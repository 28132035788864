import { FlexProps, TextProps } from "@chakra-ui/layout";
import { useCallback, useMemo, useState } from "react";
import { validateEmail } from "../common/helper/commonHelper";
import ChipsInput, { ChipsStyleProps } from "./ChipsInput";
import { MULTI_INPUT_VALUE_FORMAT } from "../common/constants/common";

//TODO : Two files are present MultiValueInput and MultiEmailInput which can be combined into a single file

const PROPS_BY_VALUE_FORMAT = {
  [MULTI_INPUT_VALUE_FORMAT.EMAIL]: {
    initHelperText: "Enter or paste emails separated by comma or space",
    separateLabelKeys: [",", " "],
  },
  [MULTI_INPUT_VALUE_FORMAT.ALLOW_SPACES]: {
    initHelperText: "Enter or paste values separated by newline",
    separateLabelKeys: ["Enter"],
  },
  [MULTI_INPUT_VALUE_FORMAT.NO_SPACES]: {
    initHelperText:
      "Enter or paste values separated by comma, space or newline",
    separateLabelKeys: [",", " ", "Enter"],
  },
};

export default function MultiValueInput({
  inputList,
  onInputListChange,
  inputType,
  valueFormat,
  maxInputs = 50,
  errorMsg,
  chipsContainerProps,
  chipsStyleProps,
  isDisabled,
}: {
  inputList: string[];
  onInputListChange: (value: string[]) => void;
  inputType: string;
  valueFormat: MULTI_INPUT_VALUE_FORMAT;
  maxInputs?: number;
  errorMsg: string;
  chipsContainerProps?: FlexProps;
  chipsStyleProps?: ChipsStyleProps;
  isDisabled?: boolean;
}) {
  const [inputData, setInputData] = useState({
    value: "",
    error: "",
  });

  const VALUE_FORMAT_PROPS = useMemo(
    () => PROPS_BY_VALUE_FORMAT[valueFormat],
    [valueFormat]
  );

  const [helperText, setHelperText] = useState<{
    text: string;
    style: TextProps;
  }>({
    text: VALUE_FORMAT_PROPS.initHelperText,
    style: {},
  });

  function isDuplicateLabel(label: string) {
    return inputList.includes(label);
  }

  function duplicateLabelCheck(label: string) {
    const duplicateLabel = isDuplicateLabel(label);
    setInputData((prev) => {
      return {
        ...prev,
        error: duplicateLabel
          ? `${label} has already been added to the list`
          : "",
      };
    });
    return !duplicateLabel;
  }

  function handleChange(code: string) {
    setInputData((prev) => {
      return { ...prev, value: code, error: "" };
    });
  }

  function handleDelete(index: number) {
    const filteredInputs = [...inputList.filter((_, ind) => ind !== index)];
    onInputListChange(filteredInputs);

    setHelperText({
      style: {},
      text:
        filteredInputs.length === maxInputs
          ? ""
          : VALUE_FORMAT_PROPS.initHelperText,
    });
  }

  function validateAndRemoveDuplicateInputs(
    formInputs: string[],
    filterValue: string
  ) {
    return formInputs.filter((input, index) => {
      if (
        filterValue === MULTI_INPUT_VALUE_FORMAT.EMAIL &&
        !validateEmail(input)
      )
        return false;
      if (formInputs.indexOf(input) !== index) return false;
      if (isDuplicateLabel(input)) return false;
      return true;
    });
  }

  const validInputCheck = useCallback(
    (input: string) => {
      let isValid = true;
      let errorMsg = "";
      if (!input) {
        isValid = false;
        errorMsg = "Enter a non empty value";
      } else if (
        valueFormat === MULTI_INPUT_VALUE_FORMAT.EMAIL &&
        !validateEmail(input)
      ) {
        isValid = false;
        errorMsg = "Enter a valid email";
      }
      setInputData((prev) => {
        return {
          ...prev,
          error: errorMsg,
        };
      });
      return isValid;
    },
    [valueFormat]
  );

  function handlePaste(e: React.ClipboardEvent) {
    e.preventDefault();
    const textToBePasted = e.clipboardData?.getData("text") ?? "";
    let inputsToAdd: string[] = [];
    if (valueFormat === MULTI_INPUT_VALUE_FORMAT.EMAIL) {
      const pastedInputs = textToBePasted.match(
        new RegExp(/[a-zA-Z0-9.!#$%&'*+/=?^_‘{|}~-]+@[\w\d\\.-]+\.[\w\d\\.-]+/g)
      );
      inputsToAdd = pastedInputs
        ? validateAndRemoveDuplicateInputs(
            pastedInputs,
            MULTI_INPUT_VALUE_FORMAT.EMAIL
          )
        : [];
    } else {
      inputsToAdd =
        valueFormat === MULTI_INPUT_VALUE_FORMAT.NO_SPACES
          ? textToBePasted.split(/[, \n]/)
          : textToBePasted.split(/[,\n]/);
      inputsToAdd = validateAndRemoveDuplicateInputs(
        inputsToAdd,
        MULTI_INPUT_VALUE_FORMAT.ALLOW_SPACES
      );
    }

    //validate all the emails and check for duplicates within the pastedEmails and the Emails in the list

    //pasted emails + emails > max number of emails
    const totalInputs = inputList.length + inputsToAdd.length;

    if (totalInputs > maxInputs) {
      const emailsToDelete = totalInputs - maxInputs;
      const emailsToPaste = inputsToAdd.length - emailsToDelete;
      inputsToAdd.splice(inputsToAdd.length - emailsToDelete);

      setHelperText({
        text: `${emailsToPaste} input${
          emailsToPaste > 1 ? "s" : ""
        } added to the list`,
        style: {
          color: "green.500",
          fontWeight: "semibold",
        },
      });
    } else {
      setHelperText({
        text: `${inputsToAdd.length} input${
          inputsToAdd.length > 1 ? "s" : ""
        } added to the list`,
        style: {
          color: "green.500",
          fontWeight: "semibold",
        },
      });
    }

    //remove helper text when max emails have been entered
    setTimeout(() => {
      setHelperText({
        style: {},
        text:
          inputList.length + inputsToAdd.length === maxInputs
            ? ""
            : VALUE_FORMAT_PROPS.initHelperText,
      });
    }, 4000);

    onInputListChange([...inputList, ...inputsToAdd]);

    setInputData({ value: "", error: "" });
  }

  function handleKeyEvent(e: React.KeyboardEvent) {
    if (VALUE_FORMAT_PROPS.separateLabelKeys.includes(e.key)) {
      e.preventDefault();

      const input = inputData.value.trim();
      if (duplicateLabelCheck(input) && validInputCheck(input)) {
        setHelperText({
          style: {},
          text:
            inputList.length + 1 === maxInputs
              ? ""
              : VALUE_FORMAT_PROPS.initHelperText,
        });
        onInputListChange([...inputList, input]);

        setInputData({ value: "", error: "" });
      }
    }
  }

  function clearInputs() {
    const dataLength = inputList.length;
    setHelperText({
      style: {},
      text: `${dataLength} inputs cleared`,
    });
    onInputListChange([]);
  }

  return (
    <ChipsInput
      isCodeMirror={false}
      labels={inputList}
      value={inputData.value}
      maxInputLength={maxInputs}
      errorMsg={inputData.error || errorMsg}
      onChange={handleChange}
      onChipDelete={handleDelete}
      helperText={helperText.text}
      helperTextProps={helperText.style}
      chipsContainerProps={chipsContainerProps}
      chipsProps={chipsStyleProps}
      isClearable={true}
      clearInputs={clearInputs}
      isDisabled={isDisabled}
      inputProps={{
        onKeyDown: handleKeyEvent,
        onPaste: handlePaste,
        type: inputType,
      }}
    />
  );
}
