import { HStack, Icon, Text } from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";

export default function CommLimitOverrideBadge({ text }: { text: string }) {
  return (
    <HStack
      color="brandBlue.900"
      bg="brandBlue.100"
      rounded="md"
      px={2}
      py={1}
      alignItems="flex-start"
    >
      <Icon as={BiMailSend} fontWeight="600" fontSize="18" mt={0.9} />
      <Text wordBreak="break-word" fontSize="12px">
        {text}
      </Text>
    </HStack>
  );
}
