import {
  Box,
  Circle,
  Collapse,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { capitalize, cloneDeep, isEqual, isNull, set } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  isLoading as isDataLoading,
  isInit,
} from "../../../../../../common/helper/commonHelper";
import {
  INFLECTION_CREATE_ACTION,
  INFLECTION_DELETE_ACTION,
  SF_OBJECTS_TO_SYNC,
  SalesforceConnectionV2,
  SalesforceEvents,
  SyncObjectsSettings,
  SyncedObjSummary,
  SfColumnDetails,
  SfColumnsOfAllSyncObjects,
  SyncReadSettingsOfAllSyncObjects,
  SfSelectiveSyncSettings,
} from "../../../../../../common/types/salesforce";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import ITitle from "../../../../../../components/ITitle";
import { useAppDispatch } from "../../../../../../store";
import IconWithTooltip from "../../../../../../components/IconWithTooltip";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import ActionBasedButtons, {
  ACTIONS,
} from "../../../../../../components/ActionBasedButtons";
import { getSyncedObjectSummary } from "../../../../../../common/helper/salesforceHelper";
import {
  listSfSelectiveSyncBooleanColumns,
  selectSalesforce,
} from "../../salesforceSlice";
import { Header } from "../salesforceSyncSettings/components/Header";
import { NoteBox } from "../salesforceSyncSettings/components/NoteBox";
import SwitchBadgeInput from "../../../../../../components/SwitchBadgeInput";

type SelectiveSyncSettingsOfAllSyncObjects = {
  [key in SF_OBJECTS_TO_SYNC]?: SfSelectiveSyncSettings | null;
};

const SALESFORCE_CREATE_MENU_OPTIONS = [
  {
    label: `Create a contact in Inflection`,
    value: INFLECTION_CREATE_ACTION.PERSON,
  },
  {
    label: "Do nothing",
    value: INFLECTION_CREATE_ACTION.DO_NOTHING,
  },
];

const SALESFORCE_DELETE_MENU_OPTIONS = [
  {
    label: "Delete the contact in Inflection but keep product connection",
    value: INFLECTION_DELETE_ACTION.DELETE_PERSON_KEEP_PRODUCT_DATA,
  },
  { label: "Do nothing", value: INFLECTION_DELETE_ACTION.DO_NOTHING },
];

// only lead and contact sync config can be changed hence keeping that in a state
//we can add others if needed
function getReadSettingsFromObjSettings(
  objSettings: SyncObjectsSettings | null
): SyncReadSettingsOfAllSyncObjects {
  return {
    lead: objSettings?.lead?.readSettings ?? null,
    contact: objSettings?.contact?.readSettings ?? null,
  };
}

function getSalesforceEventFromReadSettings(
  readSettings: SyncReadSettingsOfAllSyncObjects | null
) {
  return {
    lead: readSettings?.lead?.onSalesforceEvent ?? null,
    contact: readSettings?.contact?.onSalesforceEvent ?? null,
  };
}

function getSelectiveSyncFromReadSettings(
  readSettings: SyncReadSettingsOfAllSyncObjects | null
): SelectiveSyncSettingsOfAllSyncObjects {
  return {
    lead: readSettings?.lead?.selectiveSyncSettings ?? null,
    contact: readSettings?.contact?.selectiveSyncSettings ?? null,
  };
}

function InfoTooltip({ text }: { text: string }) {
  return (
    <IconWithTooltip
      label={text}
      iconContainerProps={{ as: "span", pt: "3px" }}
      fontSize="18px"
    />
  );
}

function NumberedListItem({ number, text }: { number: string; text: string }) {
  return (
    <Flex fontSize="13px">
      <Circle
        bgColor="gray.400"
        size="18px"
        mr={2}
        color="white"
        fontWeight="600"
      >
        {number}
      </Circle>
      <Box>
        <Text mb={3}>{text}</Text>
      </Box>
    </Flex>
  );
}

function HelperTextBox() {
  return (
    <VStack
      w="100%"
      bg="gray.100"
      p="4"
      spacing="3"
      alignItems="flex-start"
      rounded="sm"
    >
      <Box w="100%">
        <Heading fontSize="md" mb="2">
          Help
        </Heading>
        <Text fontSize="sm">
          To further limit which new Salesforce leads/contact can be added to
          Inflection, follow the below steps
        </Text>
      </Box>

      <VStack alignItems="flex-start" w="100%" spacing="1">
        <NumberedListItem
          number="1"
          text={`Create a boolean field in Salesforce on the lead & contact object (Example - "Sync to Inflection")`}
        />
        <NumberedListItem
          number="2"
          text={`Mark the above field as true for all leads/contacts that you want to sync to Inflection`}
        />
        <NumberedListItem
          number="3"
          text={`Once the above steps are complete, select the boolean field from the below list & toggle on the selective sync for lead or contacts or both`}
        />
      </VStack>

      <Divider />

      <Text fontSize="sm" color="gray.600">
        Note: If Selective sync is enabled, any leads/contacts that are added to
        the Salesforce inclusion list will automatically be marked as True
      </Text>
    </VStack>
  );
}

function ToggleFormWithDropdown({
  sfSyncObject,
  selectiveSync,
  onChange,
  options,
  isLoading,
  isDisabled,
  isReadOnly,
  activeErrorCheck,
}: {
  sfSyncObject: SF_OBJECTS_TO_SYNC;
  selectiveSync: SfSelectiveSyncSettings | null;
  onChange: (syncSettings: SfSelectiveSyncSettings | null) => void;
  options: SfColumnDetails[];
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  activeErrorCheck?: boolean;
}) {
  function onDropdownChange(value: string) {
    const updatedField = { display: "", name: value, type: "", subType: "" };

    if (selectiveSync) {
      const clonedField = cloneDeep(selectiveSync);
      set(clonedField, "field", updatedField);
      onChange(clonedField);
    } else {
      onChange({
        value: "",
        field: updatedField,
      });
    }
  }

  function onToggle(value: boolean) {
    if (value) {
      onChange({
        value: "",
        field: {
          display: "",
          name: null, //name is null or present if enabled
          type: "",
          subType: "",
        },
      });
    } else {
      onChange(null);
    }
  }
  const syncEnabled =
    !!selectiveSync?.field?.name || isNull(selectiveSync?.field?.name);
  const isInvalid = activeErrorCheck && isNull(selectiveSync?.field?.name);
  return (
    <Box>
      <HStack spacing="5">
        <ITitle
          fontSize="sm"
          fontWeight="semibold"
          title={`Selective sync for ${sfSyncObject}`}
        />
        <Flex alignItems="center">
          <SwitchBadgeInput
            isEnabled={syncEnabled}
            onChange={(e) => onToggle(e.target.checked)}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        </Flex>
        {isDisabled && (
          <InfoTooltip
            text={`${capitalize(
              sfSyncObject
            )} sync is currently disabled. You can enable them in general settings`}
          />
        )}
      </HStack>

      <Collapse in={syncEnabled} animateOpacity>
        <VStack
          alignItems="flex-start"
          bg="blackAlpha.50"
          p="2"
          w="300px"
          opacity={syncEnabled ? 1 : 0.7}
        >
          <Text
            fontSize="sm"
            color={syncEnabled ? "black" : "gray.400"}
          >{`Boolean field for ${sfSyncObject}`}</Text>
          <Box w="100%">
            <DropdownWithSearch
              menuPortalTarget={document.body}
              isLoading={isLoading}
              isDisabled={!syncEnabled || isDisabled || isReadOnly}
              options={options}
              isSearchable
              value={
                options.find((x) => x.name === selectiveSync?.field?.name) ??
                null
              }
              getOptionValue={(x) => x.name}
              onChange={(opt) => {
                opt && onDropdownChange(opt.name);
              }}
              isInvalid={isInvalid}
            />
            {isInvalid && (
              <Text color="red.500" fontSize="12px" p={1}>
                Field should not be empty
              </Text>
            )}
          </Box>
        </VStack>
      </Collapse>
    </Box>
  );
}

function SalesforceToInflectionSelectiveSync({
  selectiveSyncSettings,
  onChangeSelectiveSync,
  selectiveSyncFields,
  syncObjEnabled,
  isLoading,
  isReadOnly,
  activeErrorCheck,
}: {
  selectiveSyncSettings: SelectiveSyncSettingsOfAllSyncObjects;
  selectiveSyncFields: SfColumnsOfAllSyncObjects;
  onChangeSelectiveSync: (
    key: SF_OBJECTS_TO_SYNC,
    selectiveSync: SfSelectiveSyncSettings | null
  ) => void;
  syncObjEnabled: SyncedObjSummary;
  isLoading?: boolean;
  isReadOnly?: boolean;
  activeErrorCheck?: boolean;
}) {
  return (
    <VStack alignItems="flex-start" spacing="2" width="100%">
      <Header
        title="Selective sync"
        desc="Selective sync limits what syncs with Inflection. Only records with a
        valid email address are synced"
      />
      <HelperTextBox />
      <ToggleFormWithDropdown
        sfSyncObject={SF_OBJECTS_TO_SYNC.LEAD}
        selectiveSync={selectiveSyncSettings.lead ?? null}
        onChange={(field) =>
          onChangeSelectiveSync(SF_OBJECTS_TO_SYNC.LEAD, field)
        }
        options={selectiveSyncFields.lead?.fields ?? []}
        isDisabled={!syncObjEnabled.lead}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        activeErrorCheck={activeErrorCheck}
      />
      <ToggleFormWithDropdown
        sfSyncObject={SF_OBJECTS_TO_SYNC.CONTACT}
        selectiveSync={selectiveSyncSettings.contact ?? null}
        onChange={(field) =>
          onChangeSelectiveSync(SF_OBJECTS_TO_SYNC.CONTACT, field)
        }
        options={selectiveSyncFields.contact?.fields ?? []}
        isDisabled={!syncObjEnabled.contact}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        activeErrorCheck={activeErrorCheck}
      />
    </VStack>
  );
}

function SalesforceToInflectionSyncRules({
  salesforceEvents,
  onChangeEvent,
  syncObjEnabled,
  isLoading,
  isReadOnly,
}: {
  salesforceEvents: {
    [key in SF_OBJECTS_TO_SYNC]?: SalesforceEvents | null;
  };
  onChangeEvent: (key: SF_OBJECTS_TO_SYNC, value: SalesforceEvents) => void;
  syncObjEnabled: SyncedObjSummary;
  isLoading?: boolean;
  isReadOnly?: boolean;
}) {
  const isLeadSyncEnabled = syncObjEnabled.lead;
  const isContactSyncEnabled = syncObjEnabled.contact;

  function onChangeSalesforceEventCreate(
    key: SF_OBJECTS_TO_SYNC,
    value: INFLECTION_CREATE_ACTION
  ) {
    if (salesforceEvents?.[key]) {
      onChangeEvent(key, { ...salesforceEvents[key]!, onCreate: value });
    }
  }

  function onChangeSalesforceEventDelete(
    key: SF_OBJECTS_TO_SYNC,
    value: INFLECTION_DELETE_ACTION
  ) {
    if (salesforceEvents?.[key]) {
      onChangeEvent(key, { ...salesforceEvents[key]!, onDelete: value });
    }
  }

  return (
    <VStack alignItems="flex-start" spacing="3" width="100%" mt="5" pt="3">
      <NoteBox
        text="The action of creation, deletion or do nothing will only take effect from the time this setting is updated"
        tooltipText="Example:- If setting for deletion was set to do nothing on day 1 and the setting is updated to delete on day 2,
        the action of deleting associated Inflection people will only take place for leads/contacts who are deleted in Salesforce from day 2."
        mb={2}
      />
      <Header
        title="Sync rules for creating, updating and deleting contact in Inflection"
        desc=""
      />

      <Grid
        width="100%"
        templateColumns="repeat(3, 1fr)"
        bg="grayV2.100"
        p="2"
        rounded="xs"
        gap={6}
      >
        <Text>Action</Text>
        <HStack>
          <Text>Lead</Text>
          {!isLeadSyncEnabled && (
            <InfoTooltip text="Lead sync is currently disabled. You can enable them in general settings" />
          )}
        </HStack>
        <HStack>
          <Text>Contact</Text>
          {!isContactSyncEnabled && (
            <InfoTooltip text="Contact sync is currently disabled. You can enable them in general settings" />
          )}
        </HStack>
      </Grid>

      <Grid
        px="2"
        width="100%"
        templateColumns="repeat(3, 1fr)"
        templateRows="1fr 2fr"
        gap={6}
      >
        <Text mr="1">Created in Salesforce</Text>

        <DropdownWithSearch
          isDisabled={!isLeadSyncEnabled || isReadOnly}
          options={SALESFORCE_CREATE_MENU_OPTIONS}
          value={SALESFORCE_CREATE_MENU_OPTIONS.find((option) => {
            return option.value === (salesforceEvents?.lead?.onCreate ?? null);
          })}
          maxMenuHeight={350}
          onChange={(option) =>
            option &&
            onChangeSalesforceEventCreate(SF_OBJECTS_TO_SYNC.LEAD, option.value)
          }
          isLoading={isLoading}
          controlStyle={{
            width: "300px",
          }}
        />
        <DropdownWithSearch
          isDisabled={!isContactSyncEnabled || isReadOnly}
          options={SALESFORCE_CREATE_MENU_OPTIONS}
          value={SALESFORCE_CREATE_MENU_OPTIONS.find(
            (option) =>
              option.value === (salesforceEvents?.contact?.onCreate ?? null)
          )}
          onChange={(option) =>
            option &&
            onChangeSalesforceEventCreate(
              SF_OBJECTS_TO_SYNC.CONTACT,
              option.value
            )
          }
          isLoading={isLoading}
          controlStyle={{
            width: "300px",
          }}
        />

        <Text mr="1">Deleted in Salesforce</Text>
        <DropdownWithSearch
          isDisabled={!isLeadSyncEnabled || isReadOnly}
          options={SALESFORCE_DELETE_MENU_OPTIONS}
          value={SALESFORCE_DELETE_MENU_OPTIONS.find(
            (option) =>
              option.value === (salesforceEvents?.lead?.onDelete ?? null)
          )}
          onChange={(option) =>
            option &&
            onChangeSalesforceEventDelete(SF_OBJECTS_TO_SYNC.LEAD, option.value)
          }
          isLoading={isLoading}
          controlStyle={{
            width: "300px",
          }}
        />
        <DropdownWithSearch
          isDisabled={!isContactSyncEnabled || isReadOnly}
          options={SALESFORCE_DELETE_MENU_OPTIONS}
          value={SALESFORCE_DELETE_MENU_OPTIONS.find(
            (option) =>
              option.value === (salesforceEvents?.contact?.onDelete ?? null)
          )}
          isLoading={isLoading}
          onChange={(option) =>
            option &&
            onChangeSalesforceEventDelete(
              SF_OBJECTS_TO_SYNC.CONTACT,
              option.value
            )
          }
          controlStyle={{
            width: "300px",
          }}
        />
      </Grid>
    </VStack>
  );
}

export default function SalesforceToInflectionSettings({
  connection,
  onUpdateConnection,
  isLoading,
  isUpdatingConnection,
}: {
  connection: SalesforceConnectionV2 | null;
  onUpdateConnection: (connection: SalesforceConnectionV2 | null) => void;
  isLoading?: boolean;
  isUpdatingConnection?: boolean;
}) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);

  //use only lead and contact for now
  const [syncObjectReadSettings, setSyncObjectReadSettings] =
    useState<SyncReadSettingsOfAllSyncObjects | null>(
      getReadSettingsFromObjSettings(
        connection?.syncSettings?.objectSettings ?? null
      )
    );

  const {
    sfSelectiveSyncColumns: {
      data: selectiveSyncFields,
      loading: loadingSyncFields,
    },
  } = useSelector(selectSalesforce);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (connection?.connectionId && isInit(loadingSyncFields))
      dispatch(listSfSelectiveSyncBooleanColumns(connection.connectionId));
  }, [dispatch, connection?.connectionId, loadingSyncFields]);

  function onCancelEdit() {
    setSyncObjectReadSettings(
      getReadSettingsFromObjSettings(
        connection?.syncSettings?.objectSettings ?? null
      )
    );
    setIsReadOnly(true);
    setActiveErrorCheck(false);
  }

  function isInvalidSelectiveSync() {
    return syncObjectReadSettings
      ? Object.values(syncObjectReadSettings).some((data) =>
          isNull(data?.selectiveSyncSettings?.field?.name)
        )
      : false;
  }

  function onSaveEdit() {
    setActiveErrorCheck(true);
    if (!isInvalidSelectiveSync()) {
      const updatedConnection = cloneDeep(connection);

      set(
        updatedConnection ?? {},
        "syncSettings.objectSettings.contact.readSettings",
        syncObjectReadSettings?.contact
      );
      set(
        updatedConnection ?? {},
        "syncSettings.objectSettings.lead.readSettings",
        syncObjectReadSettings?.lead
      );

      if (!isEqual(connection, updatedConnection)) {
        onUpdateConnection(updatedConnection);
      }

      setIsReadOnly(true);
    }
  }

  const syncObjectsEnabled = getSyncedObjectSummary(connection);
  const commonProps = {
    syncObjEnabled: syncObjectsEnabled,
    isLoading,
    isReadOnly,
  };

  return (
    <ContentContainerWithHeader>
      <HStack py={1} justifyContent="flex-end">
        <ActionBasedButtons
          action={isReadOnly ? ACTIONS.VIEW : ACTIONS.EDIT}
          editButtonProps={{ onClick: () => setIsReadOnly(false), isLoading }}
          cancelButtonProps={{
            onClick: onCancelEdit,
            isLoading: isUpdatingConnection,
          }}
          saveButtonProps={{
            onClick: onSaveEdit,
            isLoading: isUpdatingConnection,
          }}
        />
      </HStack>
      <VStack spacing={2} p={5} bg="white" rounded="md">
        <SalesforceToInflectionSelectiveSync
          selectiveSyncFields={selectiveSyncFields}
          selectiveSyncSettings={getSelectiveSyncFromReadSettings(
            syncObjectReadSettings
          )}
          onChangeSelectiveSync={(key, selectiveSync) => {
            setSyncObjectReadSettings((prev) => ({
              ...prev,
              [key]: { ...prev?.[key], selectiveSyncSettings: selectiveSync },
            }));
          }}
          {...commonProps}
          isLoading={isDataLoading(loadingSyncFields) || isLoading}
          activeErrorCheck={activeErrorCheck}
        />
        <SalesforceToInflectionSyncRules
          salesforceEvents={getSalesforceEventFromReadSettings(
            syncObjectReadSettings
          )}
          onChangeEvent={(key, event) => {
            setSyncObjectReadSettings((prev) => ({
              ...prev,
              [key]: { ...prev?.[key], onSalesforceEvent: event },
            }));
          }}
          {...commonProps}
        />
      </VStack>
    </ContentContainerWithHeader>
  );
}
