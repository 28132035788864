import {
  Completion,
  CompletionContext,
  CompletionResult,
} from "@codemirror/autocomplete";
import {
  Decoration,
  EditorView,
  MatchDecorator,
  ViewPlugin,
} from "@codemirror/view";
import { UNSUBSCRIBED_URL } from "../constants/template";
import {
  EmailTokenColumn,
  EmailTokenDetails,
  EmailTokenFunction,
} from "../types/campaign";
import { ValidTokenType } from "../types/template";

export const DEFAULT_VARIABLE: Completion = {
  label: "Unsubscribe URL",
  type: "Default Variable",
  apply: (editor, completion, from, to) => {
    insertAutocompleteOption(
      editor,
      from,
      to,
      "Unsubscribe URL",
      UNSUBSCRIBED_URL,
      true
    );
  },
  info: "(Default Variable)",
};

export function createCompletionResult(autoCompleteItems: Completion[]) {
  return (context: CompletionContext): CompletionResult | null => {
    let word = context.matchBefore(/\w*/);
    if (word && word.from === word.to && !context.explicit) return null;

    return {
      from: word?.from || 0,
      options: autoCompleteItems,
    };
  };
}

function isBetweenQuotes(from: number, to: number, doc: string) {
  let quoteType = "",
    hasQuotes = false;
  for (let i = from; i >= 0; i--) {
    if (doc[i] === "'" || doc[i] === '"') {
      quoteType = doc[i];
      for (let j = to; j < doc.length; j++) {
        if (doc[j] === quoteType) {
          hasQuotes = true;
          break;
        }
        if (doc[j] === " ") {
          continue;
        } else {
          break;
        }
      }
    }
    if (doc[i] === " ") {
      continue;
    } else {
      break;
    }
  }
  return hasQuotes;
}

function addBracesIfNeeded(
  from: number,
  to: number,
  doc: string,
  word: string,
  needBraces: boolean = true
) {
  let hasBraces = false;
  for (let i = from; i > 0; i--) {
    if (doc[i] === "{" && doc[i - 1] === "{") {
      for (let j = to; j < doc.length; j++) {
        if (doc[j] === "}" && doc[j + 1] === "}") {
          hasBraces = true;
          break;
        }
        if (doc[j] === " ") {
          continue;
        } else {
          break;
        }
      }
    }
    if (doc[i] === " ") {
      continue;
    } else {
      break;
    }
  }

  return hasBraces || !needBraces ? word : `{{ ${word} }}`;
}

function calculateAutoComplete(
  editor: EditorView,
  from: number,
  to: number,
  name: string,
  display: string,
  needBraces: boolean = true
) {
  let insert = "";
  const doc = editor.state.doc.toString();
  const correctFrom = from ? from - 1 : 0;
  const range = editor.state.wordAt(correctFrom);

  if (range) {
    const word = doc.slice(range.from, range.to);
    if (word === "column" || word === "token") {
      insert = `"${name}"`;
    } else {
      insert = addBracesIfNeeded(correctFrom, to, doc, display, needBraces);
    }
  } else if (isBetweenQuotes(correctFrom, to, doc)) {
    insert = name;
  } else {
    insert = addBracesIfNeeded(correctFrom, to, doc, display, needBraces);
  }
  return insert;
}

function insertAutocompleteOption(
  editor: EditorView,
  from: number,
  to: number,
  name: string,
  display: string,
  needBraces: boolean = true
) {
  let insert = calculateAutoComplete(
    editor,
    from,
    to,
    name,
    display,
    needBraces
  );

  editor.dispatch(
    editor.state.update({
      changes: {
        from,
        to,
        insert,
      },
    })
  );
}

export function createCompletion(
  allTokensList: EmailTokenDetails[],
  allColumnsList: EmailTokenColumn[],
  allFunctionsList: EmailTokenFunction[],
  needBraces: boolean = true
) {
  let tokensOptions: Completion[] = [],
    columnsOptions: Completion[] = [],
    functionOptions: Completion[] = [];

  if (allTokensList) {
    tokensOptions = allTokensList.map((token: EmailTokenDetails) => {
      return {
        label: token.name,
        type: "Token",
        apply: (editor, completion, from, to) => {
          insertAutocompleteOption(
            editor,
            from,
            to,
            token.name,
            token.display,
            needBraces
          );
        },
        info: "(Token)",
      };
    });
  }

  if (allColumnsList) {
    columnsOptions = allColumnsList.map((col: EmailTokenColumn) => {
      return {
        label: col.name,
        type: "Column",
        apply: (editor, completion, from, to) => {
          insertAutocompleteOption(
            editor,
            from,
            to,
            col.name,
            col.display,
            needBraces
          );
        },

        info: "(Column)",
      };
    });
  }

  if (allFunctionsList) {
    functionOptions = allFunctionsList.map((func: EmailTokenFunction) => {
      return {
        label: func.name,
        type: "Function",
        apply: func.syntax,
        info: "(Function)",
      };
    });
  }

  return [...tokensOptions, ...columnsOptions, ...functionOptions];
}

export function createRegex(data: any[], type: string) {
  return `${type}\\((("|')\\s*\\b(${data
    .map((x) => x.name)
    .join("|")})\\b\\s*("|'))\\)`;
}

// New line between curlybraces won't highlight, tried with regex.
// TODO: Investigate it with documentation

export function createHighlightViewPlugin(
  allColumnsList: EmailTokenColumn[],
  allTokensList: EmailTokenDetails[],
  curlyBracketCheck = true
) {
  const highlightDeco = Decoration.mark({ class: "highlight" });
  const highlightDeco2 = Decoration.mark({ class: "highlight2" });

  const tokenRegex = createRegex(allTokensList, "token");
  const columnRegex = createRegex(allColumnsList, "column");

  const curlyBracketCheckRegex = `({{([^}]+)}})|({%([^}]+)%})`;

  let decorator = curlyBracketCheck
    ? new MatchDecorator({
        regexp: new RegExp(curlyBracketCheckRegex, "g"),
        decoration: (dataMatch) => {
          // Depends on the regex we are using, with the changes in regex, the following code should be checked.
          // dataMatch array gives you the split values with the brackets if it's matching
          // at index 2: data inside {{}}
          // at index 4: data inside {%%}
          const data = dataMatch[2] ?? dataMatch[4];
          const contentInsideBracketRegex = new RegExp(
            `^\\s*(${createRegex(allTokensList, "token")}|${createRegex(
              allColumnsList,
              "column"
            )})\\s*$`,
            "g"
          );
          const type = contentInsideBracketRegex.exec(data) ?? [];
          // type array gives you split values inside the data if its matching.
          // index 0 gives you the input string, index 1 gives trimmed string
          // index 2-5 gives you data if it's a token and index 4 is token name
          // index 6-9 gives you data if it's a column and index 8 is column name
          if (type[2]) return highlightDeco;
          if (type[6]) return highlightDeco2;
          return Decoration.mark({ class: "" });
        },
      })
    : new MatchDecorator({
        regexp: new RegExp(`${tokenRegex}|${columnRegex}`, "g"),
        decoration: (m) => (m[1] ? highlightDeco : highlightDeco2),
      });

  let viewPlugins = ViewPlugin.define(
    (view) => ({
      decorations: decorator.createDeco(view),
      update(u) {
        this.decorations = decorator.updateDeco(u, this.decorations);
      },
    }),
    {
      decorations: (v) => v.decorations,
    }
  );

  return viewPlugins;
}

export function createHighlightDefaultVariableViewPlugin() {
  let variableMatchRegex = `{{\\s*unsubscribe_url\\s*}}|{%\\s*unsubscribe_url\\s*%}`;

  const highlightDeco = Decoration.mark({ class: "highlight3" });

  let decorator = new MatchDecorator({
    regexp: new RegExp(variableMatchRegex, "g"),
    decoration: (m) => highlightDeco,
  });

  let viewPlugins = ViewPlugin.define(
    (view) => ({
      decorations: decorator.createDeco(view),
      update(u) {
        this.decorations = decorator.updateDeco(u, this.decorations);
      },
    }),
    {
      decorations: (v) => v.decorations,
    }
  );

  return viewPlugins;
}

//check if a text is valid token/column
export function isValidTokenOrColumnAccessor(
  inputText: string,
  tokensList: EmailTokenDetails[],
  columnsList: EmailTokenColumn[]
): ValidTokenType {
  let emailVariablesValidity = {
    isToken: false,
    isColumnAccessor: false,
    isValidEmailVariable: false,
  };

  const tokenRegex = createRegex(tokensList, "token");
  const columnRegex = createRegex(columnsList, "column");

  emailVariablesValidity.isToken =
    inputText.match(new RegExp(`^{{\\s*${tokenRegex}\\s*}}$`, "g"))?.length ===
    1;

  emailVariablesValidity.isColumnAccessor =
    inputText.match(new RegExp(`^{{\\s*${columnRegex}\\s*}}$`, "g"))?.length ===
    1;
  emailVariablesValidity.isValidEmailVariable =
    emailVariablesValidity.isToken || emailVariablesValidity.isColumnAccessor;
  return emailVariablesValidity;
}
