import { Flex } from "@chakra-ui/react";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { PauseDisconnectHeader } from "../salesforceSyncRun/SyncStatusEditButtons";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import SyncRunHistory from "../salesforceSyncRun/SyncRunHistory";
import SyncRunsSummary from "../salesforceSyncRun/SyncRunsSummary";
import { useCallback, useEffect, useState } from "react";
import {
  getSalesforceSyncRunsSummary,
  getSfSyncRunScheduleDetails,
  listSalesforceSyncRuns,
  setSalesforceSyncRunsPage,
} from "../../salesforceSlice";
import { endOfDay, subDays } from "date-fns";
import { useAppDispatch } from "../../../../../../store";
import { getEpochTimeUtc } from "../../../../../../common/helper/commonHelper";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
} from "../../../../../../common/constants/common";
import { SalesforceSyncRunContext } from "../../../../../../common/hooks/salesforce";

export default function SfSyncSummaryAndHistory({
  connection,
  showErrorsDuringDuration,
  isLoading,
  isConnectionComplete,
}: {
  connection: SalesforceConnectionV2 | null;
  showErrorsDuringDuration: (data: Date) => void;
  isLoading?: boolean;
  isConnectionComplete?: boolean;
}) {
  const [hideEmptyRuns, setHideEmptyRuns] = useState(true);
  const [[startDate, endDate], setDateRange] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_WEEK),
    new Date(),
  ]);

  const dispatch = useAppDispatch();

  const getSyncSummary = useCallback(() => {
    if (connection?.connectionId) {
      dispatch(
        getSalesforceSyncRunsSummary({
          connectionId: connection.connectionId,
          startTime: subDays(new Date(), 1).getTime(),
          endTime: new Date().getTime(),
        })
      );
      dispatch(getSfSyncRunScheduleDetails(connection.connectionId));
    }
  }, [connection?.connectionId, dispatch]);

  const getSyncRuns = useCallback(
    (isFilterApplied = true) => {
      if (connection?.connectionId) {
        dispatch(
          listSalesforceSyncRuns({
            connectionId: connection.connectionId,
            includeEmptyRuns: !hideEmptyRuns,
            startTime: getEpochTimeUtc(startDate),
            endTime: getEpochTimeUtc(endOfDay(endDate)),
            isFilterApplied,
          })
        );
      }
    },
    [connection?.connectionId, dispatch, hideEmptyRuns, startDate, endDate]
  );

  useEffect(() => {
    if (isConnectionComplete) {
      getSyncSummary();
    }
  }, [getSyncSummary, isConnectionComplete]);

  useEffect(() => {
    if (isConnectionComplete) {
      getSyncRuns();
    }
  }, [dispatch, getSyncRuns, isConnectionComplete]);

  function refreshAction() {
    getSyncSummary();
    getSyncRuns();
  }

  function changeSyncRunsPage(pageNo: number) {
    dispatch(setSalesforceSyncRunsPage(pageNo));
    getSyncRuns(false);
  }

  return (
    <ContentContainerWithHeader>
      <PauseDisconnectHeader />
      <Flex flexDir="column" gap={3}>
        <SalesforceSyncRunContext.Provider
          value={{
            hideEmptyRuns,
            setHideEmptyRuns,
            filterDate: [startDate, endDate],
            setFilterDate: setDateRange,
          }}
        >
          <SyncRunsSummary
            connection={connection}
            isLoading={isLoading}
            refresh={refreshAction}
          />
          <SyncRunHistory
            connection={connection}
            showErrorsDuringDuration={showErrorsDuringDuration}
            changeSyncRunsPage={changeSyncRunsPage}
          />
        </SalesforceSyncRunContext.Provider>
      </Flex>
    </ContentContainerWithHeader>
  );
}
