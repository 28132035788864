import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
  SfCampaignSyncError,
  SfSyncErrorRecord,
} from "../../../../../../common/types/salesforce";
import TableEntryText from "../../../../../../components/TableEntryText";
import { FormatDate } from "../../../../../../components/DateTimeRangeFilter";
import IButton from "../../../../../../components/IButton";
import { Text } from "@chakra-ui/react";
import {
  addSuffixForPlural,
  convertToString,
} from "../../../../../../common/helper/commonHelper";

function formatText(value: string | undefined) {
  return <TableEntryText>{value ?? "-"}</TableEntryText>;
}

function formatCount(value: number) {
  return `${convertToString(value, "0")} ${addSuffixForPlural(
    "record",
    value
  )}`;
}

export const sfErrorsTimestampColumns: ColumnDef<
  SfSyncErrorRecord | SfCampaignSyncError
>[] = [
  {
    header: "First Detected",
    accessorKey: "firstDetectedTimestamp",
    size: 100,
    cell: (info) => (
      <FormatDate
        date={new Date(info.getValue() as number).toISOString()}
        showTime
        splitLines
      />
    ),
  },
  {
    header: "Last Detected",
    accessorKey: "lastDetectedTimestamp",
    size: 100,
    cell: (info) => (
      <FormatDate
        date={new Date(info.getValue() as number).toISOString()}
        showTime
        splitLines
      />
    ),
  },
];

export const sfCampaignSyncErrorColHelper =
  createColumnHelper<SfCampaignSyncError>();
export const sfGeneralSyncErrorColHelper =
  createColumnHelper<SfSyncErrorRecord>();

export const sfCampaignSyncErrorColumns: ColumnDef<SfCampaignSyncError>[] = [
  {
    header: "Error Class",
    accessorKey: "error",
    size: 450,
    cell: (value) => formatText(value.getValue() as string),
  },

  {
    header: "Affected Records",
    accessorKey: "count",
    size: 120,
    cell: (info) => formatCount(info.getValue() as number),
    meta: {
      isNumeric: true,
    },
  },
  {
    accessorKey: "salesforceCampaignId",
    header: "Salesforce Campaign ID",
    size: 100,
    cell: (value) => formatText(value.getValue() as string),
  },
  ...(sfErrorsTimestampColumns as ColumnDef<SfCampaignSyncError>[]),
];

export const sfGeneralSyncErrorColumns = (
  onOpenAffectedRecords: (row: SfSyncErrorRecord) => void
): ColumnDef<SfSyncErrorRecord>[] => {
  return [
    {
      header: "Error Class",
      accessorKey: "errorClass",
      size: 500,
      cell: (value) => formatText(value.getValue() as string),
    },
    {
      accessorKey: "count",
      header: "Affected Records",
      size: 100,
      cell: (info) => {
        const count = info.getValue() as number;
        return (
          <IButton
            variant="link"
            fontWeight="normal"
            color="blue.600"
            isDisabled={!count}
            onClick={() => onOpenAffectedRecords(info.row.original)}
            customContent
          >
            <Text as="u">{formatCount(count)}</Text>
          </IButton>
        );
      },
      meta: {
        isNumeric: true,
      },
    },
    ...(sfErrorsTimestampColumns as ColumnDef<SfSyncErrorRecord>[]),
  ];
};
