import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik, FormikErrors, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  CampaignSchedule,
  FormType,
  RUN_TYPE,
} from "../../../common/types/campaign";
import { useSelector } from "react-redux";
import { selectCampaign } from "./campaignSlice";
import {
  ScheduleOnce,
  SchedulePeriodic,
  ScheduleRecurring,
} from "./components/SchedulerForms";
import { validateSchedule } from "./helper/validationHelper";
import { formatScheduleData, INIT_SCHEDULE } from "./helper/formatHelper";
import { MdInfo } from "react-icons/md";
import RadioItemLayoutBox from "../../../components/RadioItemLayoutBox";
import { getFormattedTenantTimezone } from "../../../common/helper/commonHelper";
import { selectSettings } from "../settings/settingsSlice";
import { selectAccount } from "../../account/accountSlice";
import { selectFeatureFlag } from "../../../common/slices/featureFlagSlice";

export type SCHEDULE_VALUE_TYPES = {
  startDate?: string;
  endDate?: string;
  day?: string;
  dateString?: string;
  date?: string;
  unit?: number;
  frequency?: string;
};

export type SCHEDULE_ERROR_TYPES = {
  startDate?: string;
  endDate?: string;
  day?: string;
  dateString?: string;
  date?: string;
  unit?: string;
  frequency?: string;
};

function onSelectRunType({
  form,
  runType,
}: {
  form: FormType;
  runType: RUN_TYPE | null;
}) {
  form.setValues(
    {
      ...INIT_SCHEDULE,
      run_type: runType ?? undefined,
    },
    true
  );
}

function formFinder(form: FormType) {
  const runConfigValues = form.values.run_config;
  const runConfigErrors = form.errors.run_config;

  switch (form.values.run_type) {
    case RUN_TYPE.ONCE:
      return (
        <ScheduleOnce
          values={{
            startDate: runConfigValues.start_date,
            endDate: runConfigValues.end_date,
          }}
          errors={{
            startDate: runConfigErrors?.start_date ?? "",
            endDate: runConfigErrors?.end_date,
          }}
          setValue={form.setFieldValue}
          onBlur={form.handleBlur}
        />
      );
    case RUN_TYPE.PERIOD:
      return (
        <SchedulePeriodic
          values={{
            startDate: runConfigValues.start_date,
            endDate: runConfigValues.end_date,
            unit: runConfigValues.unit,
            frequency: runConfigValues.frequency,
          }}
          errors={{
            startDate: runConfigErrors?.start_date,
            endDate: runConfigErrors?.end_date,
            unit: runConfigErrors?.unit,
            frequency: runConfigErrors?.frequency,
          }}
          setValue={form.setFieldValue}
          onBlur={form.handleBlur}
        />
      );
    case RUN_TYPE.RECURRING:
      return (
        <ScheduleRecurring
          values={{
            startDate: runConfigValues.start_date,
            endDate: runConfigValues.end_date,
            day: runConfigValues.freq_options.day,
            dateString: runConfigValues.freq_options.date_string,
            date: runConfigValues.freq_options.date,
            unit: runConfigValues.freq_options.unit,
            frequency: runConfigValues.frequency,
          }}
          errors={{
            startDate: runConfigErrors?.start_date,
            endDate: runConfigErrors?.end_date,
            day: runConfigErrors?.freq_options?.day,
            dateString: runConfigErrors?.freq_options?.date_string,
            date: runConfigErrors?.freq_options?.date,
            unit: runConfigErrors?.freq_options?.unit,
            frequency: runConfigErrors?.frequency,
          }}
          setValue={form.setFieldValue}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      );
    case RUN_TYPE.AT_ONCE:
      return <></>;
  }
}

function ScheduleOptionItem({
  hasError,
  runType,
  optionKey,
  optionHeader,
  optionDescription,
  onClick,
  form,
}: {
  hasError: boolean;
  runType: RUN_TYPE | null;
  optionKey: RUN_TYPE;
  optionHeader: string;
  optionDescription: string;
  onClick: () => void;
  form: FormType;
}) {
  const isSelected = optionKey === runType;
  const {
    tenantDetails: { data: tenantDetails },
  } = useSelector(selectSettings);

  // returns true for all selected options whose runtypes which is not "at once"
  const isSelectedRunTypeNotAtOnce = isSelected && runType !== RUN_TYPE.AT_ONCE;

  return (
    <RadioItemLayoutBox
      isSelected={isSelected}
      onRadioItemSelect={onClick}
      isError={hasError}
    >
      <VStack align="flex-start" width="100%">
        <Text fontSize="14px" color="blackAlpha.800" fontWeight={600}>
          {optionHeader}
        </Text>
        <Text
          fontSize="12px"
          color="blackAlpha.700"
          hidden={isSelectedRunTypeNotAtOnce}
        >
          {optionDescription}
        </Text>
        {isSelected && formFinder(form)}
        <HStack
          align="flex-start"
          bg="white"
          p={2}
          mt={3}
          borderRadius="6px"
          border="1px solid"
          borderColor="gray.200"
          width="400px"
          hidden={!isSelectedRunTypeNotAtOnce}
        >
          <Icon as={MdInfo} color="blue.700" mt={1} />
          <Text color="brandBlue.500" fontSize="12px" lineHeight="20px">
            {" "}
            Journey is scheduled in Workspace Timezone
            <br />
            <Text as="span" fontWeight="600">
              Workspace timezone:{" "}
            </Text>
            ({getFormattedTenantTimezone(tenantDetails)})
          </Text>
        </HStack>
      </VStack>
    </RadioItemLayoutBox>
  );
}

function ScheduleOptionList({
  hasError,
  runType,
  form,
  setRunType,
}: {
  hasError: boolean;
  runType: RUN_TYPE | null;
  form: FormType;
  setRunType: React.Dispatch<React.SetStateAction<RUN_TYPE | null>>;
}) {
  const { user } = useSelector(selectAccount);
  const {
    triggerCampaignEnabledOrgs: { tenants },
  } = useSelector(selectFeatureFlag);

  const BATCH_JOURNEY_OPTION_DETAILS = [
    {
      key: RUN_TYPE.AT_ONCE,
      optionHeader: "Start immediately",
      optionDescription: "Schedule the journey to begin immediately",
      show: true,
    },
    {
      key: RUN_TYPE.ONCE,
      optionHeader: "Schedule to run once",
      optionDescription: "You can schedule the journey to run only once",
      show: true,
    },
    {
      key: RUN_TYPE.RECURRING,
      optionHeader: "Schedule a recurring journey",
      optionDescription: "You can schedule the journey to reoccur accordingly",
      show: true,
    },
    {
      key: RUN_TYPE.PERIOD,
      optionHeader: "Schedule a high frequency journey",
      optionDescription: "You can schedule the journey to reoccur accordingly",
      show: !tenants.filter((org) => org === user.organisation_id).length,
    },
  ];

  function onClickHandler(chosenRunType: RUN_TYPE) {
    setRunType(chosenRunType);
    onSelectRunType({ form: form, runType: chosenRunType });
  }
  return (
    <VStack spacing="8px">
      {BATCH_JOURNEY_OPTION_DETAILS.filter((data) => data.show).map(
        (option) => (
          <ScheduleOptionItem
            hasError={hasError}
            runType={runType}
            optionKey={option.key}
            optionHeader={option.optionHeader}
            optionDescription={option.optionDescription}
            onClick={() => onClickHandler(option.key)}
            form={form}
          />
        )
      )}
    </VStack>
  );
}

export default function CampaignScheduleForms({
  data,
  onChange,
  showErrors,
}: {
  onChange: (schedule: CampaignSchedule) => void;
  data: CampaignSchedule;
  showErrors: boolean;
}) {
  const [initValues, setInitValues] = useState(formatScheduleData(null, null));
  const [initErrors, setInitErrors] = useState<FormikErrors<CampaignSchedule>>(
    {}
  );
  const { activeErrorCheck } = useSelector(selectCampaign);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (cancelButton.current !== null) {
      cancelButton.current.click();
    }
  }, [initValues]);

  useEffect(() => {
    setInitValues(data);
    activeErrorCheck && setInitErrors(validateSchedule(data));
  }, [activeErrorCheck, data]);

  const cancelButton = useRef<HTMLButtonElement>(null);

  function validate(values: CampaignSchedule, errorCheck: boolean) {
    onChange(values);
    let errors: FormikErrors<CampaignSchedule> = {};
    if (!errorCheck) {
      return errors;
    }
    setIsError(Object.keys(validateSchedule(values)).length > 0);
    return validateSchedule(values);
  }

  const HandleSubmitHook = () => {
    const { submitForm } = useFormikContext();
    useEffect(() => {
      if (showErrors) {
        submitForm();
      }
    }, [submitForm]);
    return null;
  };

  return (
    <Box w="100%">
      <Text fontSize="sm" color="brandBlue.500" mb="1">
        Set the journey schedule
      </Text>

      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        initialErrors={initErrors}
        validateOnChange={true}
        onSubmit={() => {}}
        validate={(values) => validate(values, showErrors)}
      >
        {(form) => (
          <Form id="settings-form" onSubmit={form.handleSubmit}>
            <FormControl
              mb="2"
              py="2"
              id="run_type"
              isInvalid={!!form.errors.run_type}
            >
              <ScheduleOptionList
                hasError={showErrors && isError}
                form={form}
                setRunType={(value) => form.setFieldValue("run_type", value)}
                runType={form.values.run_type ?? null}
              />
              {/* to trigger formik validation on form submit */}
              <HandleSubmitHook />
              <FormErrorMessage fontSize={12}>
                {form.errors.run_type}
              </FormErrorMessage>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
