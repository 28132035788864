import { initializePaginationWithLoadingState } from "../helper/commonHelper";
import {
  SALESFORCE_FLINK_SYNC_OBJECTS,
  SF_SYNC_ERROR_TYPE,
} from "../types/salesforce";
import { LOADING_STATES } from "./common";

export const READONLY_SF_SYNC_MESSAGE =
  "Salesforce preferred fields are read-only";

export const INIT_SF_SYNC_OBJ_PREFERENCE = {
  contact: true,
  lead: true,
  account: true,
  opportunity: false,
  campaign: false,
  campaignMember: false,
};

export const SF_ERROR_TYPE_DETAILS = [
  { name: SF_SYNC_ERROR_TYPE.OTHERS, display: "Others" },
  { name: SF_SYNC_ERROR_TYPE.PERMISSION, display: "Permissions" },
  { name: SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC, display: "Campaign Sync" },
];

export const SYNC_ERROR_RESYNC_ARCHIVE_PAGINATION = {
  ...initializePaginationWithLoadingState({}),
  archivingRecords: LOADING_STATES.INIT,
  resyncingRecords: LOADING_STATES.INIT,
};

export const SALESFORCE_OBJ_TO_CRM_OBJECT: {
  [key in SALESFORCE_FLINK_SYNC_OBJECTS]: string;
} = {
  [SALESFORCE_FLINK_SYNC_OBJECTS.CONTACT]: "contact",
  [SALESFORCE_FLINK_SYNC_OBJECTS.LEAD]: "lead",
  [SALESFORCE_FLINK_SYNC_OBJECTS.ACCOUNT]: "account",
  [SALESFORCE_FLINK_SYNC_OBJECTS.CAMPAIGN_MEMBER]: "campaignMember",
  [SALESFORCE_FLINK_SYNC_OBJECTS.CAMPAIGN_SYNC]: "campaign",
};
