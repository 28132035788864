import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  StackProps,
  Textarea,
  TextareaProps,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef } from "react";
import { CODEMIRROR_SIZE } from "../../../../common/constants/common";
import { WEBHOOK_REQUEST_METHODS } from "../../../../common/constants/webhook";
import CodemirrorInput from "../../../../components/codemirror/CodemirrorInput";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { WebhookRequestHeaders } from "./WebhookRequestHeaders";
import { HandleHeaderChangeType, WebhookInputType } from "../Webhook";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";
import { useSelector } from "react-redux";
import { isSuccess } from "../../../../common/helper/commonHelper";
import { selectWebhook } from "../webhookSlice";

export type WebhookInputConfigurationType = {
  inputs: WebhookInputType;
  handleChange: (name: keyof WebhookInputType, value: any) => void;
  contentType?: string;
  errors: {
    description: string;
    url: string;
    headers: string;
  };
  inputPaddingX?: string;
  isLoading?: boolean;
};

export default function WebhookInputConfiguration({
  inputs,
  contentType,
  handleChange,
  handleHeaderChange,
  errors,
  inputPaddingX = "0",
  isLoading,
  ...props
}: {
  handleHeaderChange: ({
    action,
    value,
    type,
    index,
  }: HandleHeaderChangeType) => void;
} & WebhookInputConfigurationType &
  StackProps) {
  const { updatingWebhook } = useSelector(selectWebhook);

  const reinitCodemirrorRef = useRef<boolean>(false);

  //TODO: create a common hook
  useEffect(() => {
    if (isSuccess(updatingWebhook)) {
      reinitCodemirrorRef.current = true;

      setTimeout(() => {
        reinitCodemirrorRef.current = false;
      }, 500);
    }
  }, [updatingWebhook]);

  return (
    <VStack alignItems={"flex-start"} px={"10px"} {...props}>
      <WebhookDescription
        inputs={inputs}
        handleChange={handleChange}
        inputPaddingX={inputPaddingX}
        errors={errors}
        isLoading={isLoading}
      />
      <WebhookUrl
        inputs={inputs}
        handleChange={handleChange}
        inputPaddingX={inputPaddingX}
        errors={errors}
        isLoading={isLoading}
        reinitializeUrl={reinitCodemirrorRef.current}
      />
      <WebhookRequestHeaders
        inputs={inputs}
        contentType={contentType}
        inputPaddingX={inputPaddingX}
        errors={errors}
        handleChange={handleChange}
        handleHeaderChange={handleHeaderChange}
        isLoading={isLoading}
        reinitializeHeader={reinitCodemirrorRef.current}
      />
      <FormControl px={inputPaddingX} py={2}>
        <FormLabel color="gray.500" fontSize="xs">
          Payload
        </FormLabel>
        <ISkeleton variant={SKELETON_VARIANT.TEXT_AREA} isLoaded={!isLoading}>
          <CodemirrorInput
            type={CODEMIRROR_SIZE.MINI_EDITOR}
            reinitialize={reinitCodemirrorRef.current}
            lang={
              contentType?.toLocaleLowerCase().includes("json")
                ? "json"
                : undefined
            }
            value={inputs.payload}
            onChange={(code: string) => handleChange("payload", code)}
            defaultVariables={true}
          />
        </ISkeleton>
      </FormControl>
    </VStack>
  );
}

function WebhookDescription({
  handleChange,
  inputs,
  errors,
  inputPaddingX,
  isLoading,
  ...props
}: WebhookInputConfigurationType & TextareaProps) {
  return (
    <FormControl isInvalid={!!errors.description} px={inputPaddingX} py={2}>
      <FormLabel color="gray.500" fontSize="xs">
        Description
      </FormLabel>
      <ISkeleton
        variant={SKELETON_VARIANT.TEXT_AREA}
        isLoaded={!isLoading}
        height="100px"
      >
        <Textarea
          pl={3}
          fontSize="sm"
          value={inputs.description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleChange("description", e.target.value)
          }
          name="description"
          resize="none"
          size="lg"
          {...props}
        />
      </ISkeleton>
      <FormErrorMessage fontSize={12}>{errors.description}</FormErrorMessage>
    </FormControl>
  );
}

function WebhookUrl({
  inputs,
  handleChange,
  errors,
  inputPaddingX,
  isLoading,
  reinitializeUrl,
}: WebhookInputConfigurationType & { reinitializeUrl: boolean }) {
  const methodsOptions = Object.values(WEBHOOK_REQUEST_METHODS).map(
    (option) => {
      return { label: option, value: option };
    }
  );

  return (
    <FormControl py={2} px={inputPaddingX} isInvalid={!!errors.url}>
      <FormLabel color="gray.500" fontSize="xs">
        Webhook URL
      </FormLabel>
      <Flex width="100%" justifyContent="space-between">
        <ISkeleton
          variant={SKELETON_VARIANT.INPUT}
          isLoaded={!isLoading}
          width="97px"
        >
          <DropdownWithSearch
            options={methodsOptions}
            value={methodsOptions.find(
              (option) => option.value === inputs.method
            )}
            controlStyle={{ width: "97px", height: "40px" }}
            name="method"
            onChange={(method) => handleChange("method", method?.value)}
          />
        </ISkeleton>

        <Flex width={"calc(100% - 100px)"} className="codemirror-normal-input">
          <ISkeleton variant={SKELETON_VARIANT.INPUT} isLoaded={!isLoading}>
            <CodemirrorInput
              type={CODEMIRROR_SIZE.STRICT_SINGLE_LINE}
              reinitialize={reinitializeUrl}
              value={inputs.url}
              onChange={(code: string) => handleChange("url", code)}
              isInvalid={!!errors.url}
            />
          </ISkeleton>
        </Flex>
      </Flex>
      <FormErrorMessage fontSize={12}>{errors.url}</FormErrorMessage>
    </FormControl>
  );
}
