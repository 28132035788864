import { Box, FormControl, FormErrorMessage, Icon } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import {
  isLoading,
  isSuccess,
  openAssetInNewTab,
} from "../../common/helper/commonHelper";
import {
  selectWebhook,
  listAllWebhooks,
} from "../../pages/dashboard/webhook/webhookSlice";
import { useAppDispatch } from "../../store";
import DropdownWithSearch from "../DropdownWithSearch";
import { AssetPreviewComponent, WebhookDropdownOption } from "../OptionHelper";
import IFormLabel from "../IFormLabel";
import {
  listStaticListSummaryList,
  selectStaticList,
} from "../../pages/dashboard/staticlist/staticlistSlice";
import { ASSET_TYPES } from "../../common/constants/common";
import {
  CodemirrorAutoCompleteFilter,
  ReactSelectDefaultOptionsWithClick,
} from "../../common/types/common";
import { FaChevronDown } from "react-icons/fa";
import { useChakraColors } from "../../common/hooks/commonHooks";
import {
  selectEmailToken,
  listAllEmailTokens,
  getAllColumns,
} from "../../pages/dashboard/emailtoken/emailTokenSlice";
import { selectSettings } from "../../pages/dashboard/settings/settingsSlice";
import {
  TokenDropdownDetails,
  ValidTokenType,
} from "../../common/types/template";
import { isValidTokenOrColumnAccessor } from "../../common/helper/codemirrorHelper";

export function WebhooksDropdown({
  webhookId,
  onSelect,
  label,
  isInvalid,
  isReadOnly,
}: {
  webhookId: string | null;
  onSelect: (id: string | null) => void;
  label?: string;
  isInvalid?: boolean;
  isReadOnly?: boolean;
}) {
  const { allWebhooksList } = useSelector(selectWebhook);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAllWebhooks());
  }, [dispatch]);

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <IFormLabel>{label}</IFormLabel>}
      <DropdownWithSearch
        options={allWebhooksList.data}
        placeholder="Select webhook"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.webhook_id}
        value={
          webhookId
            ? allWebhooksList.data.find(
                (option) => option.webhook_id === webhookId
              )
            : null
        }
        onChange={(option) => onSelect(option?.webhook_id ?? null)}
        isInvalid={isInvalid}
        isLoading={isLoading(allWebhooksList.loading)}
        maxMenuHeight={300}
        isDisabled={isReadOnly}
        isSearchable
        components={{ Option: WebhookDropdownOption }}
      />
      <FormErrorMessage>Webhook is required</FormErrorMessage>
    </FormControl>
  );
}

export function StaticListsDropdown({
  staticListId,
  onSelect,
  label,
  isInvalid,
  isReadOnly,
}: {
  staticListId: string | null;
  onSelect: (id: string | null) => void;
  label?: string;
  isInvalid?: boolean;
  isReadOnly?: boolean;
}) {
  const { staticListSummaryList } = useSelector(selectStaticList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  const staticLists: ReactSelectDefaultOptionsWithClick[] = useMemo(
    () =>
      staticListSummaryList.data.map((staticList) => {
        return {
          label: staticList.name,
          value: staticList.static_list_id,
          onPreview: () =>
            openAssetInNewTab(
              ASSET_TYPES.STATIC_LIST,
              staticList.static_list_id
            ),
        };
      }),
    [staticListSummaryList.data]
  );

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <IFormLabel>{label}</IFormLabel>}
      <DropdownWithSearch
        options={staticLists}
        placeholder="Select static list"
        value={
          staticListId
            ? staticLists.find((option) => option.value === staticListId)
            : null
        }
        onChange={(option) => onSelect(option?.value ?? null)}
        isInvalid={isInvalid}
        isLoading={isLoading(staticListSummaryList.loading)}
        maxMenuHeight={300}
        isDisabled={isReadOnly}
        isSearchable
        components={{
          Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
        }}
      />
      <FormErrorMessage>Static list is required</FormErrorMessage>
    </FormControl>
  );
}

export function TokensDropdown({
  token,
  findToken,
  onTokenChange,
  isDisabled,
  filterTokens,
  filterColumnAccessors,
}: {
  token: string;
  onTokenChange: (
    tokenDetails: TokenDropdownDetails,
    validity: ValidTokenType
  ) => void;
  findToken?: (data: TokenDropdownDetails) => boolean;
  isDisabled?: boolean;
} & CodemirrorAutoCompleteFilter) {
  const {
    allColumnsList: { data: allColumnsList, loading: fetchingColumnsList },
    emailTokenList: {
      listAll: { data: allEmailTokenList, loading: fetchingTokenList },
    },
  } = useSelector(selectEmailToken);
  const { fetchingPersonOrgMapping } = useSelector(selectSettings);
  const { gray500 } = useChakraColors();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(fetchingPersonOrgMapping)) {
      dispatch(getAllColumns());
    }
  }, [dispatch, fetchingPersonOrgMapping]);

  const filteredTokens = useMemo(() => {
    const filteredTokens = filterTokens
      ? allEmailTokenList.filter(filterTokens)
      : allEmailTokenList;
    const filteredColumnAccessors = filterColumnAccessors
      ? allColumnsList.filter(filterColumnAccessors)
      : allColumnsList;

    const filteredEmailVariables = [
      ...filteredTokens,
      ...filteredColumnAccessors,
    ].map(({ display, name }) => ({
      name,
      value: `{{ ${display} }}`,
      display,
    }));
    return filteredEmailVariables;
  }, [filterTokens, allEmailTokenList, filterColumnAccessors, allColumnsList]);

  const findValueFromTokens = findToken
    ? filteredTokens.find(findToken)
    : filteredTokens.find(({ value }) => value === token.trim());

  function onChangeToken(
    option: SingleValue<{
      name: string;
      value: string;
      display: string;
    }>
  ) {
    if (option) {
      const isValidToken = isValidTokenOrColumnAccessor(
        option.value,
        allEmailTokenList,
        allColumnsList
      );
      onTokenChange(option, isValidToken);
    }
  }

  return (
    <Box w="76%">
      <DropdownWithSearch
        value={findValueFromTokens ?? null}
        getOptionLabel={(option) => option.value}
        onChange={onChangeToken}
        options={filteredTokens}
        isLoading={
          isLoading(fetchingColumnsList) || isLoading(fetchingTokenList)
        }
        isDisabled={isDisabled}
        isSearchable
        controlStyle={{
          border: "none",
          borderRadius: "none",
          borderRight: "inherit",
        }}
        components={{
          DropdownIndicator: () => {
            return <Icon as={FaChevronDown} color={gray500} w="30px" mr={1} />;
          },
        }}
      />
    </Box>
  );
}
