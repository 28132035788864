import {
  Box,
  Checkbox,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  HStack,
  Icon,
  Heading,
  StackProps,
  VStack,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useRef, useEffect, useState } from "react";
import { CODEMIRROR_SIZE } from "../common/constants/common";
import {
  SenderMeta,
  EmailConfigErrors,
  EmailDataRequested,
  EMAIL_RECIPIENT_FIELDS,
  EmailConfigOnChange,
  TemplateCcBccResponse,
  CcBccListType,
} from "../common/types/template";
import RestrictedEmailInput from "./RestrictedEmailInput";
import OptionalCodemirrorInput from "./codemirror/OptionalCodemirrorInput";
import {
  EMAIL_CONFIG_FIELDS,
  EMAIL_CONFIG_IS_REQ_INIT,
  VALUE_META_TYPE,
} from "../common/constants/template";
import { isNull, isUndefined } from "lodash";
import IButton from "./IButton";
import { RxPlus } from "react-icons/rx";
import { IoTrash } from "react-icons/io5";
import { placeholder } from "@codemirror/view";
import { Extension } from "@codemirror/state";
import IconWithTooltip from "./IconWithTooltip";
import CcBccEmailInputs, { EMAIL_RECIPIENT_INFO } from "./CcBccEmailInputs";
import { GlobalEmailConfigType } from "../common/types/campaign";
import { OmitOnChange } from "../common/types/common";
import EmailCommunicationLimitSettings from "./EmailCommunicationLimitSettings";

export type EmailConfigurationType = TemplateCcBccResponse & {
  from_email?: {
    name: string;
    email: string;
  };
  reply_to: string;
  subject?: string;
  preheader?: string | null;
  override_email_limits?: boolean;
};
interface EmailConfigureInputProps extends OmitOnChange<StackProps> {
  inputs: (EmailConfigurationType | GlobalEmailConfigType) &
    Partial<SenderMeta>;
  onChange: EmailConfigOnChange;
  errors: EmailConfigErrors;
  readOnlyGlobalBccData?: CcBccListType;
  inputPaddingX: string;
  requiredFields?: EmailDataRequested;
  reinitCodemirror?: boolean;
  isCodemirror?: EmailDataRequested;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  ccBccInputChildren?: ReactNode;
  showCcBccWarning?: boolean;
  templateName?: string;
  showSectionWithHeaders?: boolean;
  children?: ReactNode;
}

function requiredCcBccFields(
  ccBcc: EmailDataRequested
): EMAIL_RECIPIENT_FIELDS {
  if (ccBcc.bcc_email_data_set && ccBcc.cc_email_data_set) {
    return EMAIL_RECIPIENT_FIELDS.CC_BCC;
  }
  if (ccBcc.bcc_email_data_set) {
    return EMAIL_RECIPIENT_FIELDS.BCC;
  }
  return EMAIL_RECIPIENT_FIELDS.CC;
}

function isEqualSendFromReplyTo(
  fromEmail: string,
  toEmail: string,
  metaFromEmail?: string,
  metaReplyTo?: string
) {
  return fromEmail === toEmail && metaFromEmail === metaReplyTo;
}

function CcBccEmptyChildFallback({
  recipientField,
}: {
  recipientField: EMAIL_RECIPIENT_FIELDS;
}) {
  const { heading, tooltip } = EMAIL_RECIPIENT_INFO[recipientField];
  return (
    <HStack>
      <Text variant="title">{heading}</Text>
      <IconWithTooltip label={tooltip} color="gray.400" fontSize="10px" />
    </HStack>
  );
}

function RemovePreheaderButton({
  onClick,
}: {
  onClick: (remove: boolean) => void;
}) {
  return (
    <Box
      position="absolute"
      top="3px"
      right="1px"
      w={6}
      h="30px"
      bg="white"
      pt={2}
      rounded="md"
    >
      <Icon
        aria-label="remove-preheader"
        color="brandBlue.500"
        as={IoTrash}
        size="md"
        onClick={() => onClick(true)}
        _hover={{
          cursor: "pointer",
        }}
      />
    </Box>
  );
}

function AddPreheaderButton({
  onClick,
  showButton,
  paddingStyle,
}: {
  onClick: () => void;
  showButton: boolean;
  paddingStyle: string;
}) {
  return (
    <IButton
      px={paddingStyle}
      aria-label="add preheader"
      variant="link"
      size="sm"
      color="brandBlue.500"
      fontWeight={400}
      leftIcon={<RxPlus size="12px" />}
      onClick={onClick}
      hidden={!showButton}
      justifyContent="flex-start"
    >
      Add pre-header
    </IButton>
  );
}

export function CodemirrorWithLabel({
  input,
  error,
  lang,
  reinitCodemirror,
  onChange,
  type,
  isCodemirror = true,
  name,
  label,
  infoTooltipLabel,
  isDisabled,
  description,
  additionalContent,
  extensions,
  isReadOnly = false,
  labelAsHeading,
  codemirrorClassName,
  ...props
}: {
  input: string | undefined;
  error: string | undefined;
  lang?: string;
  reinitCodemirror?: boolean;
  onChange: EmailConfigOnChange;
  type: CODEMIRROR_SIZE;
  isCodemirror?: boolean;
  label: string;
  infoTooltipLabel?: string;
  description?: string;
  name: EMAIL_CONFIG_FIELDS;
  isReadOnly?: boolean;
  additionalContent?: ReactNode;
  extensions?: Extension[];
  labelAsHeading?: boolean;
  codemirrorClassName?: string;
} & OmitOnChange<FormControlProps>) {
  return (
    <FormControl isInvalid={!!error} py={1} {...props}>
      <HStack spacing={-1} alignItems="flex-start" justifyContent="flex-start">
        {labelAsHeading ? (
          <Heading color="text.200" fontSize="16px" pb={1}>
            {label}
          </Heading>
        ) : (
          <Text color="text.50" fontSize={"xs"} pb={1}>
            {label}
          </Text>
        )}

        {infoTooltipLabel && (
          <IconWithTooltip
            iconContainerProps={{ px: "3" }}
            mb={1}
            label={infoTooltipLabel}
            fontSize="xs"
            color="gray.400"
            tooltipProps={{
              marginLeft: "180px",
              fontSize: "12px",
            }}
          />
        )}
      </HStack>
      {description && (
        <Text fontSize="xs" color="text.50" py={1}>
          {description}
        </Text>
      )}
      <OptionalCodemirrorInput
        isInvalid={!!error}
        isCodemirror={isCodemirror}
        type={type}
        lang={lang}
        reinitialize={reinitCodemirror}
        value={input || ""}
        onChange={(code: string) => onChange(name, code)}
        className={`codemirror-wrapper input-cm ${codemirrorClassName ?? ""}`}
        isDisabled={isDisabled}
        additionalContent={additionalContent}
        extensions={extensions}
        isReadOnly={isReadOnly}
      />
      <FormErrorMessage fontSize="12px" hidden={isReadOnly}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default function EmailConfigureInput({
  inputs,
  errors,
  onChange,
  inputPaddingX,
  requiredFields = EMAIL_CONFIG_IS_REQ_INIT,
  reinitCodemirror,
  isCodemirror = EMAIL_CONFIG_IS_REQ_INIT,
  isReadOnly = false,
  isDisabled,
  readOnlyGlobalBccData,
  ccBccInputChildren,
  showCcBccWarning = true,
  templateName,
  showSectionWithHeaders = false,
  children,
  ...props
}: EmailConfigureInputProps) {
  const commonEmailInputProps = {
    isDisabled,
    isReadOnly,
  };

  const [isEqualToAndFrom, setIsEqualToAndFrom] = useState(true);
  const reinitHeadersRef = useRef<boolean>(false);

  // Preheader is null when it is not CHOSEN, and is an empty string when chosen but not filled

  function onClickPreheader(remove: boolean) {
    reinitHeadersRef.current = true;

    onChange(EMAIL_CONFIG_FIELDS.PREHEADER, remove ? null : "");
    setTimeout(() => {
      reinitHeadersRef.current = false;
    }, 500);
  }

  const requiredEmailListFields = requiredCcBccFields(requiredFields);

  useEffect(() => {
    setIsEqualToAndFrom((prev) => {
      return (
        prev &&
        isEqualSendFromReplyTo(
          inputs.from_email?.email ?? "",
          inputs.reply_to,
          inputs.sender_meta?.from_email,
          inputs.sender_meta?.reply_to
        )
      );
    });
  }, [
    inputs.from_email?.email,
    inputs.reply_to,
    inputs.sender_meta?.from_email,
    inputs.sender_meta?.reply_to,
  ]);

  function setReplyToFromSendFrom({
    from,
    meta,
  }: {
    from?: string;
    meta?: VALUE_META_TYPE;
  }) {
    !isUndefined(from) && onChange(EMAIL_CONFIG_FIELDS.REPLY_TO, from);
    !isUndefined(meta) &&
      onChange(EMAIL_CONFIG_FIELDS.SENDER_META_REPLY_TO, meta);
  }

  function copySendFromToReplyTo(shouldCopy: boolean) {
    setIsEqualToAndFrom(shouldCopy);
    shouldCopy &&
      setReplyToFromSendFrom({
        from: inputs.from_email?.email ?? "",
        meta: inputs.sender_meta?.from_email,
      });
  }

  return (
    <VStack alignItems="flex-start" overflowY="auto" spacing={1} {...props}>
      {templateName && (
        <Heading
          color="text.200"
          fontSize="16px"
          pt={3}
          pb={1}
          px={inputPaddingX}
        >
          {templateName}
        </Heading>
      )}
      {requiredFields.subject && (
        <CodemirrorWithLabel
          px={inputPaddingX}
          reinitCodemirror={reinitCodemirror}
          input={"subject" in inputs ? inputs.subject : ""}
          error={errors.subject}
          label="Subject"
          onChange={onChange}
          type={CODEMIRROR_SIZE.MINI_TEXT_AREA}
          isCodemirror={isCodemirror.subject}
          name={EMAIL_CONFIG_FIELDS.SUBJECT}
          extensions={[placeholder(" Add a subject for your email")]}
          {...commonEmailInputProps}
        />
      )}
      {requiredFields.preheader && (
        <>
          <AddPreheaderButton
            onClick={() => onClickPreheader(false)}
            paddingStyle={inputPaddingX}
            showButton={isNull(inputs.preheader)}
          />
          <CodemirrorWithLabel
            px={inputPaddingX}
            reinitCodemirror={reinitHeadersRef.current}
            input={inputs.preheader ?? undefined}
            error={errors.preheader ?? undefined}
            label="Email pre-header"
            infoTooltipLabel="Keep email pre-header under 100 characters"
            onChange={onChange}
            type={CODEMIRROR_SIZE.STRICT_SINGLE_LINE}
            isCodemirror={isCodemirror.preheader}
            name={EMAIL_CONFIG_FIELDS.PREHEADER}
            isDisabled={isReadOnly}
            hidden={isNull(inputs.preheader)}
            description={
              showSectionWithHeaders
                ? "An email pre-header is a brief text displayed beside or just below the subject line in your inbox."
                : ""
            }
            labelAsHeading={showSectionWithHeaders}
            additionalContent={
              <RemovePreheaderButton onClick={onClickPreheader} />
            }
            extensions={[placeholder(" Add a preheader for your email")]}
            codemirrorClassName="codemirror-preheader"
          />
        </>
      )}
      {showSectionWithHeaders && (
        <Text variant={"title"} pt={1} pb={0} px={inputPaddingX}>
          Sender details
        </Text>
      )}

      {requiredFields.from_email.name && (
        <CodemirrorWithLabel
          px={inputPaddingX}
          reinitCodemirror={reinitCodemirror}
          input={inputs.from_email?.name}
          error={errors.from_email?.name}
          onChange={onChange}
          label="Sender name"
          isCodemirror={isCodemirror.from_email.name}
          type={CODEMIRROR_SIZE.SINGLE_LINE}
          name={EMAIL_CONFIG_FIELDS.FROM_EMAIL_NAME}
          {...commonEmailInputProps}
        />
      )}

      {requiredFields.from_email.email && (
        <RestrictedEmailInput
          labelText="Send from"
          value={inputs.from_email?.email ?? ""}
          tokenType={inputs.sender_meta?.from_email ?? null}
          onTokenTypeChange={(val) => {
            onChange(EMAIL_CONFIG_FIELDS.SENDER_META_FROM_EMAIL, val);
            isEqualToAndFrom && setReplyToFromSendFrom({ meta: val });
          }}
          onChange={(_, from) => {
            onChange(EMAIL_CONFIG_FIELDS.FROM_EMAIL, from);
            isEqualToAndFrom && setReplyToFromSendFrom({ from });
          }}
          errorMsg={errors.from_email?.email}
          paddingX={inputPaddingX}
          allowTokens={isCodemirror.from_email.email}
          name={EMAIL_CONFIG_FIELDS.FROM_EMAIL}
          formLabelProps={{ color: "gray.500", fontSize: "xs" }}
          {...commonEmailInputProps}
        />
      )}

      {requiredFields.reply_to && (
        <>
          <RestrictedEmailInput
            labelText="Reply to"
            value={inputs.reply_to}
            tokenType={inputs.sender_meta?.reply_to ?? null}
            onTokenTypeChange={(val) =>
              onChange(EMAIL_CONFIG_FIELDS.SENDER_META_REPLY_TO, val)
            }
            onChange={onChange}
            errorMsg={errors.reply_to}
            paddingX={inputPaddingX}
            allowTokens={isCodemirror.reply_to}
            name={EMAIL_CONFIG_FIELDS.REPLY_TO}
            formLabelProps={{ color: "gray.500", fontSize: "xs" }}
            isDisabled={isEqualToAndFrom || commonEmailInputProps.isDisabled}
            isReadOnly={isReadOnly}
          />
          <Checkbox
            px={inputPaddingX}
            hidden={isReadOnly}
            isChecked={isEqualToAndFrom}
            onChange={(e) => copySendFromToReplyTo(e.target.checked)}
          >
            <Text color="text.50" fontSize="12px">
              Use same details as "Send from"
            </Text>
          </Checkbox>
        </>
      )}
      {(requiredFields.bcc_email_data_set ||
        requiredFields.cc_email_data_set) && (
        <VStack alignItems="flex-start" w="100%" px={inputPaddingX}>
          <CcBccEmailInputs
            bccList={inputs.bcc_email_data_set}
            ccList={
              "cc_email_data_set" in inputs ? inputs.cc_email_data_set : null
            }
            allowTokenConfig={{
              bcc: isCodemirror.bcc_email_data_set,
              cc: isCodemirror.bcc_email_data_set,
            }}
            onListChange={onChange}
            globalBccEmailData={readOnlyGlobalBccData}
            requiredField={requiredEmailListFields}
            showTooltip={
              requiredEmailListFields !== EMAIL_RECIPIENT_FIELDS.CC_BCC
            }
            errors={{
              cc_email_data_set: errors.cc_email_data_set,
              bcc_email_data_set: errors.bcc_email_data_set,
            }}
            children={
              ccBccInputChildren ?? (
                <CcBccEmptyChildFallback
                  recipientField={requiredEmailListFields}
                />
              )
            }
            showWarning={showCcBccWarning}
            {...commonEmailInputProps}
          />
        </VStack>
      )}
      {requiredFields.override_email_limits && (
        <EmailCommunicationLimitSettings
          ignoreLimits={
            EMAIL_CONFIG_FIELDS.OVERRIDE_EMAIL_LIMITS in inputs
              ? inputs.override_email_limits ?? false
              : false
          }
          onLimitChange={(val) =>
            onChange(EMAIL_CONFIG_FIELDS.OVERRIDE_EMAIL_LIMITS, val)
          }
          px={inputPaddingX}
        />
      )}
      {children}
    </VStack>
  );
}
