import { ButtonProps, HStack, VStack, Text } from "@chakra-ui/react";
import IButton, { BUTTON } from "../../../../components/IButton";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import EmailSettingsBadgeInput from "./EmailSettingsBadgeInput";
import { ACTIONS } from "../../../../components/ActionBasedButtons";
import { EMAIL_SETTINGS_STATE } from "../../../../common/constants/settings";
import { isNull } from "lodash";

export default function UnsubscribeEmailConfiguration({
  mode,
  unsubUrl,
  onChange,
  errorMsg,
  headerTxt,
  loading,
  saveButtonProps,
}: {
  mode: ACTIONS;
  unsubUrl: string | null;
  onChange: (url: null | string) => void;
  errorMsg?: string;
  loading?: boolean;
  headerTxt?: string;
  saveButtonProps?: ButtonProps;
}) {
  //toggle with null and string
  const isDisabledUrl = isNull(unsubUrl);

  return (
    <VStack alignItems="flex-start" w="100%" spacing={0}>
      <VStack alignItems="flex-start" w="100%">
        <HStack justifyContent="space-between" w="100%">
          {headerTxt && (
            <Text fontSize="14px" color="grayV2.1600">
              {headerTxt}
            </Text>
          )}
          <EmailSettingsBadgeInput
            mode={mode}
            state={
              isDisabledUrl
                ? EMAIL_SETTINGS_STATE.DISABLED
                : EMAIL_SETTINGS_STATE.ENABLED
            }
            editSwitchProps={{
              name: "customUrl",
              onChange: (e) => onChange(isDisabledUrl ? "" : null),
              isChecked: !isDisabledUrl,
            }}
            loading={loading}
          />
        </HStack>
        <Text variant="menuText">
          This option allows you to provide recipients with a link to the
          destination for unsubscribed contacts.
        </Text>
      </VStack>

      {!isDisabledUrl && (
        <InputFieldWithError
          name="url"
          my={1}
          labelText="URL"
          value={unsubUrl ?? ""}
          errorMsg={errorMsg}
          onChange={(e) => onChange(e.target.value)}
          fontSize={14}
          capitalize={false}
          isReadOnly={mode === ACTIONS.VIEW}
          w="25%"
          formLabelProps={{ color: "gray.500", fontSize: "xs", my: 1 }}
        />
      )}
      {saveButtonProps && (
        <IButton
          variant={BUTTON.PRIMARY}
          isLoading={loading}
          name="save-unsub-url"
          fontSize="16px"
          {...saveButtonProps}
        >
          Save URL
        </IButton>
      )}
    </VStack>
  );
}
