import { Box, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { IconType } from "react-icons";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isInit, isLoading } from "../../../../common/helper/commonHelper";
import {
  SF_INIT_STEPS,
  SalesforceConnectionV2,
} from "../../../../common/types/salesforce";
import SideBar from "../../../../components/SideBar";
import SecondarySidebar from "../../../../components/v2/SecondarySidebar";
import SidebarBackButton from "../../../../components/v2/SidebarBackButton";
import urls from "../../../../urls";
import {
  getSalesforceConnectionDetails,
  reauthenticateSalesforce,
  resetSalesforceEntireState,
  resumeSalesforceSync,
  selectSalesforce,
  updateSalesforceConnection,
} from "./salesforceSlice";
import { selectConnection, getUnifiedMapping } from "../connectionSlice";
import {
  isSalesforceSetupComplete,
  isSfSyncInitPreference,
  isSfSyncPaused,
  isSfSyncVerified,
  redirectToSfLoginPageWithoutLocalStorage,
} from "../../../../common/helper/salesforceHelper";
import SalesforceConnectionInit from "./components/salesforceInit/SalesforceConnectionInit";
import { useNumberIterate } from "../../../../common/hooks/commonHooks";
import SfReauthenticateModal from "./components/salesforceInit/SfReauthenticateModal";
import { useAppDispatch } from "../../../../store";
import SfSyncStatusResumeBanner from "./components/SfSyncStatusResumeBanner";
import SfSyncSummaryAndHistory from "./components/tabs/SfSyncSummaryAndHistory";
import SfSyncHealthAndSyncErrors from "./components/tabs/SfSyncHealthAndSyncErrors";
import SalesforceGeneralSettings from "./components/tabs/SalesforceGeneralSettings";
import SalesforceToInflectionSettings from "./components/tabs/SalesforceToInflectionSettings";
import InflectionToSalesforceSettings from "./components/tabs/InflectionToSalesforceSettings";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import ContentContainer from "../../../../components/v2/ContentContainer";

const SIDEBAR_ITEMS = ["Sync History", "Sync Health"];
const SETTINGS_SIDEBAR_ITEMS = [
  `General Settings`,
  <SyncDirectionSideBarItem
    icon={FaArrowRight}
    fromText="Salesforce"
    toText="Inflection"
  />,
  <SyncDirectionSideBarItem
    icon={FaArrowRight}
    fromText="Inflection"
    toText="Salesforce"
  />,
];

function SyncDirectionSideBarItem({
  icon,
  fromText,
  toText,
}: {
  icon: IconType;
  fromText: string;
  toText: string;
}) {
  return (
    <HStack>
      <Text>{fromText}</Text> <Icon as={icon} fontSize="xs"></Icon>
      <Text>{toText}</Text>
    </HStack>
  );
}

export default function SalesforceConnection() {
  const {
    sfConnection,
    fetchingSfConnection,
    updatingSfConnection,
    oAuthUrl: { data: loginUrl, loading: getLoginUrl },
    resumingSfSync,
  } = useSelector(selectSalesforce);
  const {
    unifiedConnection: { mapping },
  } = useSelector(selectConnection);

  const { currentStep, goToNextStep, goToPrevStep, jumpToStep } =
    useNumberIterate();
  const { id } = useParams<{ id: string }>();
  const [selectedOption, setSelectedOption] = useState(0);
  const [isReauthenticatePromptOpen, setIsReauthenticatePromptOpen] =
    useState(false);
  const [isConnectionComplete, setIsConnectionComplete] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //This is a reference to the forwardRef in salesforceDetails for accessing the dateFilterHandler
  const childRef = useRef<{ filterWithDate: (date: Date) => void }>(null);

  const refreshSfConnection = useCallback(() => {
    if (id) {
      dispatch(getSalesforceConnectionDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSfSyncVerified(sfConnection?.currentState)) {
      jumpToStep(SF_INIT_STEPS.CHECK_PERMISSIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfConnection?.currentState]);

  useEffect(() => {
    if (isSfSyncInitPreference(sfConnection?.currentState)) {
      jumpToStep(SF_INIT_STEPS.SYNC_SUMMARY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfConnection?.currentState, sfConnection?.syncSettings.objectSettings]);

  useEffect(() => {
    if (id && sfConnection?.requireReAuthentication) {
      jumpToStep(null);
      setIsReauthenticatePromptOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sfConnection?.requireReAuthentication]);

  useEffect(() => {
    if (isInit(mapping.loading)) {
      dispatch(getUnifiedMapping());
    }
  });

  useEffect(() => {
    if (!sfConnection) {
      refreshSfConnection();
    }
  }, [refreshSfConnection, sfConnection]);

  useEffect(() => {
    if (loginUrl) {
      redirectToSfLoginPageWithoutLocalStorage(loginUrl);
    }
  }, [loginUrl]);

  useEffect(() => {
    setIsConnectionComplete(
      isSalesforceSetupComplete(sfConnection?.currentState)
    );
  }, [sfConnection?.currentState]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceEntireState());
    };
  }, [dispatch]);

  function onCancelReauth() {
    navigate(urls.unifiedMapping);
    setIsReauthenticatePromptOpen(false);
  }

  function onConfirmReauth() {
    if (sfConnection?.connectionId) {
      dispatch(reauthenticateSalesforce(sfConnection?.connectionId));
    }
  }

  function showErrorsDuringDuration(todayDate: Date) {
    childRef.current?.filterWithDate(todayDate);
    setSelectedOption(1);
  }

  function resumeSync() {
    if (
      sfConnection?.connectionId &&
      isSfSyncPaused(sfConnection?.currentState)
    ) {
      dispatch(resumeSalesforceSync(sfConnection?.connectionId));
    }
  }

  const onUpdateSfConnection = useCallback(
    (connection: SalesforceConnectionV2 | null) => {
      if (connection) dispatch(updateSalesforceConnection(connection));
    },
    [dispatch]
  );

  const isConnectionLoading = isLoading(fetchingSfConnection);
  const isConnectionUpdating = isLoading(updatingSfConnection);

  const getCurrentTabContent = useMemo(() => {
    switch (selectedOption) {
      case 0:
        return (
          <SfSyncSummaryAndHistory
            isLoading={isConnectionLoading}
            connection={sfConnection}
            showErrorsDuringDuration={showErrorsDuringDuration}
            isConnectionComplete={isConnectionComplete}
          />
        );

      case 1:
        return (
          <SfSyncHealthAndSyncErrors
            connection={sfConnection}
            isUpdatingConnection={isConnectionUpdating}
            onUpdateConnection={onUpdateSfConnection}
            isLoading={isConnectionLoading}
            refreshConnection={refreshSfConnection}
            ref={childRef}
          />
        );
      case 2:
        return (
          <SalesforceGeneralSettings
            connection={sfConnection}
            onUpdateConnection={onUpdateSfConnection}
            isLoading={isConnectionLoading}
            isUpdatingConnection={isConnectionUpdating}
          />
        );
      case 3:
        return (
          <SalesforceToInflectionSettings
            connection={sfConnection}
            onUpdateConnection={onUpdateSfConnection}
            isLoading={isConnectionLoading}
            isUpdatingConnection={isConnectionUpdating}
          />
        );

      case 4:
        return (
          <InflectionToSalesforceSettings
            connection={sfConnection}
            onUpdateConnection={onUpdateSfConnection}
            isLoading={isConnectionLoading}
            isUpdatingConnection={isConnectionUpdating}
          />
        );
    }
  }, [
    isConnectionLoading,
    isConnectionUpdating,
    onUpdateSfConnection,
    refreshSfConnection,
    selectedOption,
    sfConnection,
    isConnectionComplete,
  ]);

  return (
    <Flex maxH="100vh">
      <SecondarySidebar
        heading={sfConnection?.connectionName || ""}
        backButton={
          <SidebarBackButton
            onClick={() => navigate(urls.unifiedMapping)}
            text="Back to connections"
          />
        }
      >
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />

        <Text p="2" mt="3" color="text.50" fontWeight="semibold" fontSize="sm">
          Sync Settings
        </Text>
        <Box pl="3">
          <SideBar
            options={SETTINGS_SIDEBAR_ITEMS}
            selected={selectedOption - SIDEBAR_ITEMS.length}
            select={(option) =>
              setSelectedOption(option + SIDEBAR_ITEMS.length)
            }
          />
        </Box>
      </SecondarySidebar>
      <ContentContainer bg="transparent" p={0}>
        {isSfSyncPaused(sfConnection?.currentState) && (
          <SfSyncStatusResumeBanner
            resumeSync={resumeSync}
            isLoading={isLoading(resumingSfSync)}
          />
        )}
        <SpinnerContainer loading={isLoading(fetchingSfConnection)} h="90vh">
          {getCurrentTabContent}
        </SpinnerContainer>
      </ContentContainer>

      <SalesforceConnectionInit
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPrevStep={goToPrevStep}
        onCloseSfInit={() => jumpToStep(null)}
      />

      <SfReauthenticateModal
        isOpen={isReauthenticatePromptOpen}
        onCancel={onCancelReauth}
        onSubmit={onConfirmReauth}
        isLoadingOnSubmit={isLoading(getLoginUrl)}
      />
    </Flex>
  );
}
