import { Box, HStack, Text } from "@chakra-ui/react";
import { cloneDeep, isArray } from "lodash";
import { PROPERTY_FILTER_ID } from "../../../../../common/constants/campaign";
import { OperatorArgType } from "../../../../../common/types/campaign";
import {
  isArgumentMany,
  isBlank,
} from "../../../../../common/helper/commonHelper";
import IconWithTooltip from "../../../../../components/IconWithTooltip";
import {
  ValueSelectFields,
  MultiValueInputFields,
  ValueInputFields,
} from "../../../../../components/dynamic-list/DynamicListValueFields";
import React from "react";
import { TargetValueTypes } from "../../../../../common/types/dynamicList";
import { getMultiInputValueFormatFromSelectedField } from "../../../../../common/helper/dynamicListHelper";

const AudienceCriteriaValueFields = React.memo(
  ({
    value,
    validationError,
    onChange,
    argumentTypes,
    argumentSelectList,
    helperText,
    isReadOnly,
    filterValue,
    noOfArguments,
    activeErrorCheck,
    isLoading = false,
    isDisabled,
    filter,
  }: {
    value: TargetValueTypes;
    validationError?: string;
    onChange: (val: TargetValueTypes) => void;
    noOfArguments: string;
    argumentTypes: OperatorArgType;
    activeErrorCheck: boolean;
    argumentSelectList?: { label: string; value: string }[];
    helperText?: string | string[];
    isReadOnly?: boolean;
    filterValue?: string | null;
    isLoading?: boolean;
    isDisabled?: boolean;
    filter?: PROPERTY_FILTER_ID;
  }) => {
    function onChangeHandler(
      val: string | number | number[] | string[],
      index: number
    ) {
      let dataCopy = value ? cloneDeep(value) : [];
      if (typeof val === "string" || typeof val === "number") {
        dataCopy[index] = val.toString();
      } else {
        dataCopy = val.map((v) => (v ? v.toString() : ""));
      }
      onChange(dataCopy);
    }

    return (
      <>
        {argumentTypes &&
          argumentTypes.map((type, i) => {
            const validationErrorTxt =
              activeErrorCheck && isBlank(value?.[i]) ? "Invalid value" : "";

            if (typeof type === "string") {
              if (argumentSelectList) {
                return (
                  <Box key={i}>
                    <ValueSelectFields
                      options={argumentSelectList}
                      value={value?.[i] || ""}
                      onChange={(val) => onChangeHandler(val, i)}
                      validationError={validationErrorTxt}
                      isReadOnly={isReadOnly}
                      loading={isLoading}
                      isDisabled={isDisabled}
                      filter={filter}
                    />
                  </Box>
                );
              } else if (isArgumentMany(noOfArguments)) {
                return (
                  <MultiValueInputFields
                    value={value ?? []}
                    valueFormat={getMultiInputValueFormatFromSelectedField(
                      filterValue ?? undefined
                    )}
                    onChange={(val) => onChangeHandler(val, 0)}
                    isReadOnly={isReadOnly}
                    type={type}
                    activeErrorCheck={activeErrorCheck}
                    isDisabled={isDisabled}
                  />
                );
              } else {
                return (
                  <HStack key={i}>
                    {i === 1 && <Text>and</Text>}
                    <ValueInputFields
                      type={type}
                      value={value?.[i] || ""}
                      onChange={(val) => onChangeHandler(val, i)}
                      validationError={validationErrorTxt}
                      isReadOnly={isReadOnly}
                      isDisabled={isDisabled}
                    />
                  </HStack>
                );
              }
            } else if (typeof type === "object") {
              return (
                <Box key={i}>
                  <ValueSelectFields
                    options={
                      type?.map(({ display, id }) => ({
                        label: display,
                        value: id,
                      })) ?? []
                    }
                    value={value?.[i] || ""}
                    onChange={(val) => onChangeHandler(val, i)}
                    validationError={validationErrorTxt}
                    isReadOnly={isReadOnly}
                    isDisabled={isDisabled}
                  />
                </Box>
              );
            } else {
              throw new Error("Unknown Argument Type: " + type);
            }
          })}
        {!isReadOnly && helperText && (
          <IconWithTooltip
            label={isArray(helperText) ? helperText.join(", ") : helperText}
            color="gray.400"
            tooltipProps={{ fontSize: "xs" }}
          />
        )}
      </>
    );
  }
);

export default AudienceCriteriaValueFields;
