import { CAMPAIGN_EMAIL_LIMITS_TYPE, FullCampaign } from "../types/campaign";

export function isEmailLimitOverriddenByCampaign(
  val?: CAMPAIGN_EMAIL_LIMITS_TYPE
) {
  return (
    val &&
    (val === CAMPAIGN_EMAIL_LIMITS_TYPE.BOTH ||
      val === CAMPAIGN_EMAIL_LIMITS_TYPE.CAMPAIGN)
  );
}

export function getOverrideEmailLimitsFromCampaign(campaign: FullCampaign) {
  return (
    campaign.draft_data.override_email_limits ??
    campaign.override_email_limits ??
    false
  );
}
