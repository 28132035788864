import { HStack, VStack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import EmailSettingsBadgeInput from "./EmailSettingsBadgeInput";
import { ACTIONS } from "../../../../components/ActionBasedButtons";
import { getTwoStepUnsubState } from "../../../../common/helper/settingsHelper";

export default function TwoStepUnsubscribe({
  mode,
  unsubState,
  onChange,
  loading,
  headerTxt,
  children,
}: {
  mode: ACTIONS;
  unsubState: boolean;
  onChange: (field: boolean) => void;
  loading?: boolean;
  headerTxt?: string;
  children?: ReactNode;
}) {
  return (
    <VStack alignItems="flex-start" w="100%">
      <HStack justifyContent="space-between" w="100%">
        {headerTxt && (
          <Text fontSize="14px" color="grayV2.1600">
            {headerTxt}
          </Text>
        )}

        <EmailSettingsBadgeInput
          editSwitchProps={{
            name: "two-step-opt-out",
            onChange: () => onChange(!unsubState),
            isChecked: unsubState,
          }}
          mode={mode}
          state={getTwoStepUnsubState(unsubState)}
          loading={loading}
        />
      </HStack>
      {children ?? (
        <Text variant="menuText">
          With our two-click opt-out process, recipients will be prompted to
          confirm their decision when clicking the unsubscribe link in your
          email.
        </Text>
      )}
    </VStack>
  );
}
