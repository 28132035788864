import { Box, Flex, HStack, VStack, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isLoading as isDataLoading } from "../../../../../../common/helper/commonHelper";
import BolderText from "../../../components/BolderText";
import { SyncStatsHeader } from "../../../components/SyncStatsHeader";
import { SyncStats } from "../../../components/SyncStats";
import SubHeader from "../../../components/SubHeader";
import { selectSalesforce } from "../../salesforceSlice";
import {
  SalesforceConnectionV2,
  SF_OBJECTS_TO_SYNC,
  SfSyncObjRunsSummary,
} from "../../../../../../common/types/salesforce";
import ISkeleton, {
  SKELETON_VARIANT,
} from "../../../../../../components/ISkeleton";

function getReadAndWriteCount(
  syncRunObjs: SfSyncObjRunsSummary | null,
  key: SF_OBJECTS_TO_SYNC,
  newRecords?: boolean
) {
  return newRecords
    ? (syncRunObjs?.[key]?.newWriteRecordsCount || 0) +
        (syncRunObjs?.[key]?.newReadRecordsCount || 0)
    : (syncRunObjs?.[key]?.writeCount || 0) +
        (syncRunObjs?.[key]?.readCount || 0);
}

function NextSyncScheduleDetails({
  nextScheduledSyncAt,
}: {
  nextScheduledSyncAt: number | null;
}) {
  return nextScheduledSyncAt ? (
    <Text>
      Next sync on{" "}
      <BolderText>{format(new Date(nextScheduledSyncAt), "PPpp")}</BolderText>
    </Text>
  ) : (
    <Text>
      Next sync is <BolderText>not scheduled</BolderText>
    </Text>
  );
}

export default function SyncRunsSummary({
  connection,
  isLoading,
  refresh,
}: {
  connection: SalesforceConnectionV2 | null;
  isLoading?: boolean;
  refresh: () => void;
}) {
  const {
    sfSyncRunsSummary: {
      data: { syncRunObjects },
      loading,
    },
    syncRunScheduleDetails: {
      data: syncRunScheduleDetails,
      loading: isScheduleLoading,
    },
  } = useSelector(selectSalesforce);

  const totalNewRecordsCount = useMemo(
    () =>
      getReadAndWriteCount(syncRunObjects, SF_OBJECTS_TO_SYNC.LEAD, true) +
      getReadAndWriteCount(syncRunObjects, SF_OBJECTS_TO_SYNC.CONTACT, true) +
      getReadAndWriteCount(syncRunObjects, SF_OBJECTS_TO_SYNC.ACCOUNT, true) +
      getReadAndWriteCount(
        syncRunObjects,
        SF_OBJECTS_TO_SYNC.OPPORTUNITY,
        true
      ),
    [syncRunObjects]
  );

  const totalCount = useMemo(
    () => ({
      [SF_OBJECTS_TO_SYNC.ACCOUNT]: getReadAndWriteCount(
        syncRunObjects,
        SF_OBJECTS_TO_SYNC.ACCOUNT
      ),
      [SF_OBJECTS_TO_SYNC.LEAD]: getReadAndWriteCount(
        syncRunObjects,
        SF_OBJECTS_TO_SYNC.LEAD
      ),
      [SF_OBJECTS_TO_SYNC.CONTACT]: getReadAndWriteCount(
        syncRunObjects,
        SF_OBJECTS_TO_SYNC.CONTACT
      ),
      [SF_OBJECTS_TO_SYNC.OPPORTUNITY]: getReadAndWriteCount(
        syncRunObjects,
        SF_OBJECTS_TO_SYNC.OPPORTUNITY
      ),
    }),

    [syncRunObjects]
  );

  const totalRecordsCount = Object.values(totalCount).reduce(
    (sum, count) => sum + count,
    0
  );

  const isLoadingSyncRunsSummary = isLoading || isDataLoading(loading);
  const isSyncRunScheduleLoading = isDataLoading(isScheduleLoading);

  return (
    <Box p="5" bg="white" rounded="md">
      <SubHeader refreshAction={refresh} title="Sync details" />
      <Box border="1px" borderColor="gray.100" rounded="lg" mt="2">
        <Flex
          justifyContent="space-between"
          px={5}
          py={2}
          w="100%"
          borderBottomWidth="1px"
          borderColor="gray.100"
          bg="grayV2.100"
          color="gray.500"
          fontWeight="medium"
          fontSize="xs"
        >
          <ISkeleton
            variant={SKELETON_VARIANT.TEXT}
            isLoaded={!isSyncRunScheduleLoading}
            noOfLines={1}
            skeletonHeight={3}
            w="30%"
            spacing={1}
          >
            {syncRunScheduleDetails.isCurrentlyProcessing ? (
              <Text>
                Sync is <BolderText>in progress</BolderText>
              </Text>
            ) : (
              <NextSyncScheduleDetails
                nextScheduledSyncAt={syncRunScheduleDetails.nextScheduledSyncAt}
              />
            )}
          </ISkeleton>
          <ISkeleton
            variant={SKELETON_VARIANT.TEXT}
            isLoaded={!isSyncRunScheduleLoading}
            noOfLines={1}
            skeletonHeight={3}
            w="30%"
            spacing={1}
          >
            {syncRunScheduleDetails.lastSyncCompletionTime && (
              <HStack spacing="1" justifyContent="flex-end">
                <Text>Last sync completed on</Text>
                <BolderText>
                  {format(
                    new Date(syncRunScheduleDetails.lastSyncCompletionTime),
                    "PPpp"
                  )}
                </BolderText>
              </HStack>
            )}
          </ISkeleton>
        </Flex>
        <Flex alignItems="flex-end" bg="white">
          <VStack
            width="300px"
            alignItems="left"
            p={5}
            spacing="5"
            borderRightWidth="1px"
            borderColor="gray.200"
          >
            <SyncStatsHeader title="Last Sync Updates (24 hours)" />
            <SyncStats
              label="New Records"
              value={totalNewRecordsCount}
              isLoading={isLoadingSyncRunsSummary}
            />
          </VStack>
          <VStack alignItems="left" flex={1} p={5} spacing="5">
            <HStack spacing="10">
              <SyncStats
                label="Total Records"
                value={totalRecordsCount}
                isLoading={isLoadingSyncRunsSummary}
              />
              <SyncStats
                label="Total person objects"
                value={
                  totalCount[SF_OBJECTS_TO_SYNC.CONTACT] +
                  totalCount[SF_OBJECTS_TO_SYNC.LEAD]
                }
                isLoading={isLoadingSyncRunsSummary}
              />
              <SyncStats
                label="Total account objects"
                value={totalCount[SF_OBJECTS_TO_SYNC.ACCOUNT]}
                isLoading={isLoadingSyncRunsSummary}
              />
              <SyncStats
                label="Total opportunity objects"
                value={totalCount[SF_OBJECTS_TO_SYNC.OPPORTUNITY]}
                isLoading={isLoadingSyncRunsSummary}
                isComingSoon={true}
              />
            </HStack>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
}
