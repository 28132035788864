import { useEffect, useState } from "react";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import ActionBasedButtons, {
  ACTIONS,
} from "../../../components/ActionBasedButtons";
import {
  HStack,
  VStack,
  Text,
  useDisclosure,
  Circle,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
import ContentContainer from "../../../components/v2/ContentContainer";
import InfoBanner from "../../../components/InfoBanner";
import MemoizedCommonDrawer from "../campaign/components/CommonDrawer";
import {
  addSuffixForPlural,
  isFulfilled,
  isInit,
  isLoading,
} from "../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import {
  createEmailCommunicationLimitConfig,
  getCommunicationLimitConfig,
  selectSettings,
  updateEmailCommunicationLimitConfig,
} from "./settingsSlice";
import { useAppDispatch } from "../../../store";
import {
  CommLimit,
  EmailCommLimitSettings,
} from "../../../common/types/settings";
import { isNull, isNumber } from "lodash";
import SwitchBadgeInput from "../../../components/SwitchBadgeInput";
import SubHeader from "./components/SubHeader";
import NumberField, {
  NUMBER_FIELD_TYPES,
} from "../../../components/NumberField";
import { DEFAULT_COMM_LIMITS } from "../../../common/constants/settings";
import { NumberFieldType } from "../../../common/types/common";
import { FaExternalLinkAlt } from "react-icons/fa";
import IButton from "../../../components/IButton";
import { toast } from "react-toastify";

const COMMUNICATION_LIMITS_INFO = [
  "You can set a daily or weekly limit for each user",
  "Communication limits are applied on a rolling window.",
  "You can select high priority emails {here} that should bypass this limit and sent to the user even if the global limit is hit",
];

const ERRORS_INIT = {
  weeklyLimit: "",
  dailyLimit: "",
};

function isCommunicationLimitEnabled(
  commLimits: EmailCommLimitSettings | null
): boolean {
  return (
    !isNull(commLimits) &&
    (!isNull(commLimits?.weekly_limit) || !isNull(commLimits?.daily_limit))
  );
}

function GlobalCommunicationLimitsInfoDrawer(props: {
  isOpen: boolean;
  onClose: VoidFunction;
}) {
  return (
    <MemoizedCommonDrawer
      title="Global Communication Limits"
      size="md"
      placement="right"
      {...props}
    >
      <>
        <Text color="text.100" fontSize="14px">
          How does this work?
        </Text>
        <VStack alignItems="flex-start" spacing={5} my={3} w="100%" px={2}>
          {COMMUNICATION_LIMITS_INFO.map((info, index) => (
            <HStack w="100%" alignItems="baseline">
              <Circle
                size="18px"
                color="white"
                bgColor="grayV2.600"
                fontSize="12px"
              >
                {index + 1}
              </Circle>
              <Text color="text.200" fontSize="14px">
                {info}
              </Text>
            </HStack>
          ))}
        </VStack>
        <InfoBanner
          w="100%"
          my={5}
          p={2}
          customContent={
            <Text fontSize="12px" color="text.50" py={1}>
              Email Assets marked to bypass Communication Limits will be sent
              even if communication limits are set
            </Text>
          }
        >
          <Text color="brandBlue.500" fontWeight={600}>
            Note:
          </Text>
        </InfoBanner>
      </>
    </MemoizedCommonDrawer>
  );
}

export default function GlobalCommunicationLimitSettings() {
  const {
    communicationLimitConfig: {
      fetching,
      deleting,
      updating,
      emailCommunicationLimit,
    },
  } = useSelector(selectSettings);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isCommLimitEnabled, setIsCommLimitEnabled] = useState(
    isCommunicationLimitEnabled(emailCommunicationLimit)
  );
  const [dailyLimit, setDailyLimit] = useState<NumberFieldType>(
    emailCommunicationLimit?.daily_limit ?? null
  );
  const [weeklyLimit, setWeeklyLimits] = useState<NumberFieldType>(
    emailCommunicationLimit?.weekly_limit ?? null
  );
  const [errors, setErrors] = useState(ERRORS_INIT);

  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (isInit(fetching)) {
      dispatch(getCommunicationLimitConfig());
    }
  }, [fetching, dispatch]);

  useEffect(() => {
    setIsCommLimitEnabled(isCommunicationLimitEnabled(emailCommunicationLimit));
  }, [emailCommunicationLimit]);

  useEffect(() => {
    setDailyLimit(emailCommunicationLimit?.daily_limit ?? null);
  }, [emailCommunicationLimit?.daily_limit]);

  useEffect(() => {
    setWeeklyLimits(emailCommunicationLimit?.weekly_limit ?? null);
  }, [emailCommunicationLimit?.weekly_limit]);

  useEffect(() => {
    setErrors(ERRORS_INIT);
  }, [weeklyLimit, dailyLimit]);

  function enableDailyLimit(isEnabled: boolean) {
    if (isEnabled) {
      setDailyLimit(DEFAULT_COMM_LIMITS.DAILY);
    } else {
      setDailyLimit(null);
    }
  }

  function enableWeeklyLimit(isEnabled: boolean) {
    if (isEnabled) {
      setWeeklyLimits(DEFAULT_COMM_LIMITS.WEEKLY);
    } else {
      setWeeklyLimits(null);
    }
  }
  function enableCommLimits(isEnabled: boolean) {
    enableDailyLimit(isEnabled);
    enableWeeklyLimit(isEnabled);
    setIsCommLimitEnabled(isEnabled);
  }

  function onCancelEdit() {
    setIsCommLimitEnabled(isCommunicationLimitEnabled(emailCommunicationLimit));
    setWeeklyLimits(emailCommunicationLimit?.weekly_limit ?? null);
    setDailyLimit(emailCommunicationLimit?.daily_limit ?? null);
    setIsReadOnly(true);
    setErrors(ERRORS_INIT);
  }

  function validateCommLimits() {
    let valid = true;
    if (isCommLimitEnabled && isNull(weeklyLimit) && isNull(dailyLimit)) {
      setErrors({
        weeklyLimit: "Empty weekly limit",
        dailyLimit: "Empty daily limit",
      });
      valid = false;
    }
    if (!isNull(dailyLimit) && !dailyLimit) {
      setErrors((prev) => ({
        ...prev,
        dailyLimit: "Please enter a valid daily limit",
      }));
      valid = false;
    }
    if (!isNull(weeklyLimit) && !weeklyLimit) {
      setErrors((prev) => ({
        ...prev,
        weeklyLimit: "Please enter a valid weekly limit",
      }));
      valid = false;
    }

    if (valid) {
      setErrors(ERRORS_INIT);
    }

    return valid;
  }

  async function onSaveChanges() {
    if (validateCommLimits()) {
      let response = null;
      let commLimit = {
        weekly_limit: weeklyLimit as CommLimit,
        daily_limit: dailyLimit as CommLimit,
      };

      if (isNull(emailCommunicationLimit)) {
        response = await dispatch(
          createEmailCommunicationLimitConfig(commLimit)
        );
      } else {
        response = await dispatch(
          updateEmailCommunicationLimitConfig(commLimit)
        );
      }

      if (response && isFulfilled(response.meta.requestStatus)) {
        toast.success("Updated communication limit settings successfully");
        setIsReadOnly(true);
      } else {
        toast.error("Failed to update communication limit settings");
      }
    }
  }

  const isPageLoading =
    isLoading(fetching) || isLoading(updating) || isLoading(deleting);

  return (
    <ContentContainerWithHeader mr="20px">
      <HStack justifyContent="space-between" px={2} py={4}>
        <Text variant="title">Communication Limits</Text>
        <ActionBasedButtons
          action={isReadOnly ? ACTIONS.VIEW : ACTIONS.EDIT}
          editButtonProps={{
            isLoading: isPageLoading,
            onClick: () => setIsReadOnly(false),
          }}
          cancelButtonProps={{
            isLoading: isPageLoading,
            onClick: onCancelEdit,
          }}
          saveButtonProps={{
            isLoading: isPageLoading,
            onClick: onSaveChanges,
          }}
        />
      </HStack>

      <ContentContainer flexDir="column">
        <Text variant="title" pb={2}>
          Communication Limits and Frequency
        </Text>
        <Text variant="menuText">
          Set communication limits to maintain the balance in client
          interaction.
        </Text>

        <HStack justifyContent="space-between" py={3} mt={5}>
          <VStack alignItems="flex-start" spacing={1}>
            <Text variant="title">Global Communication Limits</Text>
            <IButton
              variant="link"
              fontWeight="normal"
              color="brandBlue.450"
              children="Learn more about Communication limits"
              onClick={onOpen}
              rightIcon={<FaExternalLinkAlt fontSize="12" cursor="pointer" />}
            />
          </VStack>
          <SwitchBadgeInput
            isEnabled={isCommLimitEnabled}
            onChange={(e) => enableCommLimits(e.target.checked)}
            isReadOnly={isReadOnly}
            isLoading={isPageLoading}
          />
        </HStack>

        <Stack
          flexDir={isReadOnly ? "row" : "column"}
          alignItems="flex-start"
          my={6}
          w="100%"
          opacity={isCommLimitEnabled ? 1 : 0.8}
          pointerEvents={isCommLimitEnabled ? "unset" : "none"}
        >
          <LimitSettings
            title="Apply Daily limits"
            isEnabled={!isNull(dailyLimit)}
            onChange={setDailyLimit}
            enableLimits={enableDailyLimit}
            description="Daily email sending limit (24 hours)"
            helperText="Recommended: Around 2-4 emails per day"
            readOnlyHelperText={`${
              isNumber(dailyLimit)
                ? addSuffixForPlural("Email", dailyLimit) + " per day"
                : "(Not specified)"
            }`}
            isReadOnly={isReadOnly}
            value={dailyLimit}
            errorMsg={errors.dailyLimit}
          />

          <LimitSettings
            title="Apply Weekly limits"
            isEnabled={!isNull(weeklyLimit)}
            onChange={setWeeklyLimits}
            enableLimits={enableWeeklyLimit}
            description="Weekly email sending limit (7 days)"
            helperText="Recommended: Around 10 emails per week"
            readOnlyHelperText={`${
              isNumber(weeklyLimit)
                ? addSuffixForPlural("Email", weeklyLimit) + " per week"
                : "(Not specified)"
            }`}
            isReadOnly={isReadOnly}
            value={weeklyLimit}
            errorMsg={errors.weeklyLimit}
          />
        </Stack>
        <InfoBanner p={2} my={1} w="100%">
          <Text fontSize="12px" color="brandBlue.500">
            These limits apply to all Email assets and Journeys
          </Text>
        </InfoBanner>
      </ContentContainer>
      <GlobalCommunicationLimitsInfoDrawer isOpen={isOpen} onClose={onClose} />
    </ContentContainerWithHeader>
  );
}

function LimitSettings({
  title,
  description,
  helperText,
  isEnabled,
  value,
  onChange,
  enableLimits,
  isReadOnly,
  readOnlyHelperText,
  errorMsg,
}: {
  title: string;
  description: string;
  isEnabled: boolean;
  value: NumberFieldType;
  onChange: (val: NumberFieldType) => void;
  enableLimits: (val: boolean) => void;
  helperText?: string;
  readOnlyHelperText?: string;
  isReadOnly?: boolean;
  errorMsg?: string;
}) {
  return (
    <>
      {isReadOnly ? (
        <VStack alignItems="flex-start" opacity={isEnabled ? 1 : 0.6} w="50%">
          <Text fontSize={12} color="text.50">
            {description}
          </Text>

          {value ? (
            <Text variant="normal">
              {value}
              <Text as="span" fontSize="12px" px={1} color="grayV2.800">
                {readOnlyHelperText}
              </Text>
            </Text>
          ) : (
            <Text variant="normal">{readOnlyHelperText}</Text>
          )}
        </VStack>
      ) : (
        <HStack alignItems="start" justifyContent="flex-start" mt={1} w="100%">
          <Checkbox
            mx={2}
            my={1}
            isChecked={isEnabled}
            onChange={(e) => enableLimits(e.target.checked)}
            colorScheme={errorMsg ? "red" : "blue"}
            borderColor={errorMsg ? "red" : ""}
          />
          <SubHeader
            title={title}
            desc={description}
            titleProps={{
              fontSize: 14,
            }}
            descriptionProps={{ fontSize: 12, color: "text.50" }}
            stackProps={{
              spacing: 1,
              alignItems: "flex-start",

              my: 2,
            }}
            opacity={isEnabled ? 1 : 0.7}
            pointerEvents={isEnabled ? "unset" : "none"}
            w="100%"
          >
            <NumberField
              type={NUMBER_FIELD_TYPES.INTEGER}
              allowNegativeNumbers={false}
              value={value ? value : ""}
              onValueChange={(val) => onChange(val ? val : "")}
              min={1}
              w="100%"
              isInvalid={!!errorMsg}
            />
            {errorMsg && (
              <Text fontSize="12px" py={1} color="red.500">
                {errorMsg}
              </Text>
            )}
            {helperText && (
              <Text fontSize={12} color="grayV2.500">
                {helperText}
              </Text>
            )}
          </SubHeader>
        </HStack>
      )}
    </>
  );
}
