import { Badge, Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { GroupBase, OptionProps } from "react-select";
import { READONLY_SF_SYNC_MESSAGE } from "../common/constants/connection";
import { isSalesforcePreference } from "../common/helper/unifiedMappingHelper";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
  GenericOptionPropsType,
  ReactSelectDefaultOptionsWithClick,
} from "../common/types/common";
import { PersonDestination } from "../common/types/person";
import LockIconWithTooltip from "./LockIconWithTooltip";
import { IconType } from "react-icons";
import { openAssetInNewTab } from "../common/helper/commonHelper";
import { EmailTokenDetails } from "../common/types/campaign";
import { WebhookSummary } from "../common/types/webhook";
import { ASSET_TYPES } from "../common/constants/common";

export const CustomOptionWithRequired = ({
  children,
  data,
  ...props
}: OptionProps<PersonDestination, false, GroupBase<PersonDestination>>) => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="baseline"
      py="2"
      px="3"
      bg={props.isSelected ? "gray.200" : ""}
      _hover={props.isSelected ? {} : { bg: "gray.100" }}
      {...(props.isDisabled
        ? { color: "gray.400", cursor: "default" }
        : { onClick: () => props.setValue(data, "select-option") })}
    >
      <Text fontSize="sm" isTruncated maxW="800px">
        {data.display}
      </Text>
      <HStack>
        {isSalesforcePreference(data.sync_preference) && (
          <LockIconWithTooltip message={READONLY_SF_SYNC_MESSAGE} />
        )}
        {data.required && (
          <Badge
            fontSize="10px"
            backgroundColor="oneOffs.tableheader"
            variant="solid"
          >
            Required
          </Badge>
        )}
      </HStack>
    </HStack>
  );
};

// NOTE: Dropdowns take in two kinds of options either of type
// 1)'ReactSelectDefaultOptionsWithClick' (or 'ReactSelectDefaultOptionsType')
// 2) general types like 'WebhookSummary', 'EmailTokenDetails' specific to the asset
// If the type is 1, then we can directly use the below component
// If the type is 2, then we have to pass componentSpecifics, which lets the component know
// what to be used as label, value and onPreview

export const AssetPreviewComponent = <T,>({
  componentSpecifics,
  icon = FaExternalLinkAlt,
  data,
  ...props
}: GenericOptionPropsType<T> & {
  componentSpecifics?: ReactSelectDefaultOptionsWithClick;
  icon?: IconType;
}) => {
  const transformedData =
    componentSpecifics ?? (data as ReactSelectDefaultOptionsWithClick);

  return (
    <ItemPreviewRow
      data={transformedData}
      icon={icon}
      isSelected={props.isSelected}
      onSelect={() => {
        props.setValue(data, "select-option");
      }}
    />
  );
};

function ItemPreviewRow({
  isSelected,
  onSelect,
  data,
  icon,
}: {
  isSelected: boolean;
  onSelect: () => void;
  data: ReactSelectDefaultOptionsWithClick;
  icon: IconType;
}) {
  const [iconHovered, setIconHovered] = useState(false);

  return (
    <HStack
      justifyContent="space-between"
      alignItems="baseline"
      py="2"
      px="3"
      bg={isSelected ? "gray.200" : ""}
      _hover={isSelected || iconHovered ? {} : { bg: "gray.100" }}
      onClick={onSelect}
    >
      <Box maxW="800px">
        <Text fontSize="sm" isTruncated maxW="800px">
          {data.label}
        </Text>
      </Box>
      {data.onPreview && (
        <IconButton
          icon={<Icon as={icon} fontSize="12px" />}
          aria-label="preview"
          variant="ghost"
          color="brand.blue"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            data.onPreview?.(data.value);
          }}
          onMouseEnter={() => setIconHovered(true)}
          onMouseLeave={() => setIconHovered(false)}
        />
      )}
    </HStack>
  );
}

export function TokenDropdownOption(
  props: GenericOptionPropsType<EmailTokenDetails>
) {
  const { data } = props;
  return (
    <AssetPreviewComponent<EmailTokenDetails>
      componentSpecifics={{
        label: data.name,
        value: data.display,
        onPreview: () => openAssetInNewTab(ASSET_TYPES.TOKEN, data.token_id),
      }}
      {...props}
    />
  );
}

export function WebhookDropdownOption(
  props: GenericOptionPropsType<WebhookSummary>
) {
  const { data } = props;
  return (
    <AssetPreviewComponent<WebhookSummary>
      componentSpecifics={{
        label: data.name,
        value: data.webhook_id,
        onPreview: () =>
          openAssetInNewTab(ASSET_TYPES.WEBHOOK, data.webhook_id),
      }}
      {...props}
    />
  );
}
