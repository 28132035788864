import { VStack, Text, chakra, HStack } from "@chakra-ui/react";
import ContentContainer from "../../../../components/v2/ContentContainer";
import InfoBanner from "../../../../components/InfoBanner";
import SwitchBadgeInput from "../../../../components/SwitchBadgeInput";
import {
  ReadOnlyUrlParameters,
  UrlParameterPrecedenceSelection,
  UrlParameterRows,
} from "../../templates/components/TemplateUrlParameters";
import {
  AddRemoveUrlParam,
  EmailConfigOnChange,
  EmailUrlParamChange,
  ErrorUrlParams,
  TemplateUrlParametersConfig,
} from "../../../../common/types/template";
import { EMAIL_CONFIG_FIELDS } from "../../../../common/constants/template";

export default function EmailLinkUrlParameters({
  urlParameterDetails,
  onChange,
  addOrRemoveUrlParam,
  onChangeUrlParamList,
  errors,
  isReadOnly,
  loading,
}: {
  urlParameterDetails: TemplateUrlParametersConfig;
  onChange: EmailConfigOnChange;
  addOrRemoveUrlParam: (v: AddRemoveUrlParam) => void;
  onChangeUrlParamList: (v: EmailUrlParamChange) => void;
  errors?: ErrorUrlParams;
  loading?: boolean;
  isReadOnly?: boolean;
}) {
  const { url_params_enabled, url_params_list, override_existing_url_params } =
    urlParameterDetails;

  return (
    <ContentContainer flexDir="column" my={3}>
      <VStack alignItems="flex-start" w="100%">
        <Text variant="title">Email link parameter</Text>
        <Text variant="menuText">
          {`Enable URL parameters for all links in new email assets to track sources in analytics tools (e.g., Google Analytics)`}
        </Text>

        <InfoBanner py={2} px={3} w="100%">
          <Text fontSize="xs" color="brandBlue.500">
            Changes made to global UTM parameter settings will only apply to{" "}
            <chakra.span fontWeight="semibold">new assets created</chakra.span>{" "}
            after the change.
          </Text>
        </InfoBanner>

        <HStack w="100%" justifyContent="space-between" pt={2}>
          <Text variant="mainText">URL parameters</Text>

          <SwitchBadgeInput
            isEnabled={url_params_enabled}
            onChange={(e) =>
              onChange(EMAIL_CONFIG_FIELDS.URL_PARAMS_ENABLED, e.target.checked)
            }
            isReadOnly={isReadOnly}
            isLoading={loading}
          />
        </HStack>
        {isReadOnly ? (
          <ReadOnlyUrlParameters urlParameterList={url_params_list ?? []} />
        ) : (
          <UrlParameterRows
            isUrlParamEnabled={url_params_enabled}
            urlParameterList={url_params_list}
            onChangeUrlParamList={onChangeUrlParamList}
            addOrRemoveUrlParam={addOrRemoveUrlParam}
            enableTokensInValue={false}
            errors={errors}
            isReadOnly={isReadOnly}
          />
        )}

        <UrlParameterPrecedenceSelection
          shouldOverrideExistingParams={override_existing_url_params}
          isUrlParamEnabled={url_params_enabled}
          onOverrideChange={(v) =>
            onChange(EMAIL_CONFIG_FIELDS.OVERRIDE_URL_PARAMS, v)
          }
          isReadOnly={isReadOnly}
          headerProps={{ variant: "mainText" }}
          w="400px"
        />
      </VStack>
    </ContentContainer>
  );
}
