import { DL_ACTION } from "../constants/dynamicList";
import {
  EMAIL_CONFIG_FIELDS,
  TEMPLATE_CC_BCC_TYPES,
  TEMPLATE_URL_PARAMETER_TYPE,
  URL_PARAMS_ON_CHANGE_OPTION,
  VALUE_META_TYPE,
} from "../constants/template";
import { EmailConfigurationType } from "./campaign";
import { UserSummary } from "./common";

export interface TemplateBase {
  template_id: string;
  name?: string;
  template_type?: TEMPLATE_EDITOR_MODE;
  subject?: string | null;
  preheader?: string | null;
  reply_to?: string | null;
  from_email?: {
    name: string;
    email: string;
  } | null;
  updated_at?: string;
  created_at?: string;
  is_empty?: boolean;
  override_email_limits?: boolean;
}

export type TemplateCcBccResponse = {
  bcc_email_data_set: CcBccListType;
  cc_email_data_set: CcBccListType;
};

export type TemplateCcBccRequest = {
  bcc_email_data_list: CcBccListType;
  cc_email_data_list: CcBccListType;
};

export type CcBccFieldType = {
  type: TEMPLATE_CC_BCC_TYPES;
  value: string;
};

export type CcBccListType = CcBccFieldType[] | null;

export type UrlParameterConfig = {
  key: string;
  value: string;
  type: TEMPLATE_URL_PARAMETER_TYPE;
};

export type TemplateUrlParameterList = UrlParameterConfig[];

export type TemplateUrlParametersConfig = {
  url_params_enabled: boolean;
  url_params_list: TemplateUrlParameterList;
  override_existing_url_params: boolean;
};

export type TemplateExhaustive = TemplateBase &
  TemplateAdditionalProperties &
  TemplateMeta &
  SenderMeta &
  TemplateCcBccResponse &
  GlobalBccEmailData &
  TemplateUrlParametersConfig;

export interface TemplateAdditionalProperties {
  content: string;
  previews?: {
    img_thumb: string;
    img_large: string;
  };
  content_json?: string;
  type?: string;
  is_deleted?: boolean;
}

export interface TemplateMeta {
  created_at: string;
  created_by: UserSummary;
  updated_by: UserSummary;
  updated_at: string;
}

export interface SenderMetaDetails {
  from_email: VALUE_META_TYPE;
  from_name: VALUE_META_TYPE;
  reply_to: VALUE_META_TYPE;
}

export interface SenderMeta {
  sender_meta?: SenderMetaDetails;
}

export type TemplateType = TemplateBase &
  SenderMeta &
  TemplateAdditionalProperties &
  TemplateCcBccResponse &
  GlobalBccEmailData;

export enum TEMPLATE_EDITOR_MODE {
  CODE = "advanced",
  DRAG_DROP = "visual",
}

export interface BeeAuthType {
  ".expires": string;
  ".issued": string;
  access_token: string;
  "as:client_id": string;
  "as:region": string;
  expires_in: number;
  refresh_token: string;
  token_type: "bearer";
  userName: string;
}

export interface SavedRowBasic {
  row_json: string;
  row_html?: string;
  page_json?: string;
}

export interface SavedRow extends SavedRowBasic {
  bee_row_id: string;
}

export interface SavedRowResp extends SavedRow {
  created_at: string;
  updated_at: string;
  created_by: UserSummary;
  updated_by: UserSummary;
  type: "bee_row";
  is_deleted: boolean;
}

export type EmailConfigWithSubject = EmailConfigurationType &
  SenderMeta & {
    subject: string;
  };

export type EmailConfigChangeTracker = Partial<{
  fromEmailEmail: boolean;
  fromEmailName: boolean;
  replyTo: boolean;
  subject: boolean;
  cc: boolean;
  bcc: boolean;
  preheader: boolean;
  urlParamList: boolean;
}>;

export type EmailConfigErrors = {
  subject: string;
  from_email: {
    name: string;
    email: string;
  };
  reply_to: string;
  bcc_email_data_set: string;
  cc_email_data_set: string;
  preheader: string;
  url_params_list: ErrorUrlParams;
};

export type EmailDataRequested = {
  from_email: {
    name: boolean;
    email: boolean;
  };
  reply_to: boolean;
  subject: boolean;
  bcc_email_data_set: boolean;
  cc_email_data_set: boolean;
  preheader: boolean;
  url_params_list: boolean;
  override_email_limits: boolean;
};

export type RequiredCcBcc = {
  bcc: boolean;
  cc: boolean;
};

export enum EMAIL_RECIPIENT_FIELDS {
  CC = "CC",
  BCC = "BCC",
  CC_BCC = "CC_BCC",
}

export type EmailConfigOnChange = (
  name: EMAIL_CONFIG_FIELDS,
  value: string | CcBccListType | boolean | TemplateUrlParameterList
) => void;

export type GlobalBccEmailData = {
  global_bcc_email_data_set?: CcBccListType;
};

export type GlobalAndTemplateBccData = {
  global_emails_set: CcBccListType;
  template_emails_set: CcBccListType;
};

export type GetTemplateResp = TemplateMeta &
  TemplateBase &
  SenderMeta &
  TemplateAdditionalProperties & {
    cc_email_data_set: CcBccListType;
    bcc_data: GlobalAndTemplateBccData;
  } & TemplateUrlParametersConfig;

export type AddRemoveUrlParam = {
  action: DL_ACTION;
  index?: number;
  paramKey?: string;
};

export type ValidTokenType = {
  isToken: boolean;
  isColumnAccessor: boolean;
  isValidEmailVariable: boolean;
};

export type TokenDropdownDetails = {
  name: string;
  value: string;
  display: string;
};

export type EmailUrlParamChange = {
  filter: URL_PARAMS_ON_CHANGE_OPTION;
  arg: string;
  index: number;
  type?: TEMPLATE_URL_PARAMETER_TYPE;
};

export type ErrorUrlParams = {
  [key: number]: { key: string; value?: string };
};
