import { GlobalEmailConfigType } from "../types/campaign";
import {
  EmailConfigChangeTracker,
  TemplateUrlParametersConfig,
} from "../types/template";
import {
  EmailConfigErrors,
  EmailConfigWithSubject,
  EmailDataRequested,
  SenderMeta,
} from "../types/template";

export const UNSUBSCRIBED_URL = "unsubscribe_url";

export enum EMAIL_CONFIG_FIELDS {
  SUBJECT = "subject",
  PREHEADER = "preheader",
  FROM_EMAIL_NAME = "from_email.name",
  FROM_EMAIL = "from_email.email",
  REPLY_TO = "reply_to",
  SENDER_META_FROM_EMAIL = "sender_meta.from_email",
  SENDER_META_REPLY_TO = "sender_meta.reply_to",
  BCC_LIST = "bcc_email_data_set",
  CC_LIST = "cc_email_data_set",
  URL_PARAMS_ENABLED = "url_params_enabled",
  URL_PARAMS_LIST = "url_params_list",
  OVERRIDE_URL_PARAMS = "override_existing_url_params",
  OVERRIDE_EMAIL_LIMITS = "override_email_limits",
}

export enum VALUE_META_TYPE {
  TOKEN = "token",
  TEXT = "text",
}

export enum TEMPLATE_CC_BCC_TYPES {
  TOKEN = "token",
  COLUMN = "column",
  TEXT = "text",
}

export enum TEMPLATE_URL_PARAMETER_TYPE {
  TEXT = "text",
  TOKEN = "token",
  COLUMN = "column",
}

export const GLOBAL_EMAIL_CONFIG_INIT: GlobalEmailConfigType = {
  from_email: {
    name: "",
    email: "",
  },
  reply_to: "",
  bcc_email_data_set: null,
  url_params_enabled: false,
  url_params_list: [],
  override_existing_url_params: false,
};

export const SENDER_META_DEFAULT_INIT: SenderMeta = {
  sender_meta: {
    from_email: VALUE_META_TYPE.TEXT,
    from_name: VALUE_META_TYPE.TOKEN,
    reply_to: VALUE_META_TYPE.TEXT,
  },
};

export const GLOBAL_EMAIL_SENDER_META: SenderMeta = {
  sender_meta: {
    from_email: VALUE_META_TYPE.TEXT,
    from_name: VALUE_META_TYPE.TEXT,
    reply_to: VALUE_META_TYPE.TEXT,
  },
};

export const EMAIL_CONFIG_DATA_INIT: EmailConfigWithSubject = {
  subject: "",
  preheader: null,
  cc_email_data_set: null,
  ...GLOBAL_EMAIL_CONFIG_INIT,
  ...SENDER_META_DEFAULT_INIT,
};

export const EMAIL_CONFIG_ERRORS_INIT: EmailConfigErrors = {
  subject: "",
  from_email: {
    name: "",
    email: "",
  },
  reply_to: "",
  bcc_email_data_set: "",
  cc_email_data_set: "",
  preheader: "",
  url_params_list: {},
};

export const EMAIL_CONFIG_IS_REQ_INIT: EmailDataRequested = {
  from_email: {
    name: true,
    email: true,
  },
  preheader: true,
  reply_to: true,
  subject: true,
  bcc_email_data_set: true,
  cc_email_data_set: true,
  url_params_list: true,
  override_email_limits: true,
};

export const EMAIL_INPUTS_TO_VALIDATE = EMAIL_CONFIG_IS_REQ_INIT;

export enum PREVIEW_MODES {
  LIST = "list",
  PREVIEW = "preview",
}

export const EMAIL_CONFIG_TEXT_MAP = {
  fromEmailEmail: "From",
  fromEmailName: "Sender name",
  replyTo: "Reply-to",
  subject: "Subject",
  preheader: "Preheader",
  cc: "Cc",
  bcc: "Bcc",
  urlParamList: "Link parameters",
};

export const INIT_EMAIL_CONFIG_CHANGE_TRACKER: EmailConfigChangeTracker = {
  fromEmailEmail: false,
  fromEmailName: false,
  replyTo: false,
  subject: false,
  preheader: false,
  cc: false,
  bcc: false,
  urlParamList: false,
};

export const GLOBAL_EMAIL_CONFIG_IS_CODE: EmailDataRequested = {
  from_email: {
    name: false,
    email: false,
  },
  reply_to: false,
  bcc_email_data_set: false,

  subject: false,
  preheader: false,
  cc_email_data_set: false,
  url_params_list: false,
  override_email_limits: false,
};

export const GLOBAL_EMAIL_CONFIG_VALID_FIELDS: EmailDataRequested = {
  from_email: {
    name: true,
    email: true,
  },
  reply_to: true,
  bcc_email_data_set: true,

  //no subject , cc emails and preheader in global config
  subject: false,
  preheader: false,
  cc_email_data_set: false,
  url_params_list: true,
  override_email_limits: false,
};

export const MAX_CC_BCC_TOTAL_RECIPIENTS = 1000;

export const DEFAULT_URL_PARAM_CONFIG: TemplateUrlParametersConfig = {
  url_params_enabled: false,
  url_params_list: [],
  override_existing_url_params: false,
};

export enum URL_PARAMS_ON_CHANGE_OPTION {
  KEY = "key",
  VALUE = "value",
  TYPE = "type",
}
