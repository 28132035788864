import {
  NumberInputStepper,
  NumberIncrementStepper,
  Icon,
  NumberDecrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputFieldProps,
} from "@chakra-ui/react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useCallback, useState, useEffect } from "react";

export enum NUMBER_FIELD_TYPES {
  INTEGER = "integer",
  FLOAT = "float",
}

function IncDecNumberInput() {
  //increment and decrement the number input
  return (
    <NumberInputStepper>
      <NumberIncrementStepper>
        <Icon as={FaChevronUp} size="6px" />
      </NumberIncrementStepper>
      <NumberDecrementStepper>
        <Icon as={FaChevronDown} size="6px" />
      </NumberDecrementStepper>
    </NumberInputStepper>
  );
}

//Chakra doesn't have support for integer and float separately.
// Note: The min and max values only apply for steppers and not for the input field itself.
export default function NumberField({
  type = NUMBER_FIELD_TYPES.FLOAT,
  value,
  onValueChange,
  allowNegativeNumbers = true,
  fieldProps,
  ...props
}: {
  type: NUMBER_FIELD_TYPES;
  value: number | string;
  onValueChange: (value: number | string | null) => void;
  allowNegativeNumbers?: boolean;
  fieldProps?: NumberInputFieldProps;
} & NumberInputProps) {
  const [numValue, setNumValue] = useState<string | number | null>(value);
  const isInteger = type === NUMBER_FIELD_TYPES.INTEGER;

  const validateNegativeNumbers = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      //allow negative sign string, as the first character.
      if (allowNegativeNumbers && valueAsString === "-") {
        setNumValue(valueAsString);
        return true;
      }

      // if the user copy/pastes the negative number, restrict it.
      return !allowNegativeNumbers && valueAsNumber < 0;
    },
    [allowNegativeNumbers]
  );

  useEffect(() => {
    setNumValue(value);
  }, [value]);

  // valueAsString is used for decimals.
  // if the user enters the value "9.", valueAsString gives "9." and valueAsNumber gives 9, as "9." is not a valid number in js.
  function onChange(valueAsString: string, valueAsNumber: number) {
    if (validateNegativeNumbers(valueAsString, valueAsNumber)) return;

    const isInValidNumber = isNaN(valueAsNumber) || !isFinite(valueAsNumber);
    const numberValue = isInValidNumber ? null : Number(valueAsNumber);

    //ignore decimals
    if (isInteger && !valueAsString.includes(".")) {
      setNumValue(numberValue);
      onValueChange(numberValue);
    } else if (!isInteger) {
      // set value with decimal
      setNumValue(valueAsString);

      //if only its a valid number , pass to onChange
      if (!isInValidNumber) {
        onValueChange(numberValue);
      }
    }
  }

  const numFieldProps = {
    height: "32px",
    bg: "white",
    ...fieldProps,
  };

  return (
    <NumberInput
      value={numValue ?? ""}
      onChange={onChange}
      clampValueOnBlur={false}
      min={allowNegativeNumbers ? Number.MIN_SAFE_INTEGER : props.min ?? 0}
      {...props}
    >
      <NumberInputField {...numFieldProps} />
      <IncDecNumberInput />
    </NumberInput>
  );
}
