export enum TIMEZONE_FIELDS {
  SYSTEM_INFERRED = "SYSTEM_INFERRED",
  CUSTOM_TIMEZONE = "CUSTOM_TIMEZONE",
  TENANT_TIMEZONE = "TENANT_TIMEZONE",
}

export enum TRACKED_DOMAINS_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING_APPROVAL",
  REJECTED = "REJECTED",
}

export const WILDCARD_SUBDOMAIN_REGEX = new RegExp(/^\*(\.\*)*$/);

export const STATUS_BADGE_DETAILS = {
  [TRACKED_DOMAINS_STATUS.ACTIVE]: {
    bg: "green.500",
    color: "white",
  },
  [TRACKED_DOMAINS_STATUS.INACTIVE]: {
    bg: "yellow.400",
    color: "white",
  },
  [TRACKED_DOMAINS_STATUS.PENDING]: {
    bg: "gray.400",
    color: "white",
  },
  [TRACKED_DOMAINS_STATUS.REJECTED]: {
    bg: "red.400",
    color: "white",
  },
};

export enum TRACK_SUBDOMAIN_STATES {
  DEFAULT = "ALL",
  CUSTOM = "CUSTOM",
}

export enum TRACK_ACCESS_STATES {
  ALLOWED = "ALLOWED",
  BLOCKED = "BLOCKED",
}

export enum DOMAIN_VARIANTS {
  DOMAIN = "domain",
  SUBDOMAIN = "sub_domain",
}

export enum CODE_BOX_VARIANTS {
  DEFAULT = "default",
  TOGGLE = "toggle",
}

export enum EMAIL_SETTINGS_STATE {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum DEFAULT_COMM_LIMITS {
  DAILY = 2,
  WEEKLY = 10,
}
