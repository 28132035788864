import {
  Flex,
  Box,
  Icon,
  VStack,
  Divider,
  GridItem,
  Image,
  Text,
  Grid,
  useDisclosure,
  HStack,
  GridItemProps,
  Skeleton,
  ButtonProps,
  Heading,
} from "@chakra-ui/react";
import {
  cloneDeep,
  set,
  isEmpty,
  get,
  isEqual,
  has,
  isEqualWith,
} from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FaExternalLinkAlt, FaArrowLeft, FaCog } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  addEmptyContextIfBlank,
  addPunctuationSeparators,
  isLoading,
  isSuccess,
  openAssetInNewTab,
  validateEmailConfigInputs,
} from "../../../../../common/helper/commonHelper";
import {
  EMAIL_CONFIG_DATA_INIT,
  EMAIL_CONFIG_ERRORS_INIT,
  EMAIL_CONFIG_FIELDS,
  EMAIL_CONFIG_IS_REQ_INIT,
  VALUE_META_TYPE,
} from "../../../../../common/constants/template";
import { SendEmailActionOptions } from "../../../../../common/types/campaign";
import {
  CcBccListType,
  EmailConfigChangeTracker,
  EmailConfigWithSubject,
  TemplateExhaustive,
  TemplateType,
  TemplateUrlParameterList,
  TemplateUrlParametersConfig,
} from "../../../../../common/types/template";
import EmailConfigureInput from "../../../../../components/EmailConfigureInput";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { selectTemplate } from "../../../templates/templateSlice";
import { selectCampaign } from "../../campaignSlice";
import CommonDrawer from "../../components/CommonDrawer";
import { selectSettings } from "../../../settings/settingsSlice";
import { EMAIL_PREVIEW_VARIANTS } from "./constants";
import ChangeInFlowAlertModal from "./ChangeInFlowAlertModal";
import {
  ASSET_TYPES,
  EMPTY_CONTEXT,
} from "../../../../../common/constants/common";
import {
  transformRecipientDataListToStrings,
  getDisplayTextForOverridenFields,
} from "../../../../../common/helper/templateHelper";
import MemoizedCommonDrawer from "../../components/CommonDrawer";
import {
  ReadOnlyUrlParameters,
  TemplateUrlParametersDrawer,
} from "../../../templates/components/TemplateUrlParameters";
import EmailCommunicationLimitSettings from "../../../../../components/EmailCommunicationLimitSettings";
import InfoBanner from "../../../../../components/InfoBanner";

const META_FIELDS = ["from_email", "reply_to"];
const PREHEADER = "preheader";

function addPunctuationToCcBcc(list?: CcBccListType) {
  return list && !isEmpty(list)
    ? addPunctuationSeparators(transformRecipientDataListToStrings(list) ?? [])
    : null;
}

function OverriddenFieldsInfoBanner({
  isOverridden,
  overriddenFields,
  isReadonly,
  children,
}: {
  isOverridden: boolean;
  overriddenFields: string[];
  isReadonly?: boolean;
  children: ReactNode;
}) {
  return (
    <InfoBanner
      bg={isOverridden ? "brandOrange.50" : "brandBlue.100"}
      borderRadius={4}
      p={2}
      w="100%"
      fontSize="xs"
      color={isOverridden ? "brandOrange.100" : "brandBlue.500"}
      hidden={isReadonly && !isOverridden}
      iconProps={{
        color: isOverridden ? "brandOrange.100" : "brandBlue.500",
      }}
    >
      {isOverridden ? (
        <Text>
          This asset has overrides for{" "}
          {addPunctuationSeparators(overriddenFields)}
        </Text>
      ) : (
        <>{children}</>
      )}
    </InfoBanner>
  );
}

function TemplateChangeAlertModal({
  onClose,
  isOpen,
  onConfirm,
}: {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: () => void;
}) {
  return (
    <ChangeInFlowAlertModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Confirm email asset change"
      content="Are you sure that you want to change the email asset?"
      infoText="Please note that changing the email asset would reset the 
      email metrics as the reporting is based off the email asset."
    />
  );
}
function OverrideEmailConfigDrawer({
  onClose,
  isOpen,
  templateName,
  templateDetails,
  actionOptions,
  onSave,
}: {
  onClose: () => void;
  isOpen: boolean;
  templateName?: string;
  templateDetails: TemplateExhaustive;
  actionOptions: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
}) {
  const {
    globalDefault: { data: globalDefault },
  } = useSelector(selectSettings);

  const [inputs, setInputs] = useState(EMAIL_CONFIG_DATA_INIT);
  const [errors, setErrors] = useState(EMAIL_CONFIG_ERRORS_INIT);
  const [validity, setValidity] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  // handles change in input and validates data
  function handleInputChange(
    inputName: EMAIL_CONFIG_FIELDS,
    inputVal: string | CcBccListType | boolean | TemplateUrlParameterList
  ) {
    setInputs((prev) => {
      const newInputs = cloneDeep(prev);
      set(newInputs, inputName, inputVal);
      validateInputs(newInputs);
      return newInputs;
    });
  }

  //validates input
  const validateInputs = useCallback((inputData: EmailConfigWithSubject) => {
    const { validity, errors } = validateEmailConfigInputs(inputData);
    setErrors(errors);
    setValidity(validity);
    return validity;
  }, []);

  //loads initial data on open of Modal, and always loads the last save values
  useEffect(() => {
    if (isOpen) {
      const emailData = {
        subject: actionOptions.subject ?? templateDetails.subject ?? "",
        preheader: has(actionOptions, PREHEADER)
          ? actionOptions.preheader
          : templateDetails.preheader ?? null,
        from_email: {
          name:
            actionOptions.from_email?.name ??
            templateDetails.from_email?.name ??
            globalDefault?.from_email?.name ??
            "",
          email:
            actionOptions.from_email?.email ??
            templateDetails.from_email?.email ??
            globalDefault?.from_email?.email ??
            "",
        },
        reply_to:
          actionOptions.reply_to ??
          templateDetails.reply_to ??
          globalDefault?.reply_to ??
          "",
        sender_meta: {
          from_email:
            actionOptions.sender_meta?.from_email ??
            templateDetails.sender_meta?.from_email ??
            VALUE_META_TYPE.TEXT,
          reply_to:
            actionOptions.sender_meta?.reply_to ??
            templateDetails.sender_meta?.reply_to ??
            VALUE_META_TYPE.TEXT,
          from_name: VALUE_META_TYPE.TOKEN,
        },
        bcc_email_data_set:
          actionOptions.bcc_email_data_set ??
          templateDetails.bcc_email_data_set ??
          [],
        //global bcc data will be passed as readonly in the component itself
        cc_email_data_set:
          actionOptions.cc_email_data_set ??
          templateDetails?.cc_email_data_set ??
          [],
        url_params_enabled:
          actionOptions?.url_params_enabled ??
          templateDetails.url_params_enabled ??
          false,
        url_params_list:
          actionOptions?.url_params_list ??
          templateDetails.url_params_list ??
          [],
        override_existing_url_params:
          actionOptions?.override_existing_url_params ??
          templateDetails.override_existing_url_params ??
          false,
      };
      setInputs(emailData);
    }
  }, [isOpen, actionOptions, globalDefault, templateDetails]);

  function onCloseModal() {
    onClose();
    setShowErrors(false);
  }

  // saves and shows error if data is invalid
  function onSaveEmailConfig(data: SendEmailActionOptions) {
    let updatedConfig = {};

    Object.entries(data).forEach(([key, value]) => {
      const templateValue = get(templateDetails, key);
      const isTemplateValueUndefined = templateValue !== undefined;
      const isValueNullOrEmptyList =
        value !== null && !(Array.isArray(value) && value.length === 0);
      if (
        !isEqual(templateValue, value) &&
        (isTemplateValueUndefined || isValueNullOrEmptyList)
      ) {
        set(updatedConfig, key, value);
        if (META_FIELDS.includes(key)) {
          updatedConfig = {
            ...updatedConfig,
            sender_meta: { ...data?.sender_meta },
          };
        }
      }
    });
    if (!validateInputs(data as EmailConfigWithSubject)) {
      setShowErrors(true);
    } else {
      onSave?.({
        ...updatedConfig,
        template_id: templateDetails.template_id,
      });
      onCloseModal();
      setShowErrors(false);
    }
  }
  return (
    <MemoizedCommonDrawer
      isOpen={isOpen}
      onClose={onCloseModal}
      placement="right"
      size="md"
      title="Email configuration"
      drawerBodyProps={{ px: 3 }}
      drawerOverlayProps={{ zIndex: 1400 }}
      drawerContentProps={{ transform: "none !important" }} //codemirror options won't be displayed if this is not set to none
    >
      <Text fontSize="14px" px={3}>
        Here you can edit the subject, pre-header, sender name, send from,
        reply-to, cc and bcc details for your email.
      </Text>

      <EmailConfigureInput
        templateName={templateName}
        inputs={inputs}
        onChange={handleInputChange}
        errors={showErrors ? errors : EMAIL_CONFIG_ERRORS_INIT}
        readOnlyGlobalBccData={globalDefault?.bcc_email_data_set}
        inputPaddingX="3"
        showSectionWithHeaders
        requiredFields={{
          ...EMAIL_CONFIG_IS_REQ_INIT,
          override_email_limits: false,
        }}
      ></EmailConfigureInput>
      <HStack justifyContent="flex-end" w="100%" pt={4}>
        <IButton variant={BUTTON.SECONDARY} onClick={onCloseModal}>
          Cancel
        </IButton>
        <IButton
          onClick={() => onSaveEmailConfig(inputs as SendEmailActionOptions)}
          isDisabled={showErrors && !validity}
        >
          Save
        </IButton>
      </HStack>
    </MemoizedCommonDrawer>
  );
}

function GridItemBox({
  text,
  isLoading,
  ...props
}: { text?: string | null; isLoading?: boolean } & GridItemProps) {
  return (
    <GridItem
      fontSize="sm"
      color={text ? "brandBlue.500" : "gray.400"}
      wordBreak="break-word"
      {...props}
    >
      <Skeleton isLoaded={!isLoading} minH="20px" minW="100px">
        {addEmptyContextIfBlank(text)}
      </Skeleton>
    </GridItem>
  );
}

export function EmailTemplateDetails({
  goBack,
  variant,
  actionOptions,
  onSave,
  emailConfigChange,
}: {
  goBack?: () => void;
  variant: EMAIL_PREVIEW_VARIANTS | null;
  actionOptions?: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
  emailConfigChange?: EmailConfigChangeTracker;
}) {
  const {
    templateDetails: { data: templateDetails, loading },
    templateName,
  } = useSelector(selectTemplate);
  const { globalDefault } = useSelector(selectSettings);

  const {
    isOpen: isOpenConfig,
    onClose: onCloseConfig,
    onOpen: onOpenConfig,
  } = useDisclosure();

  const {
    isOpen: isOpenUrlParams,
    onClose: onCloseUrlParams,
    onOpen: onOpenUrlParams,
  } = useDisclosure();

  const isReadonly = variant === EMAIL_PREVIEW_VARIANTS.READONLY;
  const isLoadingTemplateDetails = isLoading(loading);
  const urlParameterDetails = {
    url_params_enabled:
      actionOptions?.url_params_enabled ??
      templateDetails.url_params_enabled ??
      false,
    url_params_list:
      actionOptions?.url_params_list ?? templateDetails.url_params_list ?? [],
    override_existing_url_params:
      actionOptions?.override_existing_url_params ??
      templateDetails.override_existing_url_params ??
      false,
  };

  const { subject, preheader } = useMemo(() => {
    const subject =
      actionOptions?.subject ?? templateDetails?.subject ?? EMPTY_CONTEXT;
    const preheader = has(actionOptions, PREHEADER)
      ? actionOptions!.preheader
      : templateDetails.preheader ?? null;

    return { subject, preheader };
  }, [actionOptions, templateDetails?.subject, templateDetails?.preheader]);

  function onSaveUrlParameters(data: TemplateUrlParametersConfig) {
    const updatedActionOption = actionOptions ?? {
      template_id: templateDetails.template_id,
    };

    Object.entries(data).forEach(([key, value]: [string, any]) => {
      if (!isEqualWith(get(data, key), get(urlParameterDetails, key))) {
        set(updatedActionOption, key, value);
      }
    });

    onSave?.({
      ...updatedActionOption,
    });
  }

  function onOverrideEmailDeliveryLimit(val: boolean) {
    let updatedActionOption = actionOptions ?? {
      template_id: templateDetails.template_id,
    };

    onSave?.({
      ...updatedActionOption,
      override_email_limits: val,
    });
  }

  const emailConfigOverrideDetails = {
    subject: emailConfigChange?.subject,
    fromEmailEmail: emailConfigChange?.fromEmailEmail,
    fromEmailName: emailConfigChange?.fromEmailName,
    replyTo: emailConfigChange?.replyTo,
    preheader: emailConfigChange?.preheader,
    cc: emailConfigChange?.cc,
    bcc: emailConfigChange?.bcc,
  };

  const isEmailConfigOverridden = Object.values(
    emailConfigOverrideDetails ?? {}
  ).some((value) => value);

  const isLinkParameterOverridden = !!emailConfigChange?.urlParamList;
  const emailDeliveryLimitOverride =
    actionOptions?.override_email_limits ??
    templateDetails.override_email_limits ??
    false;

  return (
    <VStack alignItems="start">
      {variant === EMAIL_PREVIEW_VARIANTS.SELECTION && (
        <IButton
          size="md"
          aria-label="Go back"
          variant="link"
          name="return-button"
          onClick={goBack}
          fontSize="xs"
          pb={2}
          leftIcon={<Icon fontSize="xs" as={FaArrowLeft} />}
        >
          Back to email assets
        </IButton>
      )}
      <Skeleton minH="24px" minW="150px" isLoaded={!isLoadingTemplateDetails}>
        <Text
          fontSize="16"
          fontWeight="600"
          color="brandBlue.500"
          maxH="200px"
          overflow="auto"
        >
          {templateName}
        </Text>
      </Skeleton>

      {variant !== EMAIL_PREVIEW_VARIANTS.SELECTION && (
        <>
          <VStack spacing={2} align="start" w="100%">
            <OverriddenFieldsInfoBanner
              isOverridden={isEmailConfigOverridden}
              overriddenFields={getDisplayTextForOverridenFields(
                emailConfigOverrideDetails
              )}
            >
              <Text>
                You can add overrides to Subject, From, Reply-to, Sender name,
                Cc and Bcc fields
              </Text>
            </OverriddenFieldsInfoBanner>

            <IButton
              mx={2}
              variant="link"
              color="brandBlue.500"
              fontWeight="normal"
              leftIcon={<Icon as={FaCog} fontSize="xs" />}
              onClick={onOpenConfig}
              hidden={isReadonly}
              children="Email configuration"
            />
          </VStack>

          {actionOptions && variant === EMAIL_PREVIEW_VARIANTS.UPDATION && (
            <OverrideEmailConfigDrawer
              onClose={onCloseConfig}
              isOpen={isOpenConfig}
              templateName={templateName}
              templateDetails={templateDetails}
              onSave={onSave}
              actionOptions={actionOptions}
            />
          )}
        </>
      )}
      <Box py={2}>
        <Text fontSize="10px" color="brandBlue.500" pb={1}>
          Subject
        </Text>
        <GridItemBox
          maxH="200px"
          overflow="auto"
          text={subject}
          isLoading={isLoadingTemplateDetails}
        />
        {preheader && (
          <>
            <Text fontSize="10px" color="brandBlue.500" py={1}>
              Preheader
            </Text>
            <GridItemBox
              maxH="200px"
              overflow="auto"
              text={preheader}
              isLoading={isLoadingTemplateDetails}
            />
          </>
        )}
      </Box>
      <Divider />
      <Grid templateColumns="60px 1fr" gap={2}>
        <GridItemBox text="From:" />
        <GridItemBox
          text={
            actionOptions?.from_email?.email ??
            templateDetails.from_email?.email ??
            globalDefault?.data?.from_email?.email
          }
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Name:" />
        <GridItemBox
          text={
            actionOptions?.from_email?.name ??
            templateDetails.from_email?.name ??
            globalDefault?.data?.from_email?.name
          }
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Reply to:" />
        <GridItemBox
          text={
            actionOptions?.reply_to ??
            templateDetails.reply_to ??
            globalDefault?.data?.reply_to
          }
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Cc:" />
        <GridItemBox
          text={addPunctuationToCcBcc(
            actionOptions?.cc_email_data_set ??
              templateDetails.cc_email_data_set
          )}
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Bcc:" />
        <GridItemBox
          text={addPunctuationToCcBcc([
            ...(templateDetails.global_bcc_email_data_set ?? []),
            ...(actionOptions?.bcc_email_data_set ??
              templateDetails.bcc_email_data_set ??
              []),
          ])}
          isLoading={isLoadingTemplateDetails}
        />
      </Grid>

      <Heading fontSize="14px" color="text.100" py={1}>
        Link parameter
      </Heading>
      <OverriddenFieldsInfoBanner
        isOverridden={isLinkParameterOverridden}
        overriddenFields={getDisplayTextForOverridenFields({
          urlParamList: !!emailConfigChange?.urlParamList,
        })}
      >
        <Text>You can add overrides to link parameters</Text>
      </OverriddenFieldsInfoBanner>
      <TemplateUrlParametersDrawer
        isOpen={isOpenUrlParams}
        onClose={onCloseUrlParams}
        urlParameterDetails={urlParameterDetails}
        onSaveUrlParameter={onSaveUrlParameters}
        saveCancelButton
      />
      <IButton
        onClick={onOpenUrlParams}
        mx={2}
        variant="link"
        color="brandBlue.500"
        fontWeight="normal"
        leftIcon={<Icon as={FaCog} fontSize="xs" />}
        hidden={isReadonly}
        children="Link parameter configuration"
      />

      <ReadOnlyUrlParameters
        urlParameterList={urlParameterDetails.url_params_list}
        flexDir="column"
        spacing={3}
      />

      <EmailCommunicationLimitSettings
        ignoreLimits={emailDeliveryLimitOverride}
        onLimitChange={onOverrideEmailDeliveryLimit}
        infoText={`Email delivery limits are ${
          emailDeliveryLimitOverride ? "not" : ""
        } applied.`}
        titleProps={{ variant: "mainText" }}
      />
    </VStack>
  );
}

export function EmailTemplatePreviewFrame() {
  const { templateDetails } = useSelector(selectTemplate);

  function showPreview() {
    if (isLoading(templateDetails.loading)) {
      return <Skeleton height="100%" width="500px" />;
    }
    if (templateDetails.data.previews) {
      return (
        <Image
          src={templateDetails.data.previews?.img_large}
          alt="email asset unavailable"
          height="fit-content"
          maxH="100%"
        />
      );
    } else {
      return (
        <Box
          w="500px"
          h="100%"
          bg="whiteAlpha.900"
          fontSize="12px"
          color="gray.400"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          No email asset added
        </Box>
      );
    }
  }

  return (
    <Box
      w="70%"
      h="100%"
      bg="gray.100"
      borderRightRadius={4}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {showPreview()}
      <IButton
        variant="primary"
        name="external-link-icon"
        bg="gray.300"
        position="absolute"
        top="10px"
        right="10px"
        borderRadius={6}
        customContent={true}
        onClick={() =>
          openAssetInNewTab(
            ASSET_TYPES.TEMPLATE,
            templateDetails.data.template_id
          )
        }
        _hover={{
          bg: "gray.200",
        }}
        hidden={!isSuccess(templateDetails.loading)}
      >
        <Icon as={FaExternalLinkAlt} fontSize="sm" color="brandBlue.500" />
      </IButton>
    </Box>
  );
}

export default function EmailTemplatePreview({
  goBack,
  variant,
  isOpen,
  closeAllModal,
  handleSelectChange,
  onSave = () => {},
  actionOptions,
  emailConfigChange,
  onTemplateDrawerOpen,
  primaryButtonProps,
}: {
  goBack?: () => void;
  variant: EMAIL_PREVIEW_VARIANTS | null;
  isOpen: boolean;
  closeAllModal: () => void;
  handleSelectChange: (templateDetails: TemplateType) => void;
  actionOptions?: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
  emailConfigChange?: EmailConfigChangeTracker;
  onTemplateDrawerOpen: () => void;
  primaryButtonProps?: ButtonProps;
}) {
  const { templateDetails } = useSelector(selectTemplate);
  const {
    isOpen: isTemplateChangeAlertOpen,
    onClose: onTemplateChangeAlertClose,
    onOpen: onTemplateChangeAlertOpen,
  } = useDisclosure();

  function handleChangeTemplate() {
    closeAllModal();
    if (
      campaignDetails.data.activated_at ||
      campaignDetails.data.deactivated_at
    )
      onTemplateChangeAlertOpen();
    else onTemplateDrawerOpen();
  }
  const { campaignDetails } = useSelector(selectCampaign);
  const isLoadingTemplateDetails = isLoading(templateDetails.loading);

  return (
    <>
      <CommonDrawer
        size="xl"
        placement="right"
        title="Email preview"
        isOpen={isOpen}
        onClose={closeAllModal}
        drawerBodyProps={{
          pb: "0px",
          pl: "0px",
          pr: "20px",
        }}
        // providing a custom size
        drawerContentProps={{
          w: "1040px",
          maxW: "1040px",
        }}
        additionalHeaderContent={
          variant === EMAIL_PREVIEW_VARIANTS.UPDATION ? (
            <IButton
              name="drawer-secondary-button"
              mr={10}
              variant={BUTTON.SECONDARY}
              onClick={handleChangeTemplate}
            >
              Change email asset
            </IButton>
          ) : null
        }
      >
        <Flex h="100%" flexWrap="wrap" gap={4}>
          <EmailTemplatePreviewFrame />
          <Box flex={1} position="relative" h="100%" overflow="auto">
            <EmailTemplateDetails
              goBack={goBack}
              variant={variant}
              actionOptions={actionOptions}
              onSave={onSave}
              emailConfigChange={emailConfigChange}
            />
            {variant === EMAIL_PREVIEW_VARIANTS.SELECTION && (
              <Flex
                justifyContent="flex-end"
                position="absolute"
                bottom={3}
                right={0}
              >
                <IButton
                  variant="ghost"
                  fontSize="xs"
                  name="cancel-button"
                  mr={3}
                  onClick={closeAllModal}
                  isDisabled={isLoadingTemplateDetails}
                  children="Cancel"
                />
                <IButton
                  name="select-button"
                  fontSize="xs"
                  variant="primary"
                  onClick={() =>
                    handleSelectChange(templateDetails?.data ?? null)
                  }
                  isDisabled={isLoadingTemplateDetails}
                  children="Select"
                  {...primaryButtonProps}
                />
              </Flex>
            )}
          </Box>
        </Flex>
      </CommonDrawer>

      <TemplateChangeAlertModal
        isOpen={isTemplateChangeAlertOpen}
        onClose={onTemplateChangeAlertClose}
        onConfirm={onTemplateDrawerOpen}
      />
    </>
  );
}
