import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isFailed, isLoading } from "../../../../common/helper/commonHelper";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import TriggerCampaignBuilder from "./TriggerCampaignBuilder";
import {
  archiveTriggerCampaign,
  clearCampaignDetails,
  getTriggerCampaign,
  selectTrigger,
  updateTriggerCampaign,
} from "./triggerCampaignSlice";
import {
  CAMPAIGN_STATUS_FROM_TRIGGER,
  CONTEXT_FROM_TARGET,
  JOURNEY_TYPES,
  TRIGGER_CAMPAIGN_STATES,
} from "../../../../common/constants/trigger";
import { TriggerCampaignUpdatableData } from "../../../../common/types/trigger";
import { TriggerCampaignValidationType } from "../../../../common/types/dynamicList";
import { getCampaignFlow, selectFlow } from "../flow/flowSlice";
import { toast } from "react-toastify";

export default function TriggerCampaign() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(getTriggerCampaign(id));
      dispatch(getCampaignFlow(id));
    }
  }, [id, dispatch]);

  const {
    triggerCampaignDetails: {
      loading: isCampaignDetailsLoading,
      data: campaignDetails,
    },
    savingDraft,
    activeErrorCheck,
  } = useSelector(selectTrigger);

  const { isEditingFlow } = useSelector(selectFlow);

  useEffect(() => {
    if (isFailed(isCampaignDetailsLoading)) {
      toast.error("Journey not found");
      navigate(urls.journey);
    }
  }, [navigate, isCampaignDetailsLoading]);

  // const validity: TriggerCampaignValidationType = {
  //   audienceCriteria: "",
  //   flow: "",
  //   schedule: "",
  //   rule: "",
  //   exitCriteria: "",
  //   triggerCriteria: "",
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validity, setValidity] = useState<TriggerCampaignValidationType>({
    audienceCriteria: "",
    flow: "",
    schedule: "",
    rule: "",
    exitCriteria: "",
    triggerCriteria: "",
  });

  const initAudienceCriteria = useMemo(() => {
    return campaignDetails.audienceCriteria;
  }, [campaignDetails.audienceCriteria]);

  const initSchedule = useMemo(() => {
    return campaignDetails.journeySettings?.timeframe || null;
  }, [campaignDetails.journeySettings?.timeframe]);

  const initRule = useMemo(() => {
    return campaignDetails.journeySettings?.qualificationFrequency || null;
  }, [campaignDetails.journeySettings?.qualificationFrequency]);

  const initExitCriteria = useMemo(() => {
    return campaignDetails.exitCriteria;
  }, [campaignDetails.exitCriteria]);

  const initTriggerCriteria = useMemo(() => {
    return campaignDetails.triggerCriteria;
  }, [campaignDetails.triggerCriteria]);

  useEffect(() => {
    return () => {
      dispatch(clearCampaignDetails());
    };
  }, [dispatch]);

  const saveDraft = useCallback(
    async (currentData: TriggerCampaignUpdatableData) => {
      if (campaignDetails.currentState === TRIGGER_CAMPAIGN_STATES.PAUSED) {
        await dispatch(archiveTriggerCampaign(campaignDetails.campaignId));
      }
      dispatch(
        updateTriggerCampaign({
          campaignId: campaignDetails.campaignId,
          data: currentData,
        })
      );
    },
    [campaignDetails.campaignId, campaignDetails.currentState, dispatch]
  );

  return (
    <TriggerCampaignBuilder
      campaignDetails={{
        campaignName: campaignDetails.campaignName,
        campaignId: campaignDetails.campaignId,
        campaignContext:
          CONTEXT_FROM_TARGET[campaignDetails.campaignTarget] ??
          CAMPAIGN_CONTEXT.PERSON,
        status: CAMPAIGN_STATUS_FROM_TRIGGER[campaignDetails.currentState],
        isActivatedOnce: true,
        updatedBy: campaignDetails.updatedBy,
        isSavingDraft: savingDraft || isEditingFlow,
        isPublishingCampaign: false,
        campaignTags: campaignDetails.campaignTags ?? [],
        updatedAt: campaignDetails.updatedAt,
        fetchingDetails: isLoading(isCampaignDetailsLoading),
        activeErrorCheck: activeErrorCheck,
        campaignType: JOURNEY_TYPES.TRIGGER,
        currentState: campaignDetails.currentState,
        overrideEmailLimits: false,
      }}
      initData={{
        audienceCriteria: initAudienceCriteria,
        schedule: initSchedule,
        rule: initRule,
        exitCriteria: initExitCriteria,
        triggerCriteria: initTriggerCriteria,
        validity: validity,
      }}
      saveDraft={saveDraft}
    />
  );
}
