import { HStack, Icon, VStack, Text, Box, IconProps } from "@chakra-ui/react";
import { capitalize, toLower } from "lodash";
import { IconType } from "react-icons";
import { FaClock, FaRegClock } from "react-icons/fa";
import {
  CAMPAIGN_QUALIFICATION_RULES,
  QUALIFICATION_RULES_OPTIONS,
  RECURRING_FREQUENCIES,
} from "../../../../../../common/constants/campaign";
import { addSuffixForPlural } from "../../../../../../common/helper/commonHelper";
import {
  CampaignRuleResp,
  CampaignSchedule,
  RUN_TYPE,
} from "../../../../../../common/types/campaign";
import { formatDateString } from "../../../../../../components/DateTimeRangeFilter";
import { SectionItem, SectionLink } from "./SectionWrapper";
import { PiUserFocusFill } from "react-icons/pi";
import { useState, useEffect } from "react";
import { BiMailSend } from "react-icons/bi";
import InfoBanner from "../../../../../../components/InfoBanner";

const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });

const suffixes = {
  zero: "",
  one: "st",
  two: "nd",
  few: "rd",
  many: "th",
  other: "th",
};

function suffixOrdinal(number: number) {
  const category = englishOrdinalRules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
}

const buildFrequencyRange = (data: CampaignSchedule) => {
  let text = "Run ";

  if (data.run_type === RUN_TYPE.ONCE) {
    text +=
      data.run_config.start_date &&
      `once ${
        data.run_config.start_date
          ? `on ${formatDateString({
              date: data.run_config.start_date,
              showTime: true,
            })}`
          : ""
      }`;
  } else if (data.run_type === RUN_TYPE.AT_ONCE) {
    text += "immediately";
  } else if (data.run_type === RUN_TYPE.RECURRING) {
    switch (data.run_config.frequency) {
      case RECURRING_FREQUENCIES.DAY:
        text += `every ${
          data.run_config.freq_options.unit ?? ""
        } ${addSuffixForPlural(
          data.run_config.frequency,
          data.run_config.freq_options.unit
        )}`;
        break;
      case RECURRING_FREQUENCIES.WEEK:
        text += `every week`;
        if (data.run_config.freq_options.day) {
          text += ` on ${capitalize(data.run_config.freq_options.day)}`;
        }
        break;
      case RECURRING_FREQUENCIES.MONTH:
        if (data.run_config.freq_options.date_string === "custom") {
          text += `every ${suffixOrdinal(
            Number(data.run_config.freq_options.day)
          )} day of the month`;
        } else {
          text += `every ${
            data.run_config.freq_options.date_string?.split("_")[0]
          } day of the month`;
        }
        break;
      case RECURRING_FREQUENCIES.YEAR:
        if (data.run_config.freq_options.date) {
          text += ` on ${formatDateString({
            date: data.run_config.freq_options.date,
            showTime: true,
          })}`;
        }
        break;
    }
  } else if (data.run_type === RUN_TYPE.PERIOD) {
    if (data.run_config.frequency) {
      text += `every ${data.run_config.unit ?? ""} ${
        data.run_config.frequency
      }`;
    }
  }

  if (text === "Run ") {
    text = "Not scheduled";
  }

  return text.trim();
};

const buildTimeData = (date: string, rangeType: string) => {
  return `Scheduled to ${
    rangeType === "start" ? "start" : "end"
  } on ${formatDateString({ date, showTime: true })}`;
};

function buildQualifyRulesText(rule: CampaignRuleResp) {
  let text = "";

  if (
    rule.rule === CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME ||
    rule.rule === CAMPAIGN_QUALIFICATION_RULES.ONCE
  ) {
    text += `Qualify contacts ${toLower(
      QUALIFICATION_RULES_OPTIONS[rule.rule]
    )}`;
  } else if (rule.rule === CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY) {
    if (rule.recurring_period && rule.recurring_period_unit)
      text += `Qualify contacts ${toLower(
        QUALIFICATION_RULES_OPTIONS[rule.rule]
      )} ${rule.recurring_period} ${addSuffixForPlural(
        rule.recurring_period_unit?.slice(0, -1),
        rule.recurring_period
      )}`;
  }

  return text;
}

function IconWithTextAndDesc({
  icon,
  label,
  description,
  iconProps,
}: {
  icon: IconType;
  label: string;
  description?: string;
  iconProps?: IconProps;
}) {
  return (
    <HStack w="100%" p={1}>
      <VStack alignItems="flex-start" spacing="0">
        <HStack spacing="6px" alignItems="flex-start">
          <Icon
            color="blue.700"
            fontSize="13px"
            as={icon}
            mt="4px"
            {...iconProps}
          />
          <Text color="gray.800" fontSize="14px">
            {label}
          </Text>
        </HStack>
        <Box>
          <Text
            ml="22px"
            fontWeight="bold"
            fontSize="xs"
            color="oneOffs.subtitle"
          >
            {description}
          </Text>
        </Box>
      </VStack>
    </HStack>
  );
}

export default function CampaignScheduleReadonly({
  data,
  rule,
  scheduleErrorText,
  ruleErrorText,
  overrideEmailLimits,
  onOpen,
}: {
  data: CampaignSchedule;
  rule: CampaignRuleResp;
  scheduleErrorText: string;
  ruleErrorText: string;
  overrideEmailLimits: boolean;
  onOpen?: any;
}) {
  const [errorText, setErrorText] = useState<string>("");
  useEffect(() => {
    let text = "";
    if (ruleErrorText && scheduleErrorText) {
      text += "Schedule and qualification is not configured";
    } else if (scheduleErrorText) {
      text += "Schedule is not configured";
    }
    setErrorText(text);
  }, [ruleErrorText, scheduleErrorText]);

  return (
    <>
      {data.run_type ? (
        <VStack spacing={0} alignItems="flex-start" w="100%" gap="10px">
          <SectionItem
            infoText="Schedule the journey based on the following timings"
            errorText={scheduleErrorText}
          >
            <IconWithTextAndDesc
              label={buildFrequencyRange(data)}
              description=""
              icon={FaClock}
            />
            {data.run_type !== RUN_TYPE.ONCE &&
              data.run_type !== RUN_TYPE.AT_ONCE && (
                <>
                  <VStack spacing="10px" mb={2}>
                    <VStack position="relative">
                      {data.run_config.start_date && (
                        <IconWithTextAndDesc
                          label={buildTimeData(
                            data.run_config.start_date,
                            "start"
                          )}
                          description=""
                          icon={FaClock}
                        />
                      )}
                      {data.run_config.end_date && (
                        <Box
                          borderLeft="1px dotted"
                          height="100%"
                          position="absolute"
                          top="12px"
                          left="10px"
                        />
                      )}
                    </VStack>
                    {data.run_config.end_date && (
                      <>
                        <IconWithTextAndDesc
                          label={buildTimeData(data.run_config.end_date, "end")}
                          description=""
                          icon={FaClock}
                        />
                      </>
                    )}
                  </VStack>
                </>
              )}
          </SectionItem>
          <Box mt={2}>
            <SectionItem
              infoText="Define multiple entry qualification"
              errorText={ruleErrorText}
            >
              <IconWithTextAndDesc
                label={buildQualifyRulesText(rule)}
                description=""
                icon={PiUserFocusFill}
              />
            </SectionItem>
          </Box>

          {overrideEmailLimits && (
            <SectionItem
              infoText="Email delivery limits"
              errorText={""}
              spacing={1}
            >
              <IconWithTextAndDesc
                label="Ignore email send limits for this Journey"
                icon={BiMailSend}
                iconProps={{ fontSize: 16 }}
              />
              <InfoBanner
                iconProps={{
                  color: "basic.orange",
                  fontSize: 14,
                  ml: 1,
                  mb: 2,
                }}
                bg="transparent"
                w="100%"
              >
                <Text color="basic.orange" fontSize={12}>
                  Emails will be sent to users even if communication limits are
                  hit.
                </Text>
              </InfoBanner>
            </SectionItem>
          )}
        </VStack>
      ) : (
        <SectionLink
          onOpen={onOpen}
          title="Set journey schedule and qualification rules"
          LinkIcon={FaRegClock}
          errorText={errorText}
        />
      )}
    </>
  );
}
