import { Box, useTheme } from "@chakra-ui/react";
import { Completion } from "@codemirror/autocomplete";
import { EditorView, ViewPlugin } from "@codemirror/view";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CODEMIRROR_SIZE } from "../../common/constants/common";
import {
  createCompletion,
  createCompletionResult,
  createHighlightDefaultVariableViewPlugin,
  createHighlightViewPlugin,
  DEFAULT_VARIABLE,
} from "../../common/helper/codemirrorHelper";
import { isSuccess } from "../../common/helper/commonHelper";
import {
  CodemirrorAutoCompleteFilter,
  CodemirrorType,
} from "../../common/types/common";
import {
  getAllColumns,
  listAllEmailTokens,
  selectEmailToken,
} from "../../pages/dashboard/emailtoken/emailTokenSlice";
import { selectSettings } from "../../pages/dashboard/settings/settingsSlice";
import Codemirror from "./Codemirror";
import { EditorState } from "@codemirror/state";

const NORMAL_STATE_STYLE = {
  [CODEMIRROR_SIZE.TEXT_AREA]: {
    borderRadius: "7px",
    height: "170px",
    fontSize: "14px",
  },
  [CODEMIRROR_SIZE.MINI_TEXT_AREA]: {
    borderRadius: "7px",
    height: "100px",
    width: "100%",
    fontSize: "14px",
  },
  [CODEMIRROR_SIZE.SINGLE_LINE]: {
    borderRadius: "7px",
    height: "40px",
    width: "100%",
    fontSize: "14px",
  },
  [CODEMIRROR_SIZE.STRICT_SINGLE_LINE]: {
    borderRadius: "7px",
    height: "40px",
    width: "100%",
    fontSize: "14px",
  },
  [CODEMIRROR_SIZE.EDITOR]: {
    height: "100%",
  },
  [CODEMIRROR_SIZE.MINI_EDITOR]: {
    borderRadius: "7px",
    height: "210px",
    width: "100%",
    fontSize: "14px",
  },
};

function findClassname(type: CODEMIRROR_SIZE) {
  switch (type) {
    case CODEMIRROR_SIZE.SINGLE_LINE:
    case CODEMIRROR_SIZE.STRICT_SINGLE_LINE:
      return "input-cm";
    case CODEMIRROR_SIZE.READ_ONLY_CODE_EDITOR:
      return "readonly-code-cm";
    default:
      return "";
  }
}

export default function CodemirrorInput({
  type = CODEMIRROR_SIZE.EDITOR,
  value,
  onChange,
  isInvalid,
  defaultVariables,
  reinitialize,
  extensions = [],
  isDisabled,
  filterTokens,
  filterColumnAccessors,
  additionalContent,
  ...props
}: {
  type?: CODEMIRROR_SIZE;
  value: string;
  onChange: (value: string) => void;
  isInvalid?: boolean;
  defaultVariables?: boolean;
  additionalContent?: ReactNode;
} & Partial<CodemirrorType> &
  CodemirrorAutoCompleteFilter) {
  const theme = useTheme();
  const colors = {
    brandBlue: theme.__cssVars["--chakra-colors-brand-blue"],
    red: theme.__cssVars["--chakra-colors-red-500"],
  };

  const codemirrorStateStyles = {
    ...NORMAL_STATE_STYLE,
    [CODEMIRROR_SIZE.READ_ONLY_CODE_EDITOR]: {
      borderBottomRadius: "7px",
      height: "115px",
      fontSize: "14px",
      color: colors.brandBlue,
    },
  };

  const errorStateStyle = {
    borderColor: colors.red,
    boxShadow: `0 0 0 2px ${colors.red}`,
  };

  const dispatch = useDispatch();

  const [autoCompleteItems, setAutoCompleteItems] = useState<Completion[]>([]);
  const [viewPlugins, setViewPlugins] = useState<ViewPlugin<any>[]>([]);

  const reinitCodemirror = useRef(false);

  // eslint-disable-next-line
  const autoCompleteOptions = useCallback(
    createCompletionResult(autoCompleteItems),
    [autoCompleteItems]
  );

  const {
    allColumnsList,
    emailTokenList: {
      listAll: { data: allEmailTokenList, loading: fetchingTokenList },
    },
  } = useSelector(selectEmailToken);

  const { fetchingPersonOrgMapping } = useSelector(selectSettings);

  useEffect(() => {
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(fetchingPersonOrgMapping)) {
      dispatch(getAllColumns());
    }
  }, [dispatch, fetchingPersonOrgMapping]);

  useEffect(() => {
    if (isSuccess(fetchingTokenList) && isSuccess(allColumnsList.loading)) {
      const filteredTokens = filterTokens
        ? allEmailTokenList.filter(filterTokens)
        : allEmailTokenList!;
      const filteredColumns = filterColumnAccessors
        ? allColumnsList.data.filter(filterColumnAccessors)
        : allColumnsList.data;

      const completion = createCompletion(filteredTokens, filteredColumns, []);

      defaultVariables && completion.push(DEFAULT_VARIABLE);
      setAutoCompleteItems(completion);

      const viewPlugins = [
        createHighlightViewPlugin(filteredColumns, filteredTokens),
      ];
      defaultVariables &&
        viewPlugins.push(createHighlightDefaultVariableViewPlugin());
      setViewPlugins(viewPlugins);

      reinitCodemirror.current = true;
      setTimeout(() => {
        reinitCodemirror.current = false;
      });
    }
  }, [
    allEmailTokenList,
    fetchingTokenList,
    allColumnsList,
    defaultVariables,
    filterTokens,
    filterColumnAccessors,
  ]);

  const extendedExtensions = useMemo(() => {
    if (isDisabled) {
      return extensions
        ? [...extensions, EditorView.editable.of(false)]
        : [EditorView.editable.of(false)];
    } else {
      const oneLineOnlyExtension = EditorState.transactionFilter.of((tr) =>
        tr.newDoc.lines > 1 ? [] : tr
      );

      return type === CODEMIRROR_SIZE.STRICT_SINGLE_LINE
        ? [...extensions, oneLineOnlyExtension]
        : extensions;
    }
  }, [extensions, isDisabled, type]);

  return (
    <Box
      className={
        type === CODEMIRROR_SIZE.EDITOR
          ? "codemirror-editor"
          : "codemirror-input"
      }
      width="100%"
      position="relative"
    >
      <Codemirror
        value={value}
        onChange={onChange}
        reinitialize={reinitCodemirror.current || reinitialize}
        theme={"light"}
        lang="jinja2"
        className={findClassname(type)}
        style={
          isInvalid
            ? {
                ...codemirrorStateStyles[type],
                ...errorStateStyle,
              }
            : codemirrorStateStyles[type]
        }
        autoCompleteOptions={autoCompleteOptions}
        viewPlugins={viewPlugins}
        extensions={extendedExtensions}
        {...props}
      />
      {additionalContent}
    </Box>
  );
}
