import {
  Button,
  ButtonProps,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackProps,
  Switch,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";
import IButton, { BUTTON } from "../../../../components/IButton";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import {
  AddRemoveUrlParam,
  TemplateUrlParameterList,
  TemplateUrlParametersConfig,
  ErrorUrlParams,
  EmailUrlParamChange,
} from "../../../../common/types/template";
import RemoveRowCloseButton from "../../../../components/RemoveRowCloseButton";
import {
  TRACKING_UTM_PARAMETERS,
  UTM_PARAM_LABEL_VALUE_PAIR,
} from "../../../../common/constants/common";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { FaPlus } from "react-icons/fa";
import { FiLink2 } from "react-icons/fi";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { useEffect, useState } from "react";
import {
  TEMPLATE_URL_PARAMETER_TYPE,
  URL_PARAMS_ON_CHANGE_OPTION,
} from "../../../../common/constants/template";
import { DL_ACTION } from "../../../../common/constants/dynamicList";
import { cloneDeep, isNumber } from "lodash";
import {
  isTokenUrlParameterType,
  isUtmUrlParameterKey,
  validateTemplateUrlParams,
} from "../../../../common/helper/templateHelper";
import { TokensDropdown } from "../../../../components/dynamic-list/CommonDropdowns";
import { useChakraColors } from "../../../../common/hooks/commonHooks";
import SelectInputTypeDropdown from "../../../../components/SelectInputTypeDropdown";
import { toast } from "react-toastify";

const TEMPLATE_URL_PARAMS_PRECEDENCE_LIST = [
  {
    label: "Always use these parameters",
    value: true,
  },
  {
    label: "Only add missing parameters",
    value: false,
  },
];

const PARAM_VALUE_TYPE_OPTIONS = [
  {
    label: "Text",
    value: [TEMPLATE_URL_PARAMETER_TYPE.TEXT],
  },
  {
    label: "Token",
    value: [
      TEMPLATE_URL_PARAMETER_TYPE.TOKEN,
      TEMPLATE_URL_PARAMETER_TYPE.COLUMN,
    ],
  },
];

function UrlParameterRow({
  paramKey,
  paramValue,
  paramType,
  onChangeRow,
  onRemoveRow,
  isDisabledOption,
  enableTokensInValue = true,
  isReadOnly,
  error,
}: {
  paramKey: string | TRACKING_UTM_PARAMETERS;
  paramValue: string;
  paramType: TEMPLATE_URL_PARAMETER_TYPE;
  onChangeRow: (
    filter: URL_PARAMS_ON_CHANGE_OPTION,
    arg: string,
    type?: TEMPLATE_URL_PARAMETER_TYPE
  ) => void;
  onRemoveRow: VoidFunction;
  isDisabledOption?: (val: string) => boolean;
  enableTokensInValue?: boolean;
  isReadOnly?: boolean;
  error?: { key: string; value?: string };
}) {
  const isUtmParam = isUtmUrlParameterKey(paramKey);
  const { blue500, red500 } = useChakraColors();

  function onChangeKey(val: string) {
    onChangeRow(URL_PARAMS_ON_CHANGE_OPTION.KEY, val);
  }

  function onChangeValue(val: string) {
    onChangeRow(URL_PARAMS_ON_CHANGE_OPTION.VALUE, val);
  }

  function onChangeValueWithType(
    val: string,
    type: TEMPLATE_URL_PARAMETER_TYPE
  ) {
    onChangeRow(URL_PARAMS_ON_CHANGE_OPTION.VALUE, val, type);
  }

  function onChangeType(val: TEMPLATE_URL_PARAMETER_TYPE) {
    onChangeRow(URL_PARAMS_ON_CHANGE_OPTION.TYPE, "", val);
  }

  const isParamToken = isTokenUrlParameterType(paramType);
  const isParamValueInvalid = !!error?.value;
  const isParamKeyInvalid = !!error?.key;

  return (
    <HStack w="100%" spacing={1} alignItems="flex-start">
      <VStack alignItems="flex-start" w="40%" spacing={0}>
        <Text fontSize="12px" color="text.50">
          Parameter key
        </Text>
        {isUtmParam ? (
          <DropdownWithSearch
            options={UTM_PARAM_LABEL_VALUE_PAIR}
            value={UTM_PARAM_LABEL_VALUE_PAIR.find(
              ({ value }) => value === paramKey
            )}
            onChange={(option) => option && onChangeKey(option.value)}
            containerStyle={{ width: "100%" }}
            controlStyle={{
              marginTop: 1,
              height: "33px",
            }}
            isOptionDisabled={(option) =>
              isDisabledOption?.(option.value) ?? false
            }
            isSearchable
            isDisabled={isReadOnly}
            isInvalid={isParamKeyInvalid}
          />
        ) : (
          <InputFieldWithError
            value={paramKey}
            onChange={(e) => onChangeKey(e.target.value.trim())}
            paddingY="0"
            isDisabled={isReadOnly}
            isInvalid={isParamKeyInvalid}
            errorMsg={error?.key}
            height={"34px"}
            formErrorMessageProps={{ mt: 1, mx: 1 }}
          />
        )}
      </VStack>
      <VStack alignItems="flex-start" w="80%" spacing={0}>
        <Text fontSize="12px" color="text.50">
          Parameter value
        </Text>
        <InputGroup
          bg="white"
          border="1px solid"
          borderColor={isParamValueInvalid ? "red.500" : "gray.200"}
          boxShadow={isParamValueInvalid ? `0 0 0 1px ${red500}` : undefined}
          borderRadius="5px"
          _focusWithin={{
            border: "1px solid",
            boxShadow: `0 0 0 1px ${blue500}`,
            borderColor: "blue.500",
          }}
          maxW="100%"
          overflow="hidden"
        >
          {enableTokensInValue && (
            <InputLeftAddon
              background="white"
              border="none"
              borderRightRadius="none"
              borderLeftRadius="5px"
              p={2}
            >
              <SelectInputTypeDropdown
                options={PARAM_VALUE_TYPE_OPTIONS}
                value={paramType}
                onChange={(val) =>
                  onChangeType(val[0] as TEMPLATE_URL_PARAMETER_TYPE)
                }
                isDisabled={isReadOnly}
              />
            </InputLeftAddon>
          )}

          <Input
            value={paramValue}
            onChange={(e) => onChangeValue(e.target.value)}
            type="text"
            border="none"
            fontSize={14}
            focusBorderColor="transparent"
            px={1}
            hidden={isParamToken}
            isDisabled={isReadOnly}
          />

          <InputRightAddon
            background="white"
            border="none"
            borderLeftRadius="none"
            borderRightRadius="5px"
            p={0}
            width={isParamToken ? "100%" : undefined}
          >
            {enableTokensInValue && isParamToken && (
              <TokensDropdown
                token={paramValue}
                onTokenChange={({ name }, { isColumnAccessor }) => {
                  const type = isColumnAccessor
                    ? TEMPLATE_URL_PARAMETER_TYPE.COLUMN
                    : TEMPLATE_URL_PARAMETER_TYPE.TOKEN;
                  onChangeValueWithType(name, type);
                }}
                findToken={({ name }) => name === paramValue.trim()}
                isDisabled={isReadOnly}
              />
            )}
          </InputRightAddon>
        </InputGroup>
        {isParamValueInvalid && (
          <Text fontSize={12} color="red.500" pt={1} px={1}>
            {error.value}
          </Text>
        )}
      </VStack>
      <HStack alignItems="center">
        <RemoveRowCloseButton
          onClick={onRemoveRow}
          mt={5}
          hidden={isReadOnly}
        />
      </HStack>
    </HStack>
  );
}

function UrlParameterDropdown({
  onAddUrlParam,
  isInvalid,
  isDisabledUtmParam,
  ...props
}: {
  onAddUrlParam: (v: string) => void;
  isInvalid?: boolean;
  isDisabledUtmParam?: (val: string) => boolean;
} & ButtonProps) {
  return (
    <Menu closeOnBlur closeOnSelect>
      <MenuButton
        as={Button}
        variant="link"
        color={isInvalid ? "red.500" : "brand.blue"}
        leftIcon={<FaPlus fontSize="12px" />}
        {...props}
        my={1}
      >
        Add parameters
      </MenuButton>
      <MenuList fontSize="14px" fontWeight="normal">
        {UTM_PARAM_LABEL_VALUE_PAIR.map((utmParam) => {
          return (
            <MenuItem
              key={utmParam.value}
              py={2}
              onClick={() => onAddUrlParam(utmParam.value)}
              isDisabled={isDisabledUtmParam?.(utmParam.value)}
            >
              {utmParam.label}
            </MenuItem>
          );
        })}

        <MenuItem _hover={{ bg: "transparent" }}>
          <IButton
            onClick={() => onAddUrlParam("")}
            variant="link"
            color="brand.blue"
            children="Add custom parameter"
            leftIcon={<FaPlus fontSize="12px" />}
            py={1}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
export function ReadOnlyUrlParameters({
  urlParameterList,
  ...props
}: {
  urlParameterList: TemplateUrlParameterList;
} & StackProps) {
  return (
    <Stack flexDirection="row" flexWrap="wrap" spacing={0} {...props}>
      {urlParameterList.map(({ key, value }, index) => {
        let paramKey = key;

        if (isUtmUrlParameterKey(paramKey)) {
          paramKey =
            UTM_PARAM_LABEL_VALUE_PAIR.find(({ value }) => value === key)
              ?.label ?? key;
        }

        return (
          <VStack alignItems="flex-start" key={index} pr={6} pt={1} spacing={1}>
            <Text variant="normal" fontSize="12px">
              {paramKey}
            </Text>
            <Text variant="subtile" fontSize="14px">
              {value}
            </Text>
          </VStack>
        );
      })}
    </Stack>
  );
}

export function UrlParameterRows({
  isUrlParamEnabled,
  urlParameterList,
  onChangeUrlParamList,
  addOrRemoveUrlParam,
  enableTokensInValue,
  isReadOnly,
  errors,
  ...props
}: {
  isUrlParamEnabled: boolean;
  urlParameterList: TemplateUrlParameterList;
  onChangeUrlParamList: (prop: EmailUrlParamChange) => void;
  addOrRemoveUrlParam: (val: AddRemoveUrlParam) => void;
  enableTokensInValue?: boolean;
  isReadOnly?: boolean;
  errors?: ErrorUrlParams;
} & StackProps) {
  function isDisabledUtmParam(val: string) {
    return !!urlParameterList.find(({ key }) => key === val);
  }

  return (
    <VStack alignItems="flex-start" w="100%" my={2} {...props}>
      {isUrlParamEnabled &&
        urlParameterList.map(({ key, value, type }, index) => (
          <UrlParameterRow
            key={index}
            paramKey={key}
            paramValue={value}
            paramType={type}
            onChangeRow={(filter, arg, type) =>
              onChangeUrlParamList({ filter, arg, index, type })
            }
            onRemoveRow={() =>
              addOrRemoveUrlParam({
                action: DL_ACTION.REMOVE,
                index,
              })
            }
            isDisabledOption={isDisabledUtmParam}
            enableTokensInValue={enableTokensInValue}
            isReadOnly={isReadOnly}
            error={errors?.[index]}
          />
        ))}

      <UrlParameterDropdown
        isDisabledUtmParam={isDisabledUtmParam}
        onAddUrlParam={(param) =>
          addOrRemoveUrlParam({
            action: DL_ACTION.ADD,
            paramKey: param,
          })
        }
        isDisabled={!isUrlParamEnabled}
        hidden={isReadOnly}
      />
    </VStack>
  );
}

export function UrlParameterPrecedenceSelection({
  shouldOverrideExistingParams,
  isUrlParamEnabled,
  onOverrideChange,
  isReadOnly,
  headerProps,
  ...props
}: {
  shouldOverrideExistingParams: boolean;
  isUrlParamEnabled: boolean;
  onOverrideChange: (val: boolean) => void;
  isReadOnly?: boolean;
  headerProps?: TextProps;
} & StackProps) {
  const preference = TEMPLATE_URL_PARAMS_PRECEDENCE_LIST.find(
    ({ value }) => value === shouldOverrideExistingParams
  );
  return (
    <VStack
      alignItems={"flex-start"}
      w="100%"
      spacing={1}
      {...props}
      pointerEvents={isUrlParamEnabled ? "unset" : "none"}
      opacity={isUrlParamEnabled ? 1 : 0.5}
    >
      <Text py={2} {...headerProps}>
        URL parameter precedence
      </Text>
      <Text color={"text.50"} fontSize="12px">
        Parameter preference
      </Text>
      {isReadOnly ? (
        <Text fontSize="14px">{preference?.label}</Text>
      ) : (
        <DropdownWithSearch
          options={TEMPLATE_URL_PARAMS_PRECEDENCE_LIST}
          value={preference}
          onChange={(option) => option && onOverrideChange(option.value)}
          containerStyle={{ width: "100%" }}
          controlStyle={{ marginTop: 0 }}
        />
      )}
    </VStack>
  );
}

export function LinkUrlParametersButton(props: ButtonProps) {
  return (
    <IButton
      variant={BUTTON.SECONDARY}
      leftIcon={<FiLink2 fontSize="14px" />}
      children={"Link parameters"}
      {...props}
    />
  );
}

export function TemplateUrlParametersDrawer({
  isOpen,
  onClose,
  urlParameterDetails,
  onSaveUrlParameter,
  saveCancelButton = false,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  urlParameterDetails: TemplateUrlParametersConfig;
  onSaveUrlParameter: (data: TemplateUrlParametersConfig) => void;
  saveCancelButton?: boolean;
}) {
  const [isUrlParamEnabled, setIsUrlParamEnabled] = useState(
    urlParameterDetails.url_params_enabled
  );
  const [isOverrideParams, setIsOverrideParams] = useState(
    urlParameterDetails.override_existing_url_params
  );
  const [urlParamList, setUrlParamList] = useState(
    urlParameterDetails.url_params_list
  );
  const [errors, setErrors] = useState<ErrorUrlParams>({});

  useEffect(() => {
    isOpen && setIsUrlParamEnabled(urlParameterDetails.url_params_enabled);
  }, [urlParameterDetails.url_params_enabled, isOpen]);

  useEffect(() => {
    isOpen &&
      setIsOverrideParams(urlParameterDetails.override_existing_url_params);
  }, [urlParameterDetails.override_existing_url_params, isOpen]);

  useEffect(() => {
    isOpen && setUrlParamList(urlParameterDetails.url_params_list);
  }, [urlParameterDetails.url_params_list, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setErrors({});
    }
  }, [isOpen]);

  function addOrRemoveUrlParam({
    action,
    index,
    paramKey,
  }: {
    action: DL_ACTION;
    index?: number;
    paramKey?: string;
  }) {
    setUrlParamList((prev) => {
      const clone = cloneDeep(prev);

      if (action === DL_ACTION.REMOVE && isNumber(index) && index > -1) {
        clone.splice(index, 1);
      }

      if (action === DL_ACTION.ADD) {
        return [
          ...clone,
          {
            key: paramKey ?? "",
            value: "",
            type: TEMPLATE_URL_PARAMETER_TYPE.TEXT,
          },
        ];
      }

      return clone;
    });

    setErrors({});
  }

  function onChangeUrlParamList({
    filter,
    arg,
    index,
    type,
  }: EmailUrlParamChange) {
    setUrlParamList((prev) => {
      const clone = cloneDeep(prev);
      switch (filter) {
        case URL_PARAMS_ON_CHANGE_OPTION.KEY:
          clone[index] = {
            ...clone[index],
            key: arg,
          };
          break;
        case URL_PARAMS_ON_CHANGE_OPTION.VALUE:
          clone[index] = {
            ...clone[index],
            value: arg,
          };
          if (type) clone[index] = { ...clone[index], type };
          break;
        case URL_PARAMS_ON_CHANGE_OPTION.TYPE:
          clone[index] = {
            ...clone[index],
            type: type as TEMPLATE_URL_PARAMETER_TYPE,
            value: "",
          };
          break;
      }

      return clone;
    });
    setErrors({});
  }

  function validateUrlParams(): boolean {
    const { isValid, errors: validatedErrors } = validateTemplateUrlParams(
      isUrlParamEnabled,
      urlParamList
    );
    setErrors(validatedErrors);
    return isValid;
  }

  function onSave() {
    if (isUrlParamEnabled) {
      if (validateUrlParams()) {
        onSaveUrlParameter({
          url_params_enabled: isUrlParamEnabled,
          url_params_list: urlParamList,
          override_existing_url_params: isOverrideParams,
        });
        onClose();
      } else {
        toast.error("Invalid url parameters");
      }
    } else {
      onSaveUrlParameter({
        url_params_enabled: isUrlParamEnabled,
        url_params_list: [],
        override_existing_url_params: false,
      });
      onClose();
    }
  }

  return (
    <MemoizedCommonDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Link parameters"
      placement="right"
      size="lg"
      drawerOverlayProps={{ zIndex: 1400 }}
    >
      <VStack alignItems="flex-start">
        <Text variant="normal">
          Links in this email will contain URL parameters for tracking sources
          in tools like Google Analytics.
        </Text>
        <HStack justifyContent="space-between" w="100%">
          <Text variant="sub-heading">URL parameters</Text>
          <Switch
            isChecked={isUrlParamEnabled}
            size="sm"
            colorScheme="green"
            onChange={(e) => setIsUrlParamEnabled(e.target.checked)}
          />
        </HStack>
        <UrlParameterRows
          isUrlParamEnabled={isUrlParamEnabled}
          urlParameterList={urlParamList}
          onChangeUrlParamList={onChangeUrlParamList}
          addOrRemoveUrlParam={addOrRemoveUrlParam}
          errors={errors}
        />
        <UrlParameterPrecedenceSelection
          isUrlParamEnabled={isUrlParamEnabled}
          shouldOverrideExistingParams={isOverrideParams}
          onOverrideChange={setIsOverrideParams}
        />

        <HStack justifyContent="flex-end" py={2} w="100%">
          {saveCancelButton ? (
            <>
              <IButton
                children="cancel"
                variant={BUTTON.SECONDARY}
                onClick={onClose}
              />
              <IButton children="save" onClick={onSave} />
            </>
          ) : (
            <IButton children="Done" onClick={onSave} ml="92%" my={4} />
          )}
        </HStack>
      </VStack>
    </MemoizedCommonDrawer>
  );
}
