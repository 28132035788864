import { HStack, VStack } from "@chakra-ui/react";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import InflectionToSalesforceInclusionList from "../salesforceSyncSettings/components/InflectionToSalesforceInclusionList";
import InflectionToSalesforceRecord from "../salesforceSyncSettings/components/InflectionToSalesforceRecord";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { useState } from "react";
import {
  getContactInclusionList,
  getInflectionOnCreateEventDetails,
  getSyncedObjectSummary,
  updateContactAndLeadInflectionCreateSettings,
  updateInclusionListInContactAndLead,
} from "../../../../../../common/helper/salesforceHelper";
import { isEqual } from "lodash";
import ActionBasedButtons, {
  ACTIONS,
} from "../../../../../../components/ActionBasedButtons";

export default function InflectionToSalesforceSettings({
  connection,
  onUpdateConnection,
  isLoading = false,
  isUpdatingConnection,
}: {
  connection: SalesforceConnectionV2 | null;
  onUpdateConnection: (connection: SalesforceConnectionV2 | null) => void;
  isLoading?: boolean;
  isUpdatingConnection?: boolean;
}) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [contactInclusionList, setContactInclusionList] = useState(
    getContactInclusionList(connection)
  );

  //Only contact can be created and synced as of now, inflection doesn't have leads as an asset
  //later we might sync for account
  const [inflectionContactEvent, setInflectionContactEvent] = useState(
    getInflectionOnCreateEventDetails(
      connection?.syncSettings?.objectSettings ?? null
    )
  );

  function onCancelEdit() {
    setContactInclusionList(getContactInclusionList(connection));
    setInflectionContactEvent(
      getInflectionOnCreateEventDetails(
        connection?.syncSettings?.objectSettings ?? null
      )
    );
    setIsReadOnly(true);
  }

  function onSaveEdit() {
    if (connection) {
      const updatedCreateActions = updateContactAndLeadInflectionCreateSettings(
        connection,
        inflectionContactEvent
      );
      const updatedConnection = updateInclusionListInContactAndLead(
        updatedCreateActions,
        contactInclusionList
      );

      if (!isEqual(connection, updatedConnection)) {
        onUpdateConnection(updatedConnection);
      }

      setIsReadOnly(true);
    }
  }

  return (
    <ContentContainerWithHeader>
      <HStack py={1} justifyContent="flex-end">
        <ActionBasedButtons
          action={isReadOnly ? ACTIONS.VIEW : ACTIONS.EDIT}
          cancelButtonProps={{
            onClick: onCancelEdit,
            isLoading: isUpdatingConnection,
          }}
          editButtonProps={{ onClick: () => setIsReadOnly(false), isLoading }}
          saveButtonProps={{
            onClick: onSaveEdit,
            isLoading: isUpdatingConnection,
          }}
        />
      </HStack>

      <VStack alignItems="flex-start" spacing={6} p="5" bg="white" rounded="md">
        <InflectionToSalesforceInclusionList
          inclusionList={contactInclusionList}
          onChangeList={setContactInclusionList}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
        />

        <InflectionToSalesforceRecord
          inflectionEvents={inflectionContactEvent}
          onChangeEvents={setInflectionContactEvent}
          syncObjectEnabled={getSyncedObjectSummary(connection)}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
        />
      </VStack>
    </ContentContainerWithHeader>
  );
}
