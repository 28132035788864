import {
  FormControl,
  FormLabel,
  Flex,
  HStack,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { placeholder } from "@codemirror/view";
import { useRef } from "react";
import { FaPlus } from "react-icons/fa";
import {
  CODEMIRROR_SIZE,
  KEY_VALUE_OPTION,
} from "../../../../common/constants/common";
import {
  HEADER_ACTIONS,
  CONTENT_TYPE,
} from "../../../../common/constants/webhook";
import CodemirrorInput from "../../../../components/codemirror/CodemirrorInput";
import IButton from "../../../../components/IButton";
import RemoveRowButton from "../../../../components/RemoveRowButton";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";
import { HandleHeaderChangeType } from "../Webhook";
import { WebhookInputConfigurationType } from "./WebhookInputConfiguration";

export function WebhookRequestHeaders({
  inputs,
  handleHeaderChange,
  reinitializeHeader,
  contentType,
  errors,
  inputPaddingX,
  isLoading,
}: WebhookInputConfigurationType & {
  handleHeaderChange: ({
    action,
    value,
    type,
    index,
  }: HandleHeaderChangeType) => void;
  reinitializeHeader: boolean;
}) {
  const reinitHeadersRef = useRef<boolean>(false);

  function removeHeader(index: number) {
    reinitHeadersRef.current = true;
    handleHeaderChange({ action: HEADER_ACTIONS.REMOVE, index });

    setTimeout(() => {
      reinitHeadersRef.current = false;
    }, 500);
  }

  const shouldReinitialize = reinitHeadersRef.current || reinitializeHeader;

  return (
    <>
      <FormControl py="2" px={inputPaddingX} isInvalid={!!errors.headers}>
        <FormLabel color="gray.500" fontSize="xs">
          Headers
        </FormLabel>
        <HStack>
          <Flex width="44%">
            <ISkeleton variant={SKELETON_VARIANT.INPUT} isLoaded={!isLoading}>
              <Input
                isDisabled={true}
                value={CONTENT_TYPE}
                py="2"
                height="40px"
                fontSize="sm"
                backgroundColor="gray.200"
              />
            </ISkeleton>
          </Flex>
          <Flex width="44%" height="40px" className="codemirror-normal-input">
            <ISkeleton variant={SKELETON_VARIANT.INPUT} isLoaded={!isLoading}>
              <CodemirrorInput
                type={CODEMIRROR_SIZE.SINGLE_LINE}
                value={contentType ?? ""}
                onChange={(code) =>
                  handleHeaderChange({
                    action: HEADER_ACTIONS.CONTENT_TYPE_CHANGE,
                    value: code,
                  })
                }
                reinitialize={shouldReinitialize}
                isInvalid={!!errors.headers}
              />
            </ISkeleton>
          </Flex>
        </HStack>
        <FormErrorMessage fontSize="12px">{errors.headers}</FormErrorMessage>
        {inputs.headers.map(([key, value], index) => {
          return (
            <HeaderKeyValuePair
              keyValue={key}
              value={value}
              reinitialize={shouldReinitialize}
              onChange={handleHeaderChange}
              removeHeader={removeHeader}
              index={index}
              isLoading={isLoading}
            />
          );
        })}
      </FormControl>

      <Flex px={3}>
        <IButton
          color="blue.500"
          size="sm"
          variant="link"
          leftIcon={<FaPlus size="10px" />}
          onClick={() => handleHeaderChange({ action: HEADER_ACTIONS.ADD })}
          hidden={isLoading}
        >
          Add Another
        </IButton>
      </Flex>
    </>
  );
}

export function HeaderKeyValuePair({
  keyValue,
  value,
  index,
  reinitialize,
  onChange,
  removeHeader,
  isLoading,
}: {
  keyValue: string;
  value: string;
  index: number;
  reinitialize: boolean;
  onChange: ({ action, index, value, type }: HandleHeaderChangeType) => void;
  removeHeader: (index: number) => void;
  isLoading?: boolean;
}) {
  return (
    <HStack marginTop={1} className="codemirror-normal-input">
      <Flex width="44%">
        <ISkeleton variant={SKELETON_VARIANT.INPUT} isLoaded={!isLoading}>
          <CodemirrorInput
            type={CODEMIRROR_SIZE.SINGLE_LINE}
            reinitialize={reinitialize}
            value={keyValue}
            onChange={(code: string) =>
              onChange({
                action: HEADER_ACTIONS.UPDATE,
                index,
                value: code,
                type: KEY_VALUE_OPTION.KEY,
              })
            }
            extensions={[placeholder("key")]}
          />
        </ISkeleton>
      </Flex>
      <Flex marginLeft={2} marginRight={1} width="44%">
        <ISkeleton variant={SKELETON_VARIANT.INPUT} isLoaded={!isLoading}>
          <CodemirrorInput
            type={CODEMIRROR_SIZE.SINGLE_LINE}
            reinitialize={reinitialize}
            value={value}
            onChange={(code: string) =>
              onChange({
                action: HEADER_ACTIONS.UPDATE,
                index,
                value: code,
                type: KEY_VALUE_OPTION.VALUE,
              })
            }
            extensions={[placeholder("value")]}
          />
        </ISkeleton>
      </Flex>
      <RemoveRowButton onClick={() => removeHeader(index)} size="sm" />
    </HStack>
  );
}
