import { Box, Skeleton, Text, VStack } from "@chakra-ui/react";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import {
  getFormattedTenantTimezone,
  isLoading,
} from "../../../common/helper/commonHelper";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { selectAccount } from "../../account/accountSlice";
import { selectSettings } from "./settingsSlice";

function WorkSpaceItem({
  title,
  subText,
}: {
  title: string;
  subText: string | null;
}) {
  const {
    tenantDetails: { loading: tenantDetailsLoading },
  } = useSelector(selectSettings);
  return (
    <Skeleton isLoaded={!isLoading(tenantDetailsLoading)}>
      <Box maxW="100%">
        <Text variant="mainText" my={2}>
          {title}
        </Text>
        <Text variant="menuText">{subText}</Text>
      </Box>
    </Skeleton>
  );
}
export default function WorkSpaceSettings() {
  const {
    tenantDetails: { data: tenantDetails },
  } = useSelector(selectSettings);
  const {
    userOrg: { name: workspaceName },
  } = useSelector(selectAccount);

  return (
    <ContentContainerWithHeader mr="20px">
      <ContentContainer flexDir="column" h="90vh" mt={10}>
        <Box w="100%" h="100%">
          <Text variant="title" mb={3}>
            Workspace Details
          </Text>
          <VStack alignItems="flex-start" gridGap={2} spacing={3}>
            <WorkSpaceItem
              title="Workspace name"
              subText={capitalize(workspaceName)}
            />
            <WorkSpaceItem
              title="Workspace timezone"
              subText={getFormattedTenantTimezone(tenantDetails)}
            />
          </VStack>
        </Box>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
