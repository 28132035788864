import {
  Box,
  DrawerFooter,
  Flex,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { FaGlobe, FaPencilAlt } from "react-icons/fa";
import { TbWebhook, TbWebhookOff } from "react-icons/tb";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { isLoading } from "../../../../../../common/helper/commonHelper";
import {
  CanvasDateFilter,
  FlowWebhookActionOptions,
  SummaryStatsWebhook,
} from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import { WebhookSummary } from "../../../../../../common/types/webhook";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import { useAppDispatch } from "../../../../../../store";

import {
  listAllWebhooks,
  selectWebhook,
} from "../../../../webhook/webhookSlice";
import CommonDrawer from "../../../components/CommonDrawer";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import WebhookFlowStepReport from "../canvas-reports/WebhookFlowStepReport";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import StatsWithIconAndCount from "../StatsWithIconAndCount";
import WidgetContainer from "../WidgetContainer";
import WidgetIconButton from "../WidgetIconButton";
import WidgetSummaryReport from "../WidgetSummaryReport";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";
import { WebhooksDropdown } from "../../../../../../components/dynamic-list/CommonDropdowns";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.WEBHOOK];

function SummaryReportWidget({
  actionId,
  campaignId,
  title,
  dateFilter,
  summary,
  loading,
}: {
  title: string;
  actionId: string;
  campaignId: string;
  dateFilter: CanvasDateFilter;
  summary?: SummaryStatsWebhook;
  loading?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <WidgetSummaryReport loading={loading} onClick={onOpen}>
        <StatsWithIconAndCount
          icon={TbWebhook}
          text={`${summary?.success ?? 0} Successful`}
        />
        <StatsWithIconAndCount
          icon={TbWebhookOff}
          text={`${summary?.failure ?? 0} Failed`}
        />
      </WidgetSummaryReport>
      {isOpen && (
        <WebhookFlowStepReport
          actionId={actionId}
          campaignId={campaignId}
          title={title}
          data={summary}
          isOpen={isOpen}
          onClose={onClose}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}

function WebhookWidgetBeforeWebhookSelection({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onClick}
      emptyStateDetails={{
        icon: FaGlobe,
        header: "Select a webhook",
        subHeader: "Click here to set up the webhook",
      }}
    />
  );
}

function WebhookAfterWebhookSelection({
  webhookListOptions,
  value,
  onEdit,
  readonly,

  actionId,
  campaignId,
  dateFilter,
  summary,
  loadingSummary,
}: {
  webhookListOptions: WebhookSummary[];
  value: string;
  onEdit: () => void;
  readonly?: boolean;

  actionId: string;
  campaignId: string;
  dateFilter: CanvasDateFilter;
  summary?: SummaryStatsWebhook;
  loadingSummary?: boolean;
}) {
  const name =
    webhookListOptions.find((option) => option.webhook_id === value)?.name ??
    "";
  return (
    <VStack>
      <Flex justifyContent={"space-between"} w="100%" minH="70px">
        <Text noOfLines={5}>{name}</Text>

        <WidgetIconButton
          icon={FaPencilAlt}
          onClick={onEdit}
          hidden={readonly}
        />
      </Flex>

      <SummaryReportWidget
        actionId={actionId}
        campaignId={campaignId}
        title={name}
        dateFilter={dateFilter}
        summary={summary}
        loading={loadingSummary}
      />
    </VStack>
  );
}

function WebhookWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const dispatch = useAppDispatch();
  const { allWebhooksList } = useSelector(selectWebhook);
  const {
    flowValidity,
    reports: {
      filter: dateFilter,
      data: {
        flowStepsSummaryReport: {
          data: flowStepsSummaryReport,
          loading: loadingFlowStepsSummaryReport,
        },
      },
    },
  } = useSelector(selectFlow);

  const {
    isOpen: isOpenEditDrawer,
    onOpen: onOpenEditDrawer,
    onClose: onCloseEditDrawer,
  } = useDisclosure();

  const { activeErrorCheck, campaignId } = useContext(CampaignBuilderContext);
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowWebhookActionOptions,
    [action]
  );

  useEffect(() => {
    dispatch(listAllWebhooks());
  }, [dispatch]);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: FlowWebhookActionOptions) {
    setActions(options, identities.actionId, groupId);
    saveDraft(options, { actionId: identities.actionId, groupId });
  }

  useEffect(() => {
    setValidityCallback(!!actionOptions.webhook_id);
  }, [actionOptions.webhook_id, setValidityCallback]);

  function handleOptionChange(value: string | null) {
    setOptions({
      webhook_id: value,
    });
    setValidityCallback(!!value);
  }

  function editWebhookWidgetDrawer() {
    return (
      <CommonDrawer
        isOpen={isOpenEditDrawer}
        onClose={onCloseEditDrawer}
        size="md"
        placement="right"
        title={optionDetails.label}
        footer={
          <DrawerFooter p={0}>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={onCloseEditDrawer}
              children="Close"
            />
          </DrawerFooter>
        }
      >
        <WebhooksDropdown
          webhookId={actionOptions.webhook_id}
          onSelect={handleOptionChange}
          isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
          isReadOnly={readonly}
        />
      </CommonDrawer>
    );
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Webhook is required"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Box w="100%" p="5">
        {actionOptions.webhook_id ? (
          <WebhookAfterWebhookSelection
            actionId={identities.actionId}
            campaignId={campaignId}
            dateFilter={dateFilter}
            summary={
              flowStepsSummaryReport[action.action_id]
                ?.summary_stats as SummaryStatsWebhook
            }
            loadingSummary={isLoading(loadingFlowStepsSummaryReport)}
            webhookListOptions={allWebhooksList.data}
            onEdit={onOpenEditDrawer}
            value={actionOptions.webhook_id}
            readonly={readonly}
          />
        ) : (
          <WebhookWidgetBeforeWebhookSelection onClick={onOpenEditDrawer} />
        )}
      </Box>

      {editWebhookWidgetDrawer()}
    </WidgetContainer>
  );
}

export default memo(WebhookWidget, isActionDataSame);
