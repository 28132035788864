import { defineStyleConfig, ComponentStyleConfig } from "@chakra-ui/react";

const textStyle: ComponentStyleConfig = defineStyleConfig({
  variants: {
    tags: {
      fontsize: "10px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "text.50",
    },
    normal: {
      fontsize: "14px",
      color: "text.50",
    },
    title: {
      color: "text.100",
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: "12px",
      textTransform: "capitalize",
      color: "text.50",
    },
    menuText: {
      fontSize: "14px",
      color: "text.200",
    },
    mainText: {
      fontSize: "14px",
      color: "text.100",
      fontWeight: "600",
    },
  },
});

export default textStyle;
