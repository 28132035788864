import {
  Badge,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  getSalesforceConnectionApi,
  remapPersonFromSalesforceConnectionApi,
} from "../../../../common/api/salesforceIntegrations/salesforceConnection";
import {
  isFulfilled,
  isInit,
  isLoading,
  openSalesforceInstance,
} from "../../../../common/helper/commonHelper";
import {
  SalesforcePersonLinkedRecordDetails,
  SalesforcePersonSyncDetails,
  SF_PERSON_SYNC_STATUS,
} from "../../../../common/types/salesforce";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import IButton, { BUTTON } from "../../../../components/IButton";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../store";
import { compact, concat, startCase } from "lodash";
import {
  getSalesforceConnectionDetails,
  getPersonSalesforceSyncStatus,
  resyncPersonFromSalesforceConnection,
  remapPersonFromSalesforceConnection,
  selectSalesforce,
} from "../../connection/salesforce/salesforceSlice";
import { getAssociatedSalesforceObjCount } from "../../../../common/helper/salesforceHelper";
import { EMPTY_CONTEXT } from "../../../../common/constants/common";
import { SALESFORCE_OBJ_TO_CRM_OBJECT } from "../../../../common/constants/salesforce";

enum BUTTON_ACTION {
  RESYNC = "resync",
  EDIT = "edit",
  CANCEL = "cancel",
  SAVE = "save",
}

function EditPersonMapping({
  email,
  count,
  linkedRecord,
  radioOnchangeHandler,
  allAssociatedRecords,
  instanceUrl,
}: {
  email: string;
  count: number;
  linkedRecord: SalesforcePersonLinkedRecordDetails | null;
  radioOnchangeHandler: (val: string) => void;
  allAssociatedRecords: SalesforcePersonLinkedRecordDetails[];
  instanceUrl: string;
}) {
  return (
    <VStack alignItems="flex-start" spacing="3" fontSize="sm">
      <Text>{`${email} has ${count} associated record(s) in Salesforce`}</Text>

      <RadioGroup
        value={linkedRecord?.salesforceId}
        onChange={radioOnchangeHandler}
      >
        <VStack alignItems="flex-start" py="2">
          {allAssociatedRecords.map((x) => (
            <Radio value={x.salesforceId} key={x.salesforceId}>
              <LinkedRecordItem data={x} instanceUrl={instanceUrl} />
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
    </VStack>
  );
}

function ReadonlyPersonMapping({
  email,
  linkedRecord,
  instanceUrl,
  count,
  allAssociatedRecords,
}: {
  email: string;
  linkedRecord: SalesforcePersonLinkedRecordDetails | null;
  instanceUrl: string;
  count: number;
  allAssociatedRecords: SalesforcePersonLinkedRecordDetails[];
}) {
  return (
    <VStack alignItems="flex-start" spacing="3" fontSize="sm">
      <HStack spacing="1">
        <HStack spacing="1">
          <Text color="gray.800">{email}</Text>
        </HStack>
        {linkedRecord ? (
          <HStack spacing="1">
            <Text>is currently linked to</Text>
            <LinkedRecordItem data={linkedRecord} instanceUrl={instanceUrl} />
          </HStack>
        ) : (
          <Text>has 0 associated record(s) in Salesforce</Text>
        )}
      </HStack>

      {count > 1 && (
        <VStack alignItems="flex-start" bg="gray.100" p="2" px="4">
          <Text>{`Note: There ${count - 1 > 1 ? `are ` : `is `} ${
            count - 1
          } other record(s) associated with this email`}</Text>
          {allAssociatedRecords.map((x) => (
            <LinkedRecordItem data={x} instanceUrl={instanceUrl} />
          ))}
        </VStack>
      )}
    </VStack>
  );
}

function ButtonBar({
  isReadMode,
  onClick,
}: {
  isReadMode: boolean;
  onClick: (action: BUTTON_ACTION) => void;
}) {
  return (
    <Flex
      w="100%"
      justifyContent="flex-end"
      position="absolute"
      top="-58px"
      right="-20px"
    >
      {isReadMode ? (
        <ButtonGroup>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={() => onClick(BUTTON_ACTION.RESYNC)}
          >
            Resync
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={() => onClick(BUTTON_ACTION.EDIT)}
          >
            Edit
          </IButton>
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={() => onClick(BUTTON_ACTION.CANCEL)}
          >
            Cancel
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={() => onClick(BUTTON_ACTION.SAVE)}
          >
            Save
          </IButton>
        </ButtonGroup>
      )}
    </Flex>
  );
}

function SalesforceSync({
  salesforcePersonDetails,
  loading,
}: {
  salesforcePersonDetails: SalesforcePersonSyncDetails | null;
  loading: boolean;
}) {
  function SalesforcePersonSyncStatus() {
    return salesforcePersonDetails?.syncStatusMeta?.status ===
      SF_PERSON_SYNC_STATUS.SYNCED ? (
      <Text fontSize="sm"> Up-to-date </Text>
    ) : (
      <HStack>
        <Text color="red.300" fontSize="16px">
          Sync error
        </Text>
        <Tooltip label={salesforcePersonDetails?.failureReason} fontSize="sm">
          <Center ml="2">
            <Icon color="gray.300" fontSize="sm" as={FaInfoCircle}></Icon>
          </Center>
        </Tooltip>
      </HStack>
    );
  }

  return (
    <VStack alignItems="flex-start">
      <Heading fontSize="md">Salesforce sync</Heading>

      <HStack>
        Sync status:
        <SpinnerContainer loading={loading} height="24px" width="100%">
          {salesforcePersonDetails?.linkedRecord ? (
            <SalesforcePersonSyncStatus />
          ) : (
            <Text fontSize="sm"> No sync errors </Text>
          )}
        </SpinnerContainer>
      </HStack>
    </VStack>
  );
}

function LinkedRecordItem({
  data,
  instanceUrl,
}: {
  data: SalesforcePersonLinkedRecordDetails | null;
  instanceUrl: string;
}) {
  function openSalesforceInNewTab() {
    if (data && data.salesforceObject) {
      openSalesforceInstance({
        url: instanceUrl,
        crmObject: startCase(
          SALESFORCE_OBJ_TO_CRM_OBJECT[data.salesforceObject]
        ),
        salesforceId: data.salesforceId,
      });
    }
  }

  return (
    data && (
      <HStack bg="grayV2.100" px="2">
        <IButton
          color={"blue.600"}
          variant="link"
          rightIcon={<FaExternalLinkAlt />}
          onClick={openSalesforceInNewTab}
        >
          {`${data.firstName + " " + data.lastName}` || EMPTY_CONTEXT}
        </IButton>
        <Badge>SF {data.salesforceObject}</Badge>
        <HStack>
          <Text fontWeight="semibold">ID:</Text>{" "}
          <Text>{data.salesforceId}</Text>
        </HStack>
      </HStack>
    )
  );
}

function SalesforceObjects({
  email,
  linkedRecord,
  onChangeLinkedRecord,
  salesforcePersonDetails,
  isReadMode,
  loading,
}: {
  email: string;
  linkedRecord: SalesforcePersonLinkedRecordDetails | null;
  onChangeLinkedRecord: (val: SalesforcePersonLinkedRecordDetails) => void;
  salesforcePersonDetails: SalesforcePersonSyncDetails | null;
  loading: boolean;
  isReadMode: boolean;
}) {
  const dispatch = useAppDispatch();

  const [count, setCount] = useState(
    getAssociatedSalesforceObjCount(salesforcePersonDetails)
  );
  const [instanceUrl, setInstanceUrl] = useState("");

  const fetchInstanceUrl = useCallback(
    async (connectionId: string) => {
      const rest = await dispatch(getSalesforceConnectionDetails(connectionId));
      if (isFulfilled(rest.meta.requestStatus)) {
        const newSfConnectionDetails = rest.payload as Awaited<
          ReturnType<typeof getSalesforceConnectionApi>
        >;
        setInstanceUrl(newSfConnectionDetails.data?.instanceUrl ?? "");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setCount(getAssociatedSalesforceObjCount(salesforcePersonDetails));
  }, [salesforcePersonDetails]);

  useEffect(() => {
    if (salesforcePersonDetails?.linkedRecord?.connectionId) {
      fetchInstanceUrl(salesforcePersonDetails.linkedRecord.connectionId);
    }
  }, [fetchInstanceUrl, salesforcePersonDetails?.linkedRecord?.connectionId]);

  const salesforceLinkedAndAssociatedRecord = compact(
    concat(
      salesforcePersonDetails?.linkedRecord,
      salesforcePersonDetails?.associatedRecords
    )
  );

  function radioOnchangeHandler(val: string): void {
    const newLinkedRecord = salesforceLinkedAndAssociatedRecord.find(
      (x) => x?.salesforceId === val
    );
    if (newLinkedRecord) {
      onChangeLinkedRecord(newLinkedRecord);
    }
  }

  return (
    <VStack mt="5" alignItems="flex-start" w="100%">
      <Heading fontSize="md">Salesforce sync objects</Heading>
      <SpinnerContainer width="100%" loading={loading}>
        {isReadMode ? (
          <ReadonlyPersonMapping
            email={email}
            linkedRecord={linkedRecord}
            allAssociatedRecords={
              salesforcePersonDetails?.associatedRecords ?? []
            }
            instanceUrl={instanceUrl}
            count={count}
          />
        ) : (
          <EditPersonMapping
            email={email}
            count={count}
            linkedRecord={linkedRecord}
            allAssociatedRecords={salesforceLinkedAndAssociatedRecord}
            radioOnchangeHandler={radioOnchangeHandler}
            instanceUrl={instanceUrl}
          />
        )}
      </SpinnerContainer>
    </VStack>
  );
}

export default function PersonSalesforceFlinkSyncTab({
  internalPersonEmail,
}: {
  internalPersonEmail: string;
}) {
  const [isReadonlyMode, setIsReadonlyMode] = useState(true);
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [linkedSalesforceObject, setLinkedSalesforceObject] =
    useState<SalesforcePersonLinkedRecordDetails | null>(null);

  const {
    personSfSyncDetails: {
      data: personSfDetails,
      loading: sfSyncDetailsLoading,
    },
  } = useSelector(selectSalesforce);

  useEffect(() => {
    if (internalPersonEmail && isInit(sfSyncDetailsLoading)) {
      dispatch(getPersonSalesforceSyncStatus(internalPersonEmail));
    }
  }, [dispatch, internalPersonEmail, sfSyncDetailsLoading]);

  useEffect(() => {
    if (personSfDetails?.linkedRecord) {
      setLinkedSalesforceObject(personSfDetails.linkedRecord);
    }
  }, [personSfDetails.linkedRecord]);

  async function save() {
    if (linkedSalesforceObject) {
      const result = await dispatch(
        remapPersonFromSalesforceConnection({
          connectionId: linkedSalesforceObject.connectionId,
          salesforceId: linkedSalesforceObject.salesforceId,
          email: internalPersonEmail,
          salesforceObject: linkedSalesforceObject.salesforceObject,
        })
      );

      if (isFulfilled(result.meta.requestStatus)) {
        const resultPayload = result.payload as Awaited<
          ReturnType<typeof remapPersonFromSalesforceConnectionApi>
        >;

        if (resultPayload) {
          dispatch(getPersonSalesforceSyncStatus(internalPersonEmail));
          setIsReadonlyMode(true);
          onClose();
        }
      }
    }
  }

  function resync() {
    dispatch(resyncPersonFromSalesforceConnection(internalPersonEmail));
  }

  async function cancel() {
    setLinkedSalesforceObject(personSfDetails?.linkedRecord ?? null);
    setIsReadonlyMode(true);
  }

  function buttonClickHandler(action: BUTTON_ACTION) {
    switch (action) {
      case BUTTON_ACTION.RESYNC:
        resync();
        break;
      case BUTTON_ACTION.EDIT:
        setIsReadonlyMode(false);
        break;
      case BUTTON_ACTION.SAVE:
        onOpen();
        break;
      case BUTTON_ACTION.CANCEL:
        cancel();
        break;
    }
  }

  return (
    <Flex
      flexDir="column"
      w="100%"
      p="2"
      alignItems="flex-start"
      position="relative"
    >
      <ButtonBar isReadMode={isReadonlyMode} onClick={buttonClickHandler} />

      <SalesforceSync
        salesforcePersonDetails={personSfDetails}
        loading={isLoading(sfSyncDetailsLoading)}
      />
      <SalesforceObjects
        linkedRecord={linkedSalesforceObject}
        onChangeLinkedRecord={setLinkedSalesforceObject}
        email={internalPersonEmail}
        isReadMode={isReadonlyMode}
        salesforcePersonDetails={personSfDetails}
        loading={isLoading(sfSyncDetailsLoading)}
      />
      <ConfirmationModal
        isOpen={isOpen}
        title="Change mapping"
        cancelButtonText="Cancel"
        confirmButtonText="Change"
        onCancel={onClose}
        submitHandler={save}
      >
        <Text color="gray.600">
          Are you sure you want to change Inflection person mapping?
        </Text>
      </ConfirmationModal>
    </Flex>
  );
}
