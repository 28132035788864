import { Flex } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import SideBar from "../../../components/SideBar";
import SecondarySidebar from "../../../components/v2/SecondarySidebar";
import UserList from "../users/UserList";
import EmailSettings from "./EmailSettings";
import SsoConfiguration from "./SsoConfiguration";
import TimeZoneSettings from "./TimeZoneSettings";
import WebsiteTrackingSettings from "./WebsiteTrackingSettings";
import WorkSpaceSettings from "./WorkSpaceSettings";
import GlobalCommunicationLimitSettings from "./GlobalCommunicationLimitSettings";

const SIDEBAR_ITEMS = [
  "Workspace Details",
  "Email Settings",
  "Communication Limits",
  "Timezone Settings",
  "Single Sign-on",
  "Website Tracking",
  "Users",
];

const settingTabs: Record<(typeof SIDEBAR_ITEMS)[number], ReactNode> = {
  [SIDEBAR_ITEMS[0]]: <WorkSpaceSettings />,
  [SIDEBAR_ITEMS[1]]: <EmailSettings />,
  [SIDEBAR_ITEMS[2]]: <GlobalCommunicationLimitSettings />,
  [SIDEBAR_ITEMS[3]]: <TimeZoneSettings />,
  [SIDEBAR_ITEMS[4]]: <SsoConfiguration />,
  [SIDEBAR_ITEMS[5]]: <WebsiteTrackingSettings />,
  [SIDEBAR_ITEMS[6]]: <UserList />,
};

export default function Settings() {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <Flex>
      <SecondarySidebar heading="Settings">
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />
      </SecondarySidebar>
      {settingTabs[SIDEBAR_ITEMS[selectedOption]]}
    </Flex>
  );
}
