export const APPLICATION_JSON: Readonly<"Application/json"> =
  "Application/json";
export const CONTENT_TYPE: Readonly<"Content-Type"> = "Content-Type";

export enum WEBHOOK_REQUEST_METHODS {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
  HEAD = "HEAD",
  PATCH = "PATCH",
}

export enum HEADER_ACTIONS {
  ADD = "add",
  REMOVE = "remove",
  UPDATE = "update",
  CONTENT_TYPE_CHANGE = "content-type-Change",
}
