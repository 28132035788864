import {
  StackProps,
  VStack,
  Text,
  HStack,
  Icon,
  Switch,
  TextProps,
} from "@chakra-ui/react";
import InfoBanner from "./InfoBanner";
import { BiMailSend } from "react-icons/bi";

export default function EmailCommunicationLimitSettings({
  ignoreLimits,
  onLimitChange,
  isReadOnly,
  infoText,
  titleProps,
  ...props
}: {
  ignoreLimits: boolean;
  onLimitChange: (val: boolean) => void;
  isReadOnly?: boolean;
  infoText?: string;
  titleProps?: TextProps;
} & StackProps) {
  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      pt={3}
      {...props}
      pointerEvents={isReadOnly ? "none" : "unset"}
      opacity={isReadOnly ? 0.7 : 1}
    >
      <Text variant="title" {...titleProps}>
        Email Delivery Control
      </Text>
      <InfoBanner bg="brandBlue.100" w="100%" p={2}>
        <Text color="brandBlue.500" fontSize={12}>
          {infoText ??
            `Emails will be sent to users even if communication limits are hit`}
        </Text>
      </InfoBanner>
      <HStack
        py={1}
        color="brandBlue.500"
        w="100%"
        justifyContent="space-between"
      >
        <HStack>
          <Icon as={BiMailSend} fontSize={20} mt={1} />
          <Text variant="normal" fontSize="14px">
            Ignore Send Limits
          </Text>
        </HStack>
        <Switch
          isChecked={ignoreLimits}
          isReadOnly={isReadOnly}
          onChange={(e) => onLimitChange(e.target.checked)}
          colorScheme="green"
          size="sm"
        />
      </HStack>
    </VStack>
  );
}
