import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  FormLabelProps,
  Flex,
  Text,
  HStack,
  Icon,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
} from "@chakra-ui/react";
import DropdownWithSearch from "./DropdownWithSearch";
import { useSelector } from "react-redux";
import { selectConstants } from "../common/slices/constantsSlice";
import { useEffect, useState, useCallback } from "react";
import { DomainType, OmitOnChange } from "../common/types/common";
import { EMPTY_STRING } from "../common/constants/common";
import {
  EMAIL_CONFIG_FIELDS,
  VALUE_META_TYPE,
} from "../common/constants/template";
import { addEmptyContextIfBlank } from "../common/helper/commonHelper";
import { useChakraColors } from "../common/hooks/commonHooks";
import { FaChevronDown } from "react-icons/fa";
import {
  isTokenEmailField,
  isTextEmailField,
} from "../common/helper/templateHelper";
import { TokensDropdown } from "./dynamic-list/CommonDropdowns";
import SelectInputTypeDropdown from "./SelectInputTypeDropdown";
import { TOKEN_RETURN_TYPE } from "../common/constants/token";

const EMAIL_DOMAIN_SEPARATOR = "@";

interface InputWithErrorProps extends OmitOnChange<InputProps> {
  labelText?: string;
  errorMsg?: string;
  paddingX?: string;
  touched?: boolean;
  formLabelProps?: FormLabelProps;
  value: string;
  allowTokens?: boolean;
  tokenType: VALUE_META_TYPE | null;
  onTokenTypeChange: (tokenType: VALUE_META_TYPE) => void;
  onChange: (name: EMAIL_CONFIG_FIELDS, value: string) => void;
  name: EMAIL_CONFIG_FIELDS;
}

const EMAIL_TOKEN_OPTIONS = [
  {
    label: "email",
    value: [VALUE_META_TYPE.TEXT],
  },
  {
    label: "token",
    value: [VALUE_META_TYPE.TOKEN],
  },
];

function getActiveDomains(domainList: DomainType[]) {
  return domainList.filter(({ active }) => active);
}

function DomainDropdown({
  domain,
  domainList,
  onDomainChange,
  name,
  isDisabled,
}: {
  domain: string;
  onDomainChange: (domain: string) => void;
  domainList: DomainType[];
  name?: string;
  isDisabled?: boolean;
}) {
  const { gray100, gray200, gray400, gray500 } = useChakraColors();

  const activeDomains = getActiveDomains(domainList);
  const findDomain = activeDomains.find(
    (item) => `${EMAIL_DOMAIN_SEPARATOR}${item.domain}` === domain
  );

  return (
    <DropdownWithSearch
      name={`email-select-${name}`}
      options={activeDomains}
      getOptionLabel={(option) => `${EMAIL_DOMAIN_SEPARATOR}${option.domain}`}
      getOptionValue={(option) => `${EMAIL_DOMAIN_SEPARATOR}${option.domain}`}
      value={findDomain ?? null}
      onChange={(option) =>
        onDomainChange(
          option ? `${EMAIL_DOMAIN_SEPARATOR}${option.domain}` : ""
        )
      }
      controlStyle={{
        width: "100%",
        maxWidth: "150px",
        border: "none",
      }}
      singleValueProps={{
        color: gray400,
      }}
      components={{
        DropdownIndicator: () => {
          return <Icon as={FaChevronDown} color={gray500} w="30px" mr={1} />;
        },
        Option: (base) => {
          const { selectOption, isFocused, isSelected, data } = base;
          return (
            <Text
              fontSize="14px"
              w="100%"
              padding={2}
              whiteSpace="nowrap"
              color={gray400}
              backgroundColor={
                isSelected ? gray200 : isFocused ? gray100 : "white"
              }
              _hover={{
                backgroundColor: isSelected ? undefined : gray100,
              }}
              onClick={() => selectOption(data)}
            >
              {base.label}
            </Text>
          );
        },
      }}
      isSearchable={false}
      maxMenuHeight={300}
      isDisabled={isDisabled}
    />
  );
}

function InputWithDropdown({
  value,
  tokenType,
  onTokenTypeChange,
  onChangeHandler,
  inputProps,
  domainList,
  domain,
  errorMsg,
  isDisabled,
  isReadOnly = false,
  allowTokens = true,
}: {
  value: string;
  tokenType: VALUE_META_TYPE | null;
  onTokenTypeChange: (val: VALUE_META_TYPE) => void;
  onChangeHandler: (
    newValue: string,
    isInput: boolean,
    isToken?: boolean
  ) => void;
  inputProps: InputProps;
  domainList: DomainType[];
  domain: string;
  errorMsg: string | undefined;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  allowTokens?: boolean;
}) {
  const email = value + domain;
  const showError = !!errorMsg && !isDisabled;
  const isTokenField = isTokenEmailField(tokenType);
  const { red500, blue500 } = useChakraColors();

  return isReadOnly ? (
    <Text fontSize="14px"> {addEmptyContextIfBlank(email)}</Text>
  ) : (
    <InputGroup
      bg="white"
      border="1px solid"
      borderColor={showError ? "red.500" : "gray.200"}
      boxShadow={showError ? `0 0 0 1px ${red500}` : undefined}
      borderRadius="5px"
      _focusWithin={{
        border: "1px solid",
        boxShadow: `0 0 0 1px ${blue500}`,
        borderColor: "blue.500",
      }}
      maxW="100%"
      overflow="hidden"
    >
      {tokenType && allowTokens && (
        <InputLeftAddon
          background="white"
          border="none"
          borderRightRadius="none"
          borderLeftRadius="5px"
          p={2}
        >
          <SelectInputTypeDropdown
            options={EMAIL_TOKEN_OPTIONS}
            value={tokenType}
            onChange={(val) => onTokenTypeChange(val[0] as VALUE_META_TYPE)}
            isDisabled={isDisabled}
          />
        </InputLeftAddon>
      )}

      <Input
        value={value}
        onChange={(e) => onChangeHandler(e.target.value.trim(), true)}
        {...inputProps}
        type="text"
        border="none"
        fontSize={14}
        focusBorderColor="transparent"
        px={1}
        hidden={inputProps.hidden || isTokenField}
        isDisabled={isDisabled}
      />

      <InputRightAddon
        background="white"
        border="none"
        borderLeftRadius="none"
        borderRightRadius="5px"
        p={0}
        width={isTokenField ? "100%" : undefined}
      >
        {allowTokens && isTokenField && (
          <TokensDropdown
            token={email}
            onTokenChange={({ value }) => onChangeHandler(value, false, true)}
            isDisabled={isDisabled}
            filterTokens={(token) =>
              token.return_type === TOKEN_RETURN_TYPE.STRING
            }
            filterColumnAccessors={(col) =>
              col.return_type === TOKEN_RETURN_TYPE.STRING
            }
          />
        )}

        {(isTextEmailField(tokenType) || tokenType === null) && (
          <DomainDropdown
            domain={domain}
            onDomainChange={(val) => onChangeHandler(val, false)}
            domainList={domainList}
            isDisabled={isDisabled}
          />
        )}
      </InputRightAddon>
    </InputGroup>
  );
}

export default function RestrictedEmailInput({
  labelText,
  errorMsg,
  paddingX,
  touched = true,
  formLabelProps,
  name,
  value,
  onChange,
  allowTokens = true,
  tokenType,
  onTokenTypeChange,
  isDisabled,
  isReadOnly = false,
  ...props
}: InputWithErrorProps) {
  const { domainList } = useSelector(selectConstants);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [domain, setDomain] = useState(EMPTY_STRING);

  const onChangeHandler = useCallback(
    (newValue: string, isInput: boolean, isToken: boolean = false) => {
      let val = EMPTY_STRING;
      if (isToken) {
        val = newValue;
      } else {
        if (isInput) {
          const values = newValue.split(EMAIL_DOMAIN_SEPARATOR);
          val = values[0] + domain;
        } else {
          val = email + newValue;
        }
      }
      onChange(name, val);
    },
    [name, email, domain, onChange]
  );

  useEffect(() => {
    switch (tokenType) {
      case VALUE_META_TYPE.TEXT:
      case null:
        const [name, domainName] = value
          ? value.split(EMAIL_DOMAIN_SEPARATOR)
          : ["", ""];
        if (domainName) {
          setEmail(name ?? EMPTY_STRING);
          setDomain(`${EMAIL_DOMAIN_SEPARATOR}${domainName}`);
        } else {
          setEmail(EMPTY_STRING);
          const activeDomains = getActiveDomains(domainList);
          const firstActiveDomain = activeDomains.length
            ? activeDomains[0].domain
            : "";

          setDomain(
            `${
              firstActiveDomain
                ? EMAIL_DOMAIN_SEPARATOR + firstActiveDomain
                : ""
            }`
          );
        }
        break;

      case VALUE_META_TYPE.TOKEN:
        setEmail(value);
        setDomain(EMPTY_STRING);
        break;

      default:
        setEmail(EMPTY_STRING);
        setDomain(EMPTY_STRING);
    }
  }, [value, tokenType, domainList]);

  function tokenTypeChange(tokenType: VALUE_META_TYPE) {
    onTokenTypeChange(tokenType);

    // make email empty as we change type of the input
    // onchange if its not empty already
    value && onChangeHandler(EMPTY_STRING, false, true);
  }

  return (
    <FormControl px={paddingX} py="1">
      <HStack justifyContent="space-between">
        {labelText && (
          <FormLabel mb={1} color="text.50" fontSize="xs" {...formLabelProps}>
            {labelText}
          </FormLabel>
        )}
      </HStack>
      <Flex flex="1">
        <InputWithDropdown
          value={email}
          tokenType={tokenType}
          onTokenTypeChange={tokenTypeChange}
          onChangeHandler={onChangeHandler}
          inputProps={{ ...props, name }}
          domainList={domainList}
          domain={domain}
          errorMsg={errorMsg}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          allowTokens={allowTokens}
        />
      </Flex>
      <Text
        fontSize={12}
        color="red.500"
        pt={1}
        hidden={!errorMsg || !touched || isDisabled || isReadOnly}
      >
        {errorMsg}
      </Text>
    </FormControl>
  );
}
