import { Icon, Text } from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import { useChakraColors } from "../common/hooks/commonHooks";
import DropdownWithSearch from "./DropdownWithSearch";

export default function SelectInputTypeDropdown({
  options,
  value,
  onChange,
  isDisabled,
}: {
  options: { label: string; value: string[] }[];
  value: string;
  onChange: (val: string[]) => void;
  isDisabled?: boolean;
}) {
  const { title, blue600, gray300, gray200 } = useChakraColors();
  return (
    <DropdownWithSearch
      isDisabled={isDisabled}
      options={options}
      value={options.find(({ value: val }) => val.includes(value))}
      onChange={(option) => option?.value && onChange(option.value)}
      controlStyle={{
        borderRadius: "20px",
        background: title,
        width: "80px",
        minHeight: "23x",
        height: "23px",
      }}
      isSearchable={false}
      singleValueProps={{
        color: blue600,
      }}
      valueContainerProps={{
        paddingBlock: 0,
        paddingBottom: 2,
      }}
      menuStyle={{
        background: title,
      }}
      components={{
        DropdownIndicator: () => {
          return (
            <Icon
              as={FaChevronDown}
              mr={2}
              fontSize="12px"
              color="blue.600"
              w="10px"
            />
          );
        },
        Option: (base) => {
          const { selectOption, isFocused, isSelected, data } = base;
          return (
            <Text
              fontSize="14px"
              w="100%"
              padding={2}
              whiteSpace="nowrap"
              backgroundColor={
                isSelected ? gray300 : isFocused ? gray200 : title
              }
              _hover={{
                backgroundColor: isSelected ? undefined : gray200,
              }}
              onClick={() => selectOption(data)}
            >
              {base.label}
            </Text>
          );
        },
      }}
    />
  );
}
