import { Badge, Switch, Text, Box, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import {
  SfSyncRunRecord,
  SalesforceConnectionV2,
  SF_SYNC_RUN_STATE,
} from "../../../../../../common/types/salesforce";
import CustomDateRangePicker from "../../../../../../components/CustomDateRangePicker";
import { DataTable } from "../../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../../components/DateTimeRangeFilter";
import SyncRunTableCell, { SYNC_OBJ_CELL_TYPE } from "./SyncRunTableCell";
import TableHeader from "../../../../../../components/TableHeader";
import SubHeader from "../../../components/SubHeader";
import { selectSalesforce } from "../../salesforceSlice";
import { getSyncedObjectSummary } from "../../../../../../common/helper/salesforceHelper";
import { isLoading } from "../../../../../../common/helper/commonHelper";
import { SalesforceSyncRunContext } from "../../../../../../common/hooks/salesforce";

const SF_SYNC_RUN_STATE_META = {
  [SF_SYNC_RUN_STATE.COMPLETED]: { label: "Completed", color: "green" },
  [SF_SYNC_RUN_STATE.SKIPPED]: { label: "Skipped", color: "red" },
  [SF_SYNC_RUN_STATE.IN_PROGRESS]: { label: "Processing", color: "yellow" },
  [SF_SYNC_RUN_STATE.QUEUED]: { label: "Queued", color: "yellow" },
  [SF_SYNC_RUN_STATE.SCHEDULED]: { label: "Scheduled", color: "blue" },
};

export default function SyncRunHistory({
  connection,
  showErrorsDuringDuration,
  changeSyncRunsPage,
}: {
  connection: SalesforceConnectionV2 | null;
  showErrorsDuringDuration: (date: Date) => void;
  changeSyncRunsPage: (pageNo: number) => void;
}) {
  const { sfSyncRuns } = useSelector(selectSalesforce);
  const {
    hideEmptyRuns,
    setHideEmptyRuns,
    filterDate: [startDate, endDate],
    setFilterDate,
  } = useContext(SalesforceSyncRunContext);

  const columnHelper = createColumnHelper<SfSyncRunRecord>();

  const syncedObjSummary = useMemo(
    () => getSyncedObjectSummary(connection),
    [connection]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("startTime", {
        header: () => <TableHeader headerText="Synced On" />,
        size: 170,
        cell: (info) => {
          return (
            <Text marginLeft="20px">
              {<FormatDate date={info.getValue()} showTime splitLines />}
            </Text>
          );
        },
      }),
      columnHelper.accessor("syncRunObjects", {
        id: "person",
        header: () => <TableHeader headerText="Person" />,
        size: 200,
        cell: (info) => {
          const syncRunObject = info.getValue();

          return (
            <SyncRunTableCell
              cellType={SYNC_OBJ_CELL_TYPE.PERSON}
              syncRun={syncRunObject}
              syncedObjectSummary={syncedObjSummary}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDateTimestamp={info.row.original.startTime}
            />
          );
        },
      }),
      columnHelper.accessor("syncRunObjects", {
        header: () => <TableHeader headerText="Accounts" />,
        size: 200,
        id: "accounts",
        cell: (info) => {
          const syncRunObject = info.getValue();

          return (
            <SyncRunTableCell
              cellType={SYNC_OBJ_CELL_TYPE.ACCOUNT}
              syncRun={syncRunObject}
              syncedObjectSummary={syncedObjSummary}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDateTimestamp={info.row.original.startTime}
            />
          );
        },
      }),
      columnHelper.accessor("syncRunObjects", {
        header: () => (
          <TableHeader headerText="Opportunity" isComingSoon={true} />
        ),
        id: "opportunity",
        size: 100,
        cell: (info) => {
          const syncRunObject = info.getValue();

          return (
            <SyncRunTableCell
              cellType={SYNC_OBJ_CELL_TYPE.OPPORTUNITY}
              syncRun={syncRunObject}
              syncedObjectSummary={syncedObjSummary}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDateTimestamp={info.row.original.startTime}
            />
          );
        },
      }),
      columnHelper.accessor("syncRunObjects", {
        header: () => <TableHeader headerText="Total Records" />,
        id: "total_records",
        size: 200,
        cell: (info) => {
          const syncRunObject = info.getValue();
          return (
            <SyncRunTableCell
              cellType={SYNC_OBJ_CELL_TYPE.TOTAL_SUMMARY}
              syncRun={syncRunObject}
              syncedObjectSummary={syncedObjSummary}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDateTimestamp={info.row.original.startTime}
            />
          );
        },
      }),
      columnHelper.accessor("status", {
        header: () => <TableHeader headerText="Sync State" />,
        size: 100,
        cell: (info) => {
          const syncRunState = info.getValue();
          const { label, color } = SF_SYNC_RUN_STATE_META[syncRunState];
          return label ? (
            <Tooltip
              isDisabled={syncRunState !== SF_SYNC_RUN_STATE.SKIPPED}
              label={info.row.original.skippedReason}
              size="lg"
              fontSize="12px"
              placement="left"
            >
              <Badge
                fontSize="xs"
                colorScheme={color}
                variant="solid"
                size="sm"
                w="90px"
                textAlign="center"
              >
                {label}
              </Badge>
            </Tooltip>
          ) : (
            "--"
          );
        },
      }),
    ],
    [syncedObjSummary, showErrorsDuringDuration, columnHelper]
  );

  function onDateChange(startTime: Date, endTime: Date) {
    setFilterDate([startTime, endTime]);
  }

  return (
    <Box p="5" bg="white" rounded="md">
      <SubHeader title={`Sync history (${sfSyncRuns.count})`}>
        <Text>Hide empty runs</Text>
        <Switch
          isChecked={hideEmptyRuns}
          onChange={() => setHideEmptyRuns(!hideEmptyRuns)}
        />
        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={onDateChange}
        />
      </SubHeader>
      <Box w="100%" mt={2}>
        <DataTable
          fetchingList={isLoading(sfSyncRuns.loadingList)}
          changingPage={sfSyncRuns.changingPage}
          list={sfSyncRuns.list}
          totalPageSize={sfSyncRuns.pageSize}
          setPage={changeSyncRunsPage}
          totalPageCount={sfSyncRuns.totalPageCount}
          currentPage={sfSyncRuns.currentPageNo}
          columns={columns}
          emptyMsg="No sync history found"
          popoverPresent={true}
          tablePadding={{
            customCellPadding: "0px",
          }}
        />
      </Box>
    </Box>
  );
}
