import { extendTheme } from "@chakra-ui/react";
import buttonStyle from "./button";
import drawerStyle from "./drawer";
import inputStyle from "./input";
import skeletonStyle from "./skeleton";
import textareaStyle from "./textarea";
import headingStyle from "./heading";
import textStyle from "./text";
import { switchTheme } from "./switch";

const theme = extendTheme({
  components: {
    Textarea: textareaStyle,
    Input: inputStyle,
    Button: buttonStyle,
    MenuButton: buttonStyle,
    Drawer: drawerStyle,
    Skeleton: skeletonStyle,
    Heading: headingStyle,
    Text: textStyle,
    Switch: switchTheme,
  },
  fonts: {
    heading: "Open Sans, sans-serif",
    body: "Open Sans, sans-serif",
  },
  colors: {
    basic: {
      white: "#ffffff",
      orange: "#C68300",
      border: "#1C2B47",
      title: "#ECF0F7",
    },
    brand: {
      blue: "#2C5587",
      gray: "#8492A6",
      aqua: "#0CCFCF",
      red: "#FF4D42",
      green: "#5DC794",
      black: "#192E53",
      orange: "#F66C1E",
      purple: "#7479D2",
      "grayAlpha.200": "rgba(44, 85, 135,0.2)",
      "blueAlpha.700": "rgba(210, 228, 255, 0.7)",
      "redAlpha.100": "rgba(255, 77, 66, 0.1)",
    },
    brandV2: {
      primary: "#cedde1",
      "primary.50": "#edf7fa",
      tertiary: "#f5f5f5",
      gray: "#dddddd",
      darkGray: "#E1E7E7",
    },
    oneOffs: {
      sidebarBackground: "#e4e4e4", // in pdf layouts
      sidebarBackground2: "#F4F6F8", // in final theme
      backgroundGray: "#f5f5f5",
      tableHeader: "#cedde1", // Table header, sidebar selection, borders
      sidebarPopoverSelection: "#b4dae4", // not used
      headerTitle: "#888888",
      campaignTags: "#727272",
      reactFlow: "#c1cdd6",
      subtitle: "#b1b1b1", // runtime subtext
      warning: {
        text: "#C68300",
        bg: "#F9F3E5",
      },
      error: {
        red: "#B70A0A",
      },
    },
    brandAqua: {
      200: "#cff5f4",
      300: "#9debeb",
      400: "#3dd7d6",
      500: "#0ccdcd",
      600: "#08a4a4",
      700: "#055251",
      800: "#022929",
    },
    brandBlue: {
      100: "#ecf0f7", // for seclection of radio items
      200: "#d1dadf",
      300: "#a3b4c2",
      400: "#476c85",
      450: "#466cb2", // for icons and check signs of radio items
      500: "#194867",
      600: "#133952",
      700: "#091c29",
      800: "#050f14",
      900: "#466CB2",
    },
    brandRed: {
      50: " #F8ECEC",
    },
    brandGreen: {
      100: "#6B9769",
    },
    brandOrange: {
      50: "#F9F3E5", // information banner bg
      100: "#BF6537", // information banner text
    },
    text: {
      50: "#516a85", // Subtitle Grey Color
      100: "#26313c", // Main Text Black
      200: "#2d3a48", // Menu Text Black
    },
    grayV2: {
      100: "#f4f6f8", // background grey, table hover
      200: "#e1e7eb", // background objects, table border
      300: "#d0dae0", // table tile, summary items, borders
      400: "#c1cdd6", // canvas background
      500: "#b1c1cc", // canvas grouping
      600: "#748ea5", // Canvas Lines
      800: "#8AA1B3",
      1600: "#42566B",
    },
  },
});

export default theme;
