import { Flex } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  formatLastSaved,
  isLoading,
} from "../../../common/helper/commonHelper";
import { isValidUrl } from "../../../common/helper/commonHelper";
import { UpdateWebhookType } from "../../../common/types/webhook";
import { WEBHOOK_REQUEST_METHODS } from "../../../common/constants/webhook";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import urls from "../../../urls";
import WebhookInputConfiguration from "./components/WebhookInputConfiguration";
import {
  getWebhook,
  resetWebhookDetails,
  resetWebhookPreviewData,
  selectWebhook,
  setIsWebhookDirty,
  updateWebhook,
  updateWebhookName,
} from "./webhookSlice";
import { PreventNavigationModal } from "../../../components/PreventNavigationModal";
import WebhookPreview from "./components/WebhookPreview";
import { useAppDispatch } from "../../../store";
import { cloneDeep } from "lodash";
import WebhookHeader from "./components/WebhookHeader";
import {
  HEADER_ACTIONS,
  CONTENT_TYPE,
  APPLICATION_JSON,
} from "../../../common/constants/webhook";
import { KEY_VALUE_OPTION } from "../../../common/constants/common";

const ONE_MIN = 60000;

export type WebhookInputType = {
  description: string;
  method: WEBHOOK_REQUEST_METHODS;
  url: string;
  payload: string;
  headers: [string, string][];
};

export type HandleHeaderChangeType = {
  action: HEADER_ACTIONS;
  value?: string;
  index?: number;
  type?: KEY_VALUE_OPTION;
};

const initWebhookInput: WebhookInputType = {
  description: "",
  method: WEBHOOK_REQUEST_METHODS.POST,
  url: "",
  payload: "",
  headers: [],
};

export default function Webhook() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    webhookDetails: { data: webhookDetails, loading: fetchingWebhookDetails },
    updatingWebhook,
    isWebhookDirty,
    webhookName,
  } = useSelector(selectWebhook);

  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);
  const [lastSavedStatus, setLastSavedStatus] = useState("Just now");
  const [contentTypeHeader, setContentTypeHeader] = useState(
    webhookDetails.headers[CONTENT_TYPE] ?? APPLICATION_JSON
  );
  const [inputs, setInputs] = useState(initWebhookInput);
  const [errors, setErrors] = useState({
    description: "",
    url: "",
    headers: "",
  });

  useEffect(() => {
    if (id) dispatch(getWebhook(id));
  }, [dispatch, id]);

  useEffect(() => {
    let headersArray = Object.entries(webhookDetails.headers);
    const headersClone = { ...webhookDetails.headers };
    let headers: [string, string][];

    //content type is a mandatory field that appears first in the headers, only the value changes

    if (CONTENT_TYPE in webhookDetails.headers) {
      setContentTypeHeader(webhookDetails.headers[CONTENT_TYPE]);
      delete headersClone[CONTENT_TYPE];
      //separate content type and code mirror inputs
      headers = Object.entries(headersClone);
    } else {
      setContentTypeHeader(webhookDetails.url ? "" : APPLICATION_JSON);
      headers = webhookDetails.url
        ? [...headersArray]
        : [...headersArray, ["", ""]];
    }

    const inputInitState: WebhookInputType = {
      description: webhookDetails.description ?? "",
      method: webhookDetails.method ?? WEBHOOK_REQUEST_METHODS.POST,
      url: webhookDetails.url ? webhookDetails.url.trim() : "",
      payload: webhookDetails.payload ?? "",
      headers: [...headers],
    };
    setInputs(inputInitState);
  }, [webhookDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetWebhookDetails());
    };
  }, [dispatch]);

  function renameWebhook(newName: string) {
    setIsOpenRenameModal(false);
    dispatch(
      updateWebhookName({ name: newName, webhookId: webhookDetails.webhook_id })
    );
  }

  const updateLastSavedStatus = useCallback((updatedAt: string) => {
    setLastSavedStatus(formatLastSaved({ updatedAt }));
  }, []);

  useEffect(() => {
    updateLastSavedStatus(webhookDetails.updated_at);
    const lastSavedIntervalId = setInterval(
      () => updateLastSavedStatus(webhookDetails.updated_at),
      ONE_MIN
    );
    return () => clearInterval(lastSavedIntervalId);
  }, [updateLastSavedStatus, updatingWebhook, webhookDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetWebhookPreviewData());
    };
  }, [dispatch]);

  function handleChange(name: keyof WebhookInputType, value: any) {
    setInputs((prev) => {
      return { ...prev, [name]: cloneDeep(value) };
    });

    dispatch(setIsWebhookDirty(true));
  }

  function handleHeaderChange({
    action,
    value,
    index,
    type,
  }: HandleHeaderChangeType) {
    const ind = index!;
    switch (action) {
      case HEADER_ACTIONS.ADD:
        setInputs((prev) => {
          return { ...prev, headers: [...prev.headers, ["", ""]] };
        });
        break;

      case HEADER_ACTIONS.REMOVE:
        setInputs((prev) => {
          let headerArray = prev.headers.filter(
            (header, index) => index !== ind
          );
          return { ...prev, headers: [...headerArray] };
        });
        break;

      case HEADER_ACTIONS.UPDATE:
        setInputs((prev) => {
          let headersArray = cloneDeep(prev.headers);

          if (type === KEY_VALUE_OPTION.KEY) {
            headersArray[ind] = [value!, headersArray[ind][1]];
          } else {
            headersArray[ind] = [headersArray[ind][0], value!];
          }
          return { ...prev, headers: [...headersArray] };
        });
        break;

      case HEADER_ACTIONS.CONTENT_TYPE_CHANGE:
        setContentTypeHeader(value);
        break;
    }

    dispatch(setIsWebhookDirty(true));
  }

  function checkAndSetErrors(
    key: string,
    value: string,
    message: string,
    url: boolean = false
  ) {
    let isValid = (value && !url) || isValidUrl(value);
    let errorMsg = isValid ? "" : message;

    setErrors((prev) => {
      return { ...prev, [key]: errorMsg };
    });

    return isValid;
  }

  function validateInputs() {
    const isDescriptionValid = checkAndSetErrors(
      "description",
      inputs.description,
      "Description should not be empty"
    );
    const isUrlValid = checkAndSetErrors(
      "url",
      inputs.url,
      "Please enter a valid url",
      true
    );

    function isHeaderValid() {
      if (inputs.payload) {
        return checkAndSetErrors(
          "headers",
          contentTypeHeader,
          "Content-type is required, when payload is present"
        );
      } else if (!inputs.payload && !contentTypeHeader) {
        checkAndSetErrors("headers", contentTypeHeader, "");
        return true;
      }
      return true;
    }

    return isDescriptionValid && isUrlValid && isHeaderValid();
  }

  function saveWebhook() {
    if (validateInputs()) {
      const headerObject = Object.fromEntries(
        inputs.headers
          .filter((header) => header[0].trim() !== "")
          .map((header) => [header[0].trim(), header[1].trim()])
      );

      const updatedWebhookDetails: UpdateWebhookType = {
        ...inputs,
        webhook_id: webhookDetails.webhook_id,
        headers: contentTypeHeader
          ? { [CONTENT_TYPE]: contentTypeHeader.trim(), ...headerObject }
          : { ...headerObject },
        url: inputs.url.trim(),
        payload: inputs.payload ? inputs.payload.trim() : null,
      };
      dispatch(updateWebhook(updatedWebhookDetails));
    }
  }

  return (
    <>
      <WebhookHeader
        heading={webhookName}
        openRenameModal={() => setIsOpenRenameModal(true)}
        lastSavedStatus={lastSavedStatus}
        loading={isLoading(updatingWebhook)}
        onSave={saveWebhook}
        returnUrl={urls.webhook}
        isFetchingData={isLoading(fetchingWebhookDetails)}
      />
      <Flex flexDir="row" height="calc(100vh - 140px)">
        <WebhookInputConfiguration
          inputs={inputs}
          errors={errors}
          handleChange={handleChange}
          handleHeaderChange={handleHeaderChange}
          contentType={contentTypeHeader}
          inputPaddingX="5px"
          width="450px"
          overflowY="scroll"
          borderRight="1px"
          borderRightColor="gray.200"
          isLoading={isLoading(fetchingWebhookDetails)}
        />
        <WebhookPreview />
      </Flex>
      <EditNameModal
        action={ASSET_NAME_ACTIONS.RENAME}
        value={webhookName}
        isOpen={isOpenRenameModal}
        asset="webhook"
        onClose={() => setIsOpenRenameModal(false)}
        onSubmit={renameWebhook}
        validateName={(name: string) => {
          return name.length < 3
            ? "Name must be at least 3 characters long"
            : "";
        }}
        validateOnClick
      />
      <PreventNavigationModal
        isActive={isWebhookDirty}
        onCancel={() => dispatch(setIsWebhookDirty(isWebhookDirty))}
        onConfirm={() => dispatch(setIsWebhookDirty(false))}
      />
    </>
  );
}
