import {
  Heading,
  VStack,
  Text,
  TextProps,
  Box,
  BoxProps,
  StackProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export default function SubHeader({
  title,
  desc,
  titleProps,
  descriptionProps,
  children,
  stackProps,
  ...props
}: {
  title: string | ReactNode;
  desc: string;
  titleProps?: TextProps;
  descriptionProps?: TextProps;
  children?: ReactNode;
  stackProps?: StackProps;
} & BoxProps) {
  return (
    <Box {...props}>
      <Heading as="h1" pb="2" fontSize="16px" {...titleProps}>
        {title}
      </Heading>
      <Text fontSize="14px" {...descriptionProps}>
        {desc}
      </Text>
      <VStack width="full" spacing="4" mt={4} {...stackProps}>
        {children}
      </VStack>
    </Box>
  );
}
