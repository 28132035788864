import {
  Box,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
  StackProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaAngleRight, FaCircle } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import IButton from "../../../../../../components/IButton";
import ISkeleton, {
  SKELETON_VARIANT,
} from "../../../../../../components/ISkeleton";
import ValidationStateIndicator from "./ValidationStateIndicator";

export default function SectionWrapper({
  title,
  icon,
  isReadOnly,
  onRightButtonClick,
  isErrorHidden,
  errorText,
  isLoading,
  isEmpty,
  isLastRow,
  children,
}: {
  title: string;
  icon: IconType;
  isReadOnly: boolean;
  onRightButtonClick: () => void;
  isErrorHidden: boolean;
  errorText: string;
  isLoading: boolean;
  isEmpty: boolean;
  isLastRow?: boolean;
  children: ReactNode;
}) {
  return (
    <VStack alignItems="flex-start" spacing={0}>
      <SectionTitle
        TitleIcon={icon}
        title={title}
        isReadOnly={isReadOnly}
        onButtonClick={onRightButtonClick}
        isErrorHidden={isErrorHidden}
        errorText={errorText}
        isLoading={isLoading}
        isEmpty={isEmpty}
      />
      <Box w="100%" pl={"12px"} position="relative">
        <ISkeleton
          variant={SKELETON_VARIANT.TEXT}
          borderLeft="1px"
          borderColor="brandBlue.400"
          isLoaded={!isLoading}
          pl="14px"
          marginTop={0}
          py={2}
          w="100%"
        >
          {children}
        </ISkeleton>
        {isLastRow && (
          <Icon
            as={FaCircle}
            color="brandBlue.400"
            fontSize="5px"
            position="absolute"
            bottom={"-2.5px"}
            left={"10px"}
          />
        )}
      </Box>
    </VStack>
  );
}

export function SectionTitle({
  TitleIcon,
  isReadOnly,
  onButtonClick,
  isErrorHidden,
  errorText,
  title,
  isLoading,
  isEmpty,
}: {
  TitleIcon: any;
  isReadOnly: boolean;
  onButtonClick: () => void;
  isErrorHidden: boolean;
  errorText: string;
  title: string;
  isLoading: boolean;
  isEmpty: boolean;
}) {
  const errorProps =
    !isErrorHidden && errorText
      ? {
          bgColor: "red.50",
          border: "1px solid",
          borderColor: "red.500",
        }
      : {
          bgColor: "basic.title",
        };

  return (
    <HStack w="100%" py="8px" px="8px" borderRadius="6px" {...errorProps}>
      <TitleIcon fontSize="12px" />
      <Text fontSize="12px" fontWeight="600" color="basic.border">
        {title}
      </Text>
      <Spacer />
      {!isEmpty && (
        <HStack>
          <IButton
            variant="link"
            color="brandBlue.400"
            fontSize="12px"
            onClick={onButtonClick}
            hidden={isLoading}
            rightIcon={<Icon as={FaAngleRight} fontSize="14px" mt="1px" />}
            iconSpacing={0}
          >
            {isReadOnly ? "View" : "Edit"}
          </IButton>
        </HStack>
      )}
    </HStack>
  );
}

export function SectionItem({
  infoText,
  hideInfo,
  children,
  errorText,
  ...props
}: {
  infoText: string;
  hideInfo?: boolean;
  children: ReactNode;
  errorText: string;
} & StackProps) {
  if (hideInfo) {
    return <>{children}</>;
  } else {
    return (
      <VStack alignItems="flex-start" gridGap={2} {...props}>
        <Text fontSize="12px" color="text.50" wordBreak="break-word" w="100%">
          {infoText}
        </Text>
        {children}
        <ValidationStateIndicator errorText={errorText} />
      </VStack>
    );
  }
}

export function SectionLink({
  title,
  LinkIcon,
  onOpen,
  errorText,
}: {
  title: string;
  LinkIcon?: any;
  onOpen: () => void;
  errorText?: string;
}) {
  return (
    <>
      <HStack
        onClick={onOpen}
        cursor="pointer"
        bg="oneOffs.sidebarBackground2"
        color="brand.blue"
        borderRadius="4px"
        px="16px"
        py="8px"
        my={2}
        w="90%"
      >
        <LinkIcon width="16px" height="16px" />
        <Text fontSize="12px" fontWeight="600">
          {title}
        </Text>
      </HStack>
      <ValidationStateIndicator errorText={errorText || ""} />
    </>
  );
}
