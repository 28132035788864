import { PERSON_ORG_MAPPING, UnsubUrl } from "../../types/person";
import { DomainType } from "../../types/common";
import { campaignRequest } from "./api";
import { integrationsRequest } from "../integrations/api";
import {
  FormSettings,
  TenantDetails,
  TimezoneSettings,
  UnsubscribeSettings,
  GlobalEmailResp,
  GlobalEmailRequest,
  CommLimitSettings,
  EmailCommLimitSettings,
} from "../../types/settings";
import { TIMEZONE_FIELDS } from "../../constants/settings";

export async function createUnsubUrlApi(url: string): Promise<UnsubUrl> {
  return campaignRequest({
    url: "/settings/unsub-url/create",
    data: { url },
  });
}

export async function updateUnsubUrlApi(
  url: string
): Promise<{ unsub_url: UnsubUrl }> {
  return campaignRequest({
    url: "/settings/unsub-url/update",
    data: { url },
  });
}

export async function deleteUnsubUrlApi(): Promise<{ unsub_url: boolean }> {
  return campaignRequest({
    url: "/settings/unsub-url/delete",
    data: null,
  });
}

export async function getUnsubUrlApi(): Promise<{ unsub_url: UnsubUrl }> {
  return campaignRequest({
    url: "/settings/unsub-url/get",
    data: null,
  });
}

export async function getSettingsApi(): Promise<{
  settings: {
    domain: string | null;
    email: GlobalEmailResp | null;
    person_org_mapping: PERSON_ORG_MAPPING;
  };
}> {
  return campaignRequest({
    url: "/settings/get",
    data: {},
    shouldRetryApiCall: true,
  });
}

//TODO: include updated_at and created_at in the resp
export async function createGlobalDefaultApi(
  data: GlobalEmailRequest
): Promise<GlobalEmailResp> {
  return campaignRequest({
    url: "/settings/email/create",
    data,
  });
}

//TODO: include updated_at and created_at in the resp
export async function updateGlobalDefaultApi(
  data: GlobalEmailRequest
): Promise<{ email: GlobalEmailResp }> {
  return campaignRequest({
    url: "/settings/email/update",
    data,
  });
}

export async function fetchDomainListApi(): Promise<{ domain: DomainType[] }> {
  return campaignRequest({
    url: "/settings/domain/list",
    data: { active: true },
    shouldRetryApiCall: true,
  });
}

export async function createFormSettingsApi(
  overwrite: boolean
): Promise<{ forms: FormSettings }> {
  return integrationsRequest({
    url: "/settings/forms.create",
    data: {
      overwrite,
    },
  });
}

export async function getFormSettingsApi(): Promise<{ forms: FormSettings }> {
  return integrationsRequest({
    url: "/settings/forms.get",
  });
}

export async function deleteFormSettingsApi(): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/settings/forms.delete",
  });
}

export async function updateFormSettingsApi(overwrite: boolean): Promise<{}> {
  return integrationsRequest({
    url: "/settings/forms.update",
    data: {
      overwrite,
    },
  });
}

export async function getUnsubscribeSettingsApi(): Promise<{
  unsubscribe: UnsubscribeSettings;
}> {
  return campaignRequest({
    url: "/settings/unsubscribe/get",
  });
}

export async function toggleUnsubscribeSettingsApi(): Promise<{
  unsubscribe: UnsubscribeSettings;
}> {
  return campaignRequest({
    url: "/settings/unsubscribe/toggle",
  });
}

export async function getTimezonePreferenceSettingsApi(): Promise<{
  timezone_preference: TimezoneSettings;
}> {
  return campaignRequest({
    url: "/settings/timezone-preference",
    method: "GET",
  });
}

export async function createTimezonePreferenceSettingsApi(
  preferredTimezone: TIMEZONE_FIELDS
): Promise<{ timezone_preference: TimezoneSettings }> {
  return campaignRequest({
    url: "/settings/timezone-preference",
    method: "POST",
    data: {
      preferred_timezone_field: preferredTimezone,
    },
  });
}
export async function updateTimezonePreferenceSettingsApi(
  preferredTimezone: TIMEZONE_FIELDS
): Promise<{ timezone_preference: TimezoneSettings }> {
  return campaignRequest({
    url: "/settings/timezone-preference",
    method: "PUT",
    data: {
      preferred_timezone_field: preferredTimezone,
    },
  });
}

export async function getTenantTimezoneApi(): Promise<{
  tenant_timezone: TenantDetails;
}> {
  return campaignRequest({
    url: "/settings/tenant-timezone",
    method: "GET",
  });
}

export async function getCommunicationLimitConfigApi(): Promise<{
  email_limits: CommLimitSettings | null;
}> {
  return campaignRequest({
    url: "settings/email-limits",
    method: "GET",
  });
}

export async function createEmailCommunicationLimitConfigApi(
  data: EmailCommLimitSettings
): Promise<{ email_limits: CommLimitSettings }> {
  return campaignRequest({
    url: "settings/email-limits",
    method: "POST",
    data: { email_limits: data },
  });
}

export async function updateEmailCommunicationLimitConfigApi(
  data: EmailCommLimitSettings
): Promise<{ email_limits: CommLimitSettings }> {
  return campaignRequest({
    url: "settings/email-limits",
    method: "POST",
    data: { email_limits: data },
  });
}

export async function deleteEmailCommunicationLimitConfigApi(): Promise<{
  success: boolean;
}> {
  return campaignRequest({
    url: "settings/email-limits",
    method: "DELETE",
  });
}
