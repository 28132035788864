import { isEmpty, debounce, isNull, cloneDeep } from "lodash";
import { useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { setActiveErrorCheck, setCampaignTags } from "./campaignSlice";
import { toast } from "react-toastify";
import { CAMPAIGN_STATUS } from "../../../common/constants/campaign";
import {
  CampaignDetailsForBuilder,
  CampaignRuleResp,
  CampaignSchedule,
  CampaignValidationType,
  DraftSendDataType,
  DynamicListType,
} from "../../../common/types/campaign";
import {
  validateDynamicList,
  validateSchedule,
} from "./helper/validationHelper";
import { formatRule, formatSchedule } from "./helper/formatHelper";
import { useAppDispatch } from "../../../store";
import CampaignScheduleSection from "./sidebar/sections/CampaignScheduleSection";
import CampaignExitSection from "./sidebar/sections/CampaignExitSection";
import AudienceFilterSection from "./sidebar/sections/AudienceFilterSection";
import { selectDynamicList } from "../../../components/dynamic-list/dynamicListSlice";
import CampaignBuilder from "./CampaignBuilder";
import { useUpdateWithInitValue } from "../../../common/hooks/commonHooks";
import { CampaignBuilderContext, selectFlow } from "./flow/flowSlice";
import { FlowGraph } from "../../../common/types/flow";

type InitCampaignDataType = {
  dynamicList: DynamicListType[];
  schedule: CampaignSchedule;
  rule: CampaignRuleResp;
  exitCriteria: [DynamicListType] | null;
  validity: CampaignValidationType;
  overrideEmailLimits: boolean;
};

export default function BatchCampaignBuilder({
  campaignDetails,
  initCampaignData: {
    dynamicList: initDynamicList,
    schedule: initSchedule,
    rule: initRule,
    exitCriteria: initExitCriteria,
    validity: initValidity,
    overrideEmailLimits: initOverrideEmailLimits,
  },
  saveDraft,
  publishData,
}: {
  campaignDetails: CampaignDetailsForBuilder;
  initCampaignData: InitCampaignDataType;
  saveDraft: (current: DraftSendDataType) => void;
  publishData: (current: DraftSendDataType, active: boolean) => void;
}) {
  const dispatch = useAppDispatch();

  const { operatorsArgCount } = useSelector(selectDynamicList);
  const {
    draftFlow: flow,
    flowValidity,
    deletedFlowActions,
  } = useSelector(selectFlow);

  const [dynamicList, setDynamicList] = useUpdateWithInitValue(initDynamicList);
  const [campaignSchedule, setCampaignSchedule] =
    useUpdateWithInitValue(initSchedule);
  const [campaignRule, setCampaignRule] = useUpdateWithInitValue(initRule);
  const [exitCriteria, setExitCriteria] =
    useUpdateWithInitValue(initExitCriteria);
  const [overrideEmailLimits, setOverrideEmailLimits] = useUpdateWithInitValue(
    initOverrideEmailLimits
  );
  const [ruleValid, setRuleValid] = useUpdateWithInitValue(true);
  const [validity, setValidity] = useUpdateWithInitValue(initValidity);

  const validateScheduleOnSave = useCallback(() => {
    const scheduleErrors = validateSchedule(campaignSchedule);
    const scheduleValid = !Object.values(scheduleErrors).length;

    setValidity((prevState) => {
      return {
        ...prevState,
        schedule: scheduleValid ? "" : "Schedule is not configured",
        rule: ruleValid ? "" : "Journey rule is invalid",
      };
    });
  }, [campaignSchedule, ruleValid, setValidity]);

  useEffect(() => {
    if (campaignDetails.activeErrorCheck) validateScheduleOnSave();
  }, [
    campaignDetails.activeErrorCheck,
    campaignSchedule,
    validateScheduleOnSave,
  ]);

  function setNullIfEmpty(data: any) {
    return isEmpty(data) ? null : data;
  }

  function appendDeletedFlowSteps(flowData: FlowGraph) {
    const clonedFlow = cloneDeep(flowData);
    if (deletedFlowActions.length) {
      clonedFlow.nodes = [...clonedFlow.nodes, ...deletedFlowActions];
    }
    return clonedFlow;
  }

  function withOtherTabData(
    newData?: Partial<DraftSendDataType>
  ): DraftSendDataType {
    return {
      flow: appendDeletedFlowSteps(flow),
      query: { conditions: isEmpty(dynamicList) ? [] : dynamicList },
      schedule: setNullIfEmpty(formatSchedule(campaignSchedule)),
      rule: setNullIfEmpty(formatRule(campaignRule)),
      exit_criteria: exitCriteria ? { conditions: exitCriteria } : null,
      override_email_limits: overrideEmailLimits,
      ...newData,
    };
  }

  const debouncedSave = useMemo(() => debounce(saveDraft, 1000), [saveDraft]);

  function saveDraftWithOtherData(
    newData?: Partial<DraftSendDataType>,
    skipDebounce?: boolean
  ) {
    const data = withOtherTabData(newData);
    if (skipDebounce) {
      saveDraft(data);
    } else {
      debouncedSave(data);
    }
  }

  function validateExitCriteria() {
    let exitCriteriaValid = true;
    if (!isNull(exitCriteria)) {
      const { dynamicListValid, dynamicListValidated: exitCriteriaValidated } =
        validateDynamicList(exitCriteria ?? [], operatorsArgCount);
      exitCriteriaValid = dynamicListValid;
      setExitCriteria([exitCriteriaValidated[0]]);
    }
    return exitCriteriaValid;
  }

  function validateDLOnClose() {
    const { dynamicListValid, dynamicListValidated } = validateDynamicList(
      dynamicList,
      operatorsArgCount
    );
    setValidity((prevState) => {
      return {
        ...prevState,
        dynamicList: dynamicListValid
          ? ""
          : isEmpty(dynamicList)
          ? "Audience criteria is empty"
          : "Audience criteria is incomplete",
      };
    });
    setDynamicList(dynamicListValidated);
  }

  function validateExitCriteriaOnClose() {
    let exitCriteriaValid = validateExitCriteria();

    setValidity((prevState) => {
      return {
        ...prevState,
        exitCriteria: exitCriteriaValid ? "" : "Exit criteria is incomplete",
      };
    });
  }

  function validateData(
    callback: (current: DraftSendDataType, active: boolean) => void,
    ...args: any[]
  ) {
    dispatch(setActiveErrorCheck(true));
    const { dynamicListValid, dynamicListValidated } = validateDynamicList(
      dynamicList,
      operatorsArgCount
    );
    setDynamicList(dynamicListValidated);
    const flowValid =
      Object.values(flowValidity).every((valid) => valid) &&
      !isEmpty(flow.nodes);
    const scheduleErrors = validateSchedule(campaignSchedule);
    const scheduleValid = !Object.values(scheduleErrors).length;
    let exitCriteriaValid = validateExitCriteria();
    const validityMessage = {
      dynamicList: dynamicListValid
        ? ""
        : isEmpty(dynamicList)
        ? "Audience criteria is empty"
        : "Audience criteria is incomplete",
      flow: flowValid
        ? ""
        : isEmpty(flow)
        ? "Flow is empty"
        : "Flow is incomplete",
      schedule: scheduleValid ? "" : "Schedule is not configured",
      rule: ruleValid ? "" : "Journey rule is invalid",
      exitCriteria: exitCriteriaValid ? "" : "Exit criteria is incomplete",
    };
    setValidity(validityMessage);
    if (
      dynamicListValid &&
      flowValid &&
      ruleValid &&
      scheduleValid &&
      exitCriteriaValid
    ) {
      callback.apply(null, [withOtherTabData(), args[0] as boolean]);
    } else {
      toast.error(
        Object.values(validityMessage)
          .filter((msg) => !!msg)
          .join(", ")
      );
    }
  }

  function onPublish(active: boolean) {
    validateData(publishData, active);
  }

  function onExitCriteriaChange(exitCriteria: DynamicListType | null) {
    setExitCriteria(exitCriteria ? [exitCriteria] : null);
    saveDraftWithOtherData({
      exit_criteria: exitCriteria ? { conditions: [exitCriteria] } : null,
    });
  }

  function updateTags(tags: string[]) {
    dispatch(setCampaignTags(tags));
  }

  const isReadOnly = useMemo(() => {
    return campaignDetails.status === CAMPAIGN_STATUS.ACTIVE;
  }, [campaignDetails.status]);

  return (
    <CampaignBuilderContext.Provider value={campaignDetails}>
      <CampaignBuilder onPublish={onPublish} updateTags={updateTags}>
        <AudienceFilterSection
          data={dynamicList}
          readonly={isReadOnly}
          errorText={validity.dynamicList}
          saveDraft={(data: DynamicListType[], skipDebounce) =>
            saveDraftWithOtherData(
              {
                query: { conditions: data },
              },
              skipDebounce
            )
          }
          onChange={(data: DynamicListType[]) => {
            setDynamicList(data);
          }}
          campaignContext={campaignDetails.campaignContext}
          isLoading={campaignDetails.fetchingDetails}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          validateOnClose={validateDLOnClose}
        />
        <CampaignExitSection
          readonly={isReadOnly}
          errorText={validity.exitCriteria}
          data={exitCriteria ? exitCriteria[0] : null}
          onChange={onExitCriteriaChange}
          campaignContext={campaignDetails.campaignContext}
          isLoading={campaignDetails.fetchingDetails}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          validateOnClose={validateExitCriteriaOnClose}
        />
        <CampaignScheduleSection
          readonly={isReadOnly}
          scheduleErrorText={validity.schedule}
          ruleErrorText={validity.rule}
          schedule={campaignSchedule}
          overrideEmailLimits={overrideEmailLimits}
          onChange={(schedule, rule, overrideEmailLimits) => {
            setCampaignSchedule(schedule);
            setCampaignRule(rule);
            setOverrideEmailLimits(overrideEmailLimits);
            saveDraftWithOtherData(
              {
                schedule: formatSchedule(schedule),
                rule: formatRule(rule),
                override_email_limits: overrideEmailLimits,
              },
              true
            );
          }}
          rule={campaignRule}
          onRuleValidityChange={setRuleValid}
          isLoading={campaignDetails.fetchingDetails}
          activeErrorCheck={campaignDetails.activeErrorCheck}
        />
      </CampaignBuilder>
    </CampaignBuilderContext.Provider>
  );
}
